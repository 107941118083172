import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListTaskComponent } from './task/list-task/list-task.component';
import { ReportComponent } from './report/report.component';
import { WaComponent } from './task/forms/wa/wa.component';
import { RcraComponent } from './task/forms/rcra/rcra.component';
import { LoginComponent } from './user/components/login/login.component';
import { PrivateComponent } from './private/private.component';
import { FlashPointComponent } from './task/forms/flash-point/flash-point.component';
import { BoilingPointComponent } from './task/forms/boiling-point/boiling-point.component';
import { PhComponent } from './task/forms/ph/ph.component';
import { CasNumberAndWeightsComponent } from './task/forms/cas-number-and-weights/cas-number-and-weights.component';
import { FormComponent } from './task/forms/form/form.component';
import { HasPendingTaskGuard } from './shared/guards/has-pending-task.guard';
import { CaComponent } from './task/forms/ca/ca.component';
import { TxComponent } from './task/forms/tx/tx.component';
import { NoContentComponent } from './no-content/no-content.component';
import { ListGroupComponent } from './group/list-group/list-group.component';
import { IsSupervisorGuard } from './shared/guards/is-supervisor.guard';
import { ResetPasswordComponent } from './user/components/reset-password/reset-password.component';
import { ChangePasswordComponent } from './user/components/change-password/change-password.component';
import { IntendedUseComponent } from './task/forms/intended-use/intended-use.component';
import { FindProductInfoComponent } from './task/forms/find-product-info/find-product-info.component';
import { SdsFindInfoComponent } from './task/forms/sds-find-info/sds-find-info.component';
import { ValidateSdsPairingComponent } from './task/forms/validate-sds-pairing/validate-sds-pairing.component';
import { UnNumberComponent } from './task/forms/un-number/un-number.component';
import { MeltingPointComponent } from './task/forms/melting-point/melting-point.component';
import { HazardComponent } from './task/forms/hazard/hazard.component';
import { IshipComponent } from './task/forms/iship/iship.component';
import {ExpertClassificationContainerComponent} from './task/forms/expert-classification/expert-classification-container/expert-classification-container.component';
import {PackagingInfoComponent} from './task/forms/expert-classification/packaging-info/packaging-info.component';
import { TabBarComponent } from './task/forms/expert-classification/tab-bar/tab-bar.component';
import { TransportationComponent } from './task/forms/expert-classification/transportation/transportation.component';
import { WasteComponent } from './task/forms/expert-classification/waste/waste.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'nocontent', component: NoContentComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  {
    path: 'private',
    component: PrivateComponent,
    children: [
      { path: 'report', component: ReportComponent },
      { path: 'expert/:id', component: ExpertClassificationContainerComponent,
        children: [
          { path: '', component: TabBarComponent, children:[
            { path: 'transportation', component: TransportationComponent },
            { path: 'waste', component: WasteComponent }
          ],
          },
          { path: 'form', component: FormComponent },
          { path: 'boiling_point', component: BoilingPointComponent },
          { path: 'flash_point', component: FlashPointComponent },
          { path: 'ph', component: PhComponent },
          { path: 'cas_numbers_and_concentration', component: CasNumberAndWeightsComponent },
          { path: 'intended_use', component: IntendedUseComponent },
          { path: 'packaging_info', component: PackagingInfoComponent }
        ]
      },
      {
        path: 'management',
        component: ListGroupComponent,
        canActivate: [IsSupervisorGuard]
      },
      {
        path: 'task/list',
        component: ListTaskComponent,
        canActivate: [HasPendingTaskGuard]
      },
      { path: 'task/RCRA/:id', component: RcraComponent },
      { path: 'task/WA/:id', component: WaComponent },
      { path: 'task/TX/:id', component: TxComponent },
      { path: 'task/CA/:id', component: CaComponent },
      { path: 'task/flash_point/:id', component: FlashPointComponent },
      { path: 'task/flashpoint/:id', component: FlashPointComponent },
      { path: 'task/boiling_point/:id', component: BoilingPointComponent },
      { path: 'task/ph/:id', component: PhComponent },
      { path: 'task/un_number/:id', component: UnNumberComponent },
      {
        path: 'task/cas_number_and_weights/:id',
        component: CasNumberAndWeightsComponent
      },
      { path: 'task/form/:id', component: FormComponent },
      { path: 'task/intended_use/:id', component: IntendedUseComponent },
      { path: 'task/find_product_info/:id', component: FindProductInfoComponent },
      { path: 'task/sds_find_info/:id', component: SdsFindInfoComponent },
      { path: 'task/validate_sds_pairing/:id', component: ValidateSdsPairingComponent },
      { path: 'task/melting_point/:id', component: MeltingPointComponent },
      { path: 'task/hazard/:id', component: HazardComponent },
      { path: 'task/ISHiP/:id', component: IshipComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
