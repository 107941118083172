import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { RequestService } from 'src/app/shared/services/request/request.service';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';

@Component({
  selector: 'app-intended-use',
  templateUrl: './intended-use.component.html',
  styleUrls: ['./intended-use.component.less']
})

export class IntendedUseComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  intended_use_categories = [];
  showSubcategories = false;
  selectedCategory = '';
  selectedSubcategory = '';
  recommended_use_from_sds = '';
  isDisabled: boolean;
  treeData = [];

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public activeRoute: ActivatedRoute,
    private expertService: ExpertService
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.intended_use_categories = this.requestService.intended_use_categories;
    this.form = this.formBuilder.group({
      intended_use: ['', Validators.required],
      intended_use_subCategory: '',
      recommended_use: this.expertService.getData() == null ? ['', Validators.required] : [''],
      recommended_use_check: [false]
    });

    if(this.expertClassificationMode){
      this.expertService.getExpertData$.subscribe((data: any) => {
        if (data) {
          this.treeData.push(data);
          this.loadInfo(data.intended_use)
        }
      })
    }
  }

  get f() {
    return this.form.controls;
  }

  changeFormat() {
    this.hideAll();
    if (this.f.intended_use.value.subcategories) {
      this.showSubcategories = true;
      this.selectedSubcategory = this.f.intended_use.value.subcategories;
      this.f.intended_use_subCategory.setValidators(Validators.required);
      this.f.intended_use_subCategory.updateValueAndValidity();
      this.f.intended_use_subCategory.setValue('');
    } else {
      this.f.intended_use_subCategory.setValue('');
      this.f.intended_use_subCategory.setValidators(null);
      this.f.intended_use_subCategory.updateValueAndValidity();
    }
  }
  recommendedUseCheck(e) {
    this.isDisabled = this.f.recommended_use_check.value;
    if (this.f.recommended_use_check.value) {
      this.f.recommended_use.setValue("Not Found");
    } else {
      this.f.recommended_use.setValue('');
    }
  }
  hideAll() {
    this.showSubcategories = false;
    this.selectedSubcategory = '';
  }

  loadInfo(data){
    if (data.intended_use) {
      let selected_intended_use = this.requestService.intended_use_categories.find(e => e.keyword == data.intended_use)

      this.f.intended_use.setValue(selected_intended_use)
      this.changeFormat();

      if (data.intended_use_subCategory) {
        this.f.intended_use_subCategory.setValue(selected_intended_use.subcategories.find(e => e.keyword == data.intended_use_subCategory));
      } else {
        this.f.intended_use_subCategory.setValue('');
      }
    } else {
      this.f.intended_use.setValue('');
      this.changeFormat();
    }
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      const request = this.requestService.createIntendedUse(this.form, this.expertClassificationMode);
      this.post(request);
    }
    if (this.f.intended_use_subCategory.invalid) {
      this.toastr.error('Please select an intended use subcategory');
    }
    if (this.f.intended_use.invalid || this.f.recommended_use.invalid) {
      this.toastr.error('Please write the recommended use and an intended use');
    }
  }

  discard(){
    this.expertService.exitComponent();
    this.router.navigate(['../transportation'], { relativeTo: this.route });
  }

  accept(){
    this.submitted = true;
    if (this.form.valid) {
      const request = this.requestService.createIntendedUse(this.form, this.expertClassificationMode);
      // call Jonathans service
      this.router.navigate(['../transportation'], { relativeTo: this.route });
      this.expertService.exitComponent();
      const data = { type: "intended_use", payload: request, targetId: this.treeData[0].id };
      this.expertService.accept(data);
    }
  }
}
