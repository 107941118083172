import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { map } from "rxjs/operators";
// import 'rxjs/add/operator/catch';
@Component({
  selector: 'app-packaging-info',
  templateUrl: './packaging-info.component.html',
  styleUrls: ['./packaging-info.component.less']
})
export class PackagingInfoComponent implements OnInit {
  basicInfoData: any;
  constructor(public router: Router, public route: ActivatedRoute, private expertService: ExpertService) { }

  getData() {
    return this.expertService
      .getExpertData$.pipe(
        map(
          (data) => {
            this.basicInfoData = data;
          })
      );
  }

  ngOnInit() {
    this.getData().subscribe(_ => {
      ;
    });
  }
  
  packaging_modal() {
    if ("outerPackaging" in this.basicInfoData) {
      return "outer_and_inner";
    } else if (this.basicInfoData['product_type'] != 'Single') {
      return "inner";
    } else {
      return "single";
    }

  }
  close() {
    this.expertService.exitComponent();
    this.router.navigate(['../transportation'], { relativeTo: this.route });
  }
}
