import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators, Form } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { RequestService } from 'src/app/shared/services/request/request.service';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';

@Component({
  selector: 'app-tx',
  templateUrl: './tx.component.html',
  styleUrls: ['./tx.component.less']
})
export class TxComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  codes = [];
  classCodes = [];
  treeData = [];

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public activeRoute: ActivatedRoute,
    private expertService: ExpertService
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.codes = this.requestService.tx_state_codes;
    this.classCodes = this.requestService.tx_class_codes;
    
    if (this.expertClassificationMode) {
      let unk = [{ code: 'unknown', desc: 'Unknown' }];
      let none = [{ code: 'none', desc: 'None' }];
      this.codes = [...unk, ...this.requestService.tx_state_codes];
      this.classCodes = [...unk, ...this.classCodes];
    }

    this.form = this.formBuilder.group({
      txcode: ['', [Validators.required]],
      txclasscode: ['', [Validators.required]]
    });
    this.f.txclasscode.setValue('');
    this.f.txcode.setValue('');

    if (this.expertClassificationMode) {
      this.expertService.getExpertData$.subscribe((data: any) => {
        if (data) {
          this.treeData = [];
          this.treeData.push(data);
          if (data.waste.tx == null) {
            data.waste.tx = {
              code: '',
              class_code: ''
            }
          }
          let code = '';
          if (data.waste.tx.code != 'none') {
            code = this.codes.find(code => code.code === data.waste.tx.code);
            if (code == undefined)
              code = '';
          } else
            code = data.waste.tx.code;
          this.f.txcode.setValue(code);

          let class_code = '';
          if (data.waste.tx.class_code != 'none') {
            class_code = this.classCodes.find(code => code.code === data.waste.tx.class_code);
            if (class_code == undefined)
              class_code = '';
          } else
            class_code = data.waste.tx.class_code;
          this.f.txclasscode.setValue(class_code);
        }
        
      });
    }

  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      const request = { txformcode: this.f['txcode'].value, txclasscode: this.f['txclasscode'].value, tx_codes: {codes: this.codes, classcodes: this.classCodes} };
      this.post(request);
    }
  }

  onChange() {
    const data = {
      type: "waste",
      payload: {
        ...this.treeData[0].waste,
        tx: {
          code: this.f['txcode'].value === undefined ? '' : this.f['txcode'].value != 'none' ? this.f['txcode'].value.code : 'none',
          class_code: this.f['txclasscode'].value === '' ? '' : this.f['txclasscode'].value != 'none' ? this.f['txclasscode'].value.code : 'none',
          tx_codes: {codes: this.codes, classcodes: this.classCodes}
        }
      },
      targetId: this.treeData[0].id
    };
    this.expertService.accept(data);
  }

  get f() {
    return this.form.controls;
  }
}
