import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ValidatorsService } from 'src/app/shared/services/validators/validators.service';
@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.less']
})
export class RightPanelComponent implements OnInit {
  isDisabled = false;
  cancel = false;
  window = null;
  subscription: Subscription;
  data = {};

  constructor(private expertService: ExpertService,
              public router: Router,
              public toastr: ToastrService, 
              private validators: ValidatorsService ) {
    this.subscription = this.expertService.disableInteraction$.subscribe(state => this.isDisabled = state);
  }

  ngOnInit() {
  }

  handleStateAction(action) {
    this.data = this.expertService.getStateForm;
    if (action === 'submit') {
      this.data["expert_data"]["type"] = 'submit'
      if (this.validators.validate(this.data["request"])) {
        let finalData = this.selectKeyRemover(this.data["request"])
        this.expertService.submit(this.data["expert_data"]["task_id"], finalData).subscribe(
          res => {
            this.toastr.success('Result submited');
            this.router.navigate(['/private/task/list']);
          },
          err => {
            this.toastr.error(err.message);
          }
        );
      }
    } else if (action === 'save') {
      this.data["expert_data"]["type"] = 'save'
      this.expertService.save(this.data["expert_data"]["task_id"], this.data).subscribe(
        res => {
          this.toastr.success('Result saved');
        },
        err => {
          this.toastr.error(err.message);
        }
      );
    }
  }

  selectKeyRemover(data) {
    let children = data['children'];
    if (children.length > 0) {
      for (const key in children) {
        let subChild = children[key]['children'];
        if (subChild.length > 0) {
          for (const subC in subChild) {
            let subTransportation = subChild[subC]['transportation']
            if (subTransportation.length) {
              data.subChild[subC]['transportation'] = this.deleteSelectProperties(subTransportation)
            }
          }
        }
          data.children[key]['transportation'] = this.deleteSelectProperties(data.children[key]['transportation'])
      }
    }
  
    data['transportation'] = this.deleteSelectProperties(data['transportation'])
    return data
  }

  deleteSelectProperties(val) {
    for (const subKey in val) {
      delete val[subKey].select_un_number
      delete val[subKey].select_proper_shipping_name
      delete val[subKey].select_technical_name
      delete val[subKey].select_reportable_quantity
    }
    return val;
  }
}
