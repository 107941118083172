import { Component, OnInit , EventEmitter, Input, Output } from '@angular/core';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.less']
})

export class TreeComponent implements OnInit {
  // @Input() themeVar: string;
  selectedItemId = '';
  toChild = [];
  console = console;
  isDisabled = false;
  subscription: Subscription;
  sds_url = [];
  window = null;

  constructor(private expertService: ExpertService,
              private route: ActivatedRoute) {

    this.subscription = this.expertService.getTreeData$.subscribe(treeData => {
      if (treeData) {
        this.toChild = []
        this.toChild.push(treeData)
        if (!this.selectedItemId && !this.expertService.getSelectedTreeItem) {
          this.openSdsUrl(treeData.sds_url)
          this.selectedItemId = treeData.id;
        } else {
          this.selectedItemId = this.expertService.getSelectedTreeItem;
        }
      }
    })
    
    this.subscription = this.expertService.disableInteraction$.subscribe(state => this.isDisabled = state);
  }

  ngOnInit() {  }

  openSdsUrl(url) {
    if (url) 
      this.open(url);
    else 
      this.close()
  }

  open(url) {
    this.sds_url = url
    const leftPos = screen.width;
    this.window = window.open(
      url,
      'myWindow',
      `width=800,height=600, left=${leftPos}`
    );
  }

  close() {
    const leftPos = screen.width;
    this.window = window.open('/nocontent', 'myWindow', `width=800,height=600, left=${leftPos}`);
  }

  handleItemSelection(treeItem) {
    this.expertService.setSelectedTreeItem = treeItem.id
    this.selectedItemId = this.expertService.getSelectedTreeItem
    this.openSdsUrl(treeItem.sds_url)
    this.expertService.sendSelectedTreeItem(treeItem);
  }

  ngOnDestroy() {
    // this.expertService.lastId = 0;
    this.subscription.unsubscribe();
  }
}