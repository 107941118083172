import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { RequestService } from 'src/app/shared/services/request/request.service';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';

@Component({
  selector: 'app-cas-number-and-weights',
  templateUrl: './cas-number-and-weights.component.html',
  styleUrls: ['./cas-number-and-weights.component.less']
})
export class CasNumberAndWeightsComponent extends BaseComponent implements OnInit {
  @Input() themeVar: string;
  public mturkForm: FormGroup;
  public symbols = [];
  public symbolsMin = [];
  public symbolsMax = [];
  public showRange = [];
  showType = true;
  treeData = [];

  private notFoundStatus = true;

  private compositionType = {
    nameOnly: 'name_only',
    casNumber: 'cas_number',
    proprietary: 'proprietary',
    notFound: 'not_found'
  };

  private percentRangeType = {
    single: 'single_number',
    range: 'range_given',
    notFound: 'not_found'
  };

  private inputType = {
    cas: 'cas',
    name: 'name',
    percent: 'percent'
  };

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public activeRoute: ActivatedRoute,
    private expertService: ExpertService
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.symbols = this.requestService.symbols;
    this.symbolsMin = this.requestService.symbols_min;
    this.symbolsMax = this.requestService.symbols_max;

    this.mturkForm = this.formBuilder.group({
      pdf_type: this.expertService.getData() == null ? ['', Validators.required] : ['sds'],
      composition: this.formBuilder.array([])
    });

    this.addNewFormGroup();

    if(this.expertClassificationMode){
      this.expertService.getExpertData$.subscribe((data: any) => {
        if (data) {
          this.treeData.push(data);
          this.showType = false;
          this.loadInfo(data.cas_numbers_and_concentration);
        }
      });
    }
  }

  loadInfo(info){
    if(info.ingredients.some(e => e.type === this.compositionType.notFound)){
      this.resetForm();
      this.composition.push(this.newFormArray(this.compositionType.notFound));
      this.pushCasNumber(0, this.composition);
      this.notFoundStatus = false;
    }else{
      info.ingredients.forEach((e, i) => {
        this.loadGroupInfo(e, i);
      })
    }
  }

  loadGroupInfo(group_info, index){
    let group = null;

    switch(group_info.percent_range){
      case 'single_number':
        group = this.formBuilder.group({
            type: [group_info.type, Validators.required],
            cas_number: this.loadCasNumbers(group_info.cas_number),
            cas_name: this.loadCasName(group_info.cas_name),
            symbol: group_info.symbol,
            percent_range: group_info.percent_range,
            percent: [group_info.percent, [
                Validators.required,
                Validators.min(0),
                Validators.max(100)
              ]
            ],
            percent_min: null,
            percent_max: null,
            symbol_min: null,
            symbol_max: null
          });
        break;

        case 'range_given':
          const percentValidations = [
            Validators.required,
            Validators.min(0),
            Validators.max(100),
            this.percentageRangeValidator.bind(this)
          ];
          group = this.formBuilder.group({
              type: [group_info.type, Validators.required],
              cas_number: this.loadCasNumbers(group_info.cas_number),
              cas_name: this.loadCasName(group_info.cas_name),
              symbol: null,
              percent_range: group_info.percent_range,
              percent: null,
              percent_min: group_info.percent_min,
              percent_max: group_info.percent_max,
              symbol_min: [group_info.symbol_min, Validators.required],
              symbol_max: [group_info.symbol_max, Validators.required]
            });
            this.setValidations(group, 'percent_min', percentValidations);
            this.setValidations(group, 'percent_max', percentValidations);
          break;

      default:
        group = this.formBuilder.group({
          type: [group_info.type, Validators.required],
          cas_number: this.loadCasNumbers(group_info.cas_number),
          cas_name: this.loadCasName(group_info.cas_name),
          symbol: null,
          percent_range: group_info.percent_range,
          percent: null,
          percent_min: null,
          percent_max: null,
          symbol_min: null,
          symbol_max: null
        });
        break;
    }

    if (group == null)
      return ;

    if (index != 0)
      this.composition.push(group);
    else
      this.composition.controls[0] = group;
  }

  // NOTE: While this is built to iterate it actually only takes a
  // single arguement, rather than an array. Consider refactoring.
  loadCasNumbers(cas_numbers) {
    let cas_forms = this.formBuilder.array([]);

    // length here is identifying nil string rather than an empty array
    if (cas_numbers.length == 0) {
      cas_forms.push(
        this.formBuilder.group({cas: null})
      );

      return cas_forms;
    }

    cas_numbers.forEach((cas_number) => {
      cas_forms.push(
        this.formBuilder.group({cas: cas_number.cas})
      );
    })

    return cas_forms
  }

  // NOTE: This is only to maintain the same contract to pass in null, but if we nullify empty strings we can drop this.
  loadCasName(cas_name) {
    if(cas_name) {
      return cas_name;
    } else {
      return null;
    }
  }

  private addNewFormGroup() {
    this.composition.push(this.newFormArray(''));
    const index = this.composition.length - 1;
    this.pushCasNumber(index, this.composition);
  }

  private newFormArray(ingredientType: string) {
    return this.formBuilder.group({
      type: [ingredientType, Validators.required],
      cas_number: this.formBuilder.array([]),
      cas_name: null,
      symbol: null,
      percent_range: '',
      percent: null,
      percent_min: null,
      percent_max: null,
      symbol_min: null,
      symbol_max: null
    });
  }

  get composition() {
    return this.mturkForm.get('composition') as FormArray;
  }

  public pushCasNumber(index: number, mturk) {
    (mturk.controls[index].controls.cas_number as FormArray).push(
      this.formBuilder.group({
        cas: null
      })
    );
  }

  public addNewCasNumber(mturkIndex, composition) {
    this.pushCasNumber(mturkIndex, composition);
    composition.controls[mturkIndex].get('cas_number').controls.forEach(key => {
      this.setValidations(key, 'cas', [Validators.required, Validators.pattern(/^[.\d.-]+$/)]);
    });
  }

  public removeCas(casNumber, casIndex: number) {
    (casNumber as FormArray).removeAt(casIndex);
  }

  public removeControls(index) {
    (this.mturkForm.get('composition') as FormArray).removeAt(index);
  }

  public displayInput(control, type: string) {
    switch (type) {
      case this.inputType.cas: {
        return control.value.type === this.compositionType.casNumber;
      }
      case this.inputType.name: {
        return control.value.type === this.compositionType.nameOnly;
      }
      case this.inputType.percent: {
        return control.value.type !== this.compositionType.notFound && control.value.type !== '';
      }
      default: {
        return false;
      }
    }
  }

  public displayPercent(control, type: string) {
    switch (type) {
      case 'single': {
        return control.value.percent_range === this.percentRangeType.single;
      }
      case 'multi': {
        return control.value.percent_range === this.percentRangeType.range;
      }
      default: {
        return false;
      }
    }
  }

  public addControls() {
    this.addNewFormGroup();
  }

  public typeChangeOnPdf() {
    this.resetForm();
    this.addNewFormGroup();
  }

  private resetForm() {
    while (this.composition.length !== 0) {
      this.composition.removeAt(0);
    }
  }

  public canAddIngredients() {
    return this.notFoundStatus;
  }

  public typeChangeOnIngredient(event, ingredient, index: number) {
    const ingredientValue = event.currentTarget.value;
    if (ingredientValue === this.compositionType.notFound) {
      this.resetForm();
      this.composition.push(this.newFormArray(ingredientValue));
      this.pushCasNumber(0, this.composition);
      this.notFoundStatus = false;
    } else {
      this.notFoundStatus = true;
      this.resetCasArray(ingredient.get('cas_number'), index);
      this.modifyIngredientsValidations(ingredient, ingredientValue);
    }
  }

  private resetCasArray(formArray, index: number) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
    this.pushCasNumber(index, this.composition);
  }

  public rangeChangeOnPercentage(event, ingredient) {
    this.resetRangeValidations(ingredient);
    this.resetRangeFormElements(ingredient);
    const rangeValue = event.currentTarget.value;
    switch (rangeValue) {
      case this.percentRangeType.single:
        this.setValidations(ingredient, 'percent', [Validators.required, Validators.min(0), Validators.max(100)]);
        this.setValidations(ingredient, 'symbol', [Validators.required]);
        break;
      case this.percentRangeType.range:
        const percentValidations = [
          Validators.required,
          Validators.min(0),
          Validators.max(100),
          this.percentageRangeValidator.bind(this)
        ];
        this.setValidations(ingredient, 'percent_min', percentValidations);
        this.setValidations(ingredient, 'percent_max', percentValidations);
        this.setValidations(ingredient, 'symbol_min', [Validators.required]);
        this.setValidations(ingredient, 'symbol_max', [Validators.required]);
        break;
      case this.percentRangeType.notFound:
        break;
    }
  }

  private percentageRangeValidator(group: FormGroup): { [s: string]: boolean } {
    let error = { error: true };
    const percentMin = group.parent.get('percent_min');
    const percentMax = group.parent.get('percent_max');
    if (percentMin.value && percentMax.value && percentMin.value < percentMax.value) {
      error = null;
    }
    percentMin.setErrors(error);
    percentMax.setErrors(error);
    return error;
  }

  private resetRangeFormElements(formControl) {
    formControl.get('percent').setValue(null);
    formControl.get('percent_min').setValue(null);
    formControl.get('percent_max').setValue(null);
    formControl.get('symbol').setValue(null);
    formControl.get('symbol_min').setValue(null);
    formControl.get('symbol_max').setValue(null);
  }

  private modifyIngredientsValidations(formControl, ingredient: string) {
    this.resetIngredientsValidations(formControl);
    this.resetIngredientFormElements(formControl);
    switch (ingredient) {
      case this.compositionType.casNumber:
        formControl.get('cas_number').controls.forEach(key => {
          this.setValidations(key, 'cas', [Validators.required, Validators.pattern(/^[.\d.-]+$/)]);
        });
        break;
      case this.compositionType.nameOnly:
        this.setValidations(formControl, 'cas_name', [Validators.required]);
        break;
      case this.compositionType.proprietary:
        break;
      case this.compositionType.notFound:
        break;
    }
    if (ingredient !== this.compositionType.notFound) {
      this.setValidations(formControl, 'percent_range', [Validators.required]);
    }
  }

  private resetIngredientFormElements(formControl) {
    formControl.get('cas_name').setValue(null);
    formControl.get('percent_range').setValue(null);
  }

  private resetIngredientsValidations(formControl) {
    formControl.get('cas_number').controls.forEach(key => {
      this.resetValidations(key, 'cas');
    });
    this.resetValidations(formControl, 'cas_name');
    this.resetValidations(formControl, 'percent_range');
  }

  private resetRangeValidations(formControl) {
    this.resetValidations(formControl, 'percent');
    this.resetValidations(formControl, 'percent_min');
    this.resetValidations(formControl, 'percent_max');
    this.resetValidations(formControl, 'symbol');
    this.resetValidations(formControl, 'symbol_min');
    this.resetValidations(formControl, 'symbol_max');
  }

  private resetValidations(formControl, formControlName: string) {
    formControl.get(formControlName).clearValidators();
    formControl.get(formControlName).updateValueAndValidity();
  }

  private setValidations(formControl, formControlName: string, ValidatorArray) {
    formControl.get(formControlName).setValidators(ValidatorArray);
    formControl.get(formControlName).updateValueAndValidity();
  }

  public submit() {
    this.submitted = true;
    if (this.mturkForm.valid) {
      this.post(this.processCasValues());
    }
  }

  discard(){
    this.expertService.exitComponent();
    this.router.navigate(['../transportation'], { relativeTo: this.route });
  }

  accept(){
    this.submitted = true;
    if (this.mturkForm.valid) {
      // call Jonathans service
      this.router.navigate(['../'], { relativeTo: this.route });
      this.expertService.exitComponent();
      const data = { type: "cas_numbers_and_concentration", payload: {ingredients: this.f.composition.value}, targetId: this.treeData[0].id };
      this.expertService.accept(data);
    }
  }

  private processCasValues() {
    const formValue = JSON.parse(JSON.stringify(this.mturkForm.value));
    formValue.composition.forEach(key => {
      const tempCas = {
        cas: null,
        additional_cas: []
      };
      if (key.type === this.compositionType.casNumber) {
        key.cas_number.forEach((keys, index) => {
          if (index === 0) {
            tempCas.cas = keys.cas;
          } else {
            tempCas.additional_cas.push(keys.cas);
          }
        });
      }
      if (tempCas.additional_cas.length === 0) {
        delete tempCas.additional_cas;
      }
      key.cas_number = tempCas;
    });
    return formValue;
  }

  get f() {
    return this.mturkForm.controls;
  }

  public checkError(name) {
    return name.errors;
  }

  errorCas(name) {
    return name.controls.cas.errors;
  }
}
