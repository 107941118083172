import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { RequestService } from 'src/app/shared/services/request/request.service';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { map } from "rxjs/operators";

@Component({
  selector: 'app-rcra',
  templateUrl: './rcra.component.html',
  styleUrls: ['./rcra.component.less']
})
export class RcraComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  uwaste_form: FormGroup;
  unknown = [];
  none = [];

  uwaste_codes_all = [];
  uwaste_codes = []
  uwaste_selectedCode: any;

  d_characteristic_codes_all = [];
  d_characteristic_codes = [];
  d_characteristic_selectedCodes: Array<any> = [];

  p_codes = [];
  p_codes_all = [];
  p_selectedCodes: Array<any> = [];

  d_characteristic_toxics_codes = [];
  d_characteristic_toxics_codes_all = [];
  d_characteristic_toxics_selectedCodes: Array<any> = [];

  u_codes = [];
  u_codes_all = [];
  u_selectedCodes: Array<any> = [];
  basicInfoData = [];
  allCodesSended = false;

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public activeRoute: ActivatedRoute,
    private expertService: ExpertService
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.uwaste_codes = [{ value: "None", desc: "None" }, { value: 'Unknown', desc: 'Unknown' }, { value: "UW–BATTERY", desc: "UW–BATTERY" }, { value: "UW–PESTICIDES", desc: "UW–PESTICIDES" },
    { value: "UW–MERCURY", desc: "UW–MERCURY" }, { value: "UW–LAMPS", desc: "UW–LAMPS" }, { value: "UW–AEROSOLS", desc: "UW–AEROSOLS" },
    { value: "UW–ANTIFREEZE", desc: "UW–ANTIFREEZE" }, { value: "UW-ELECTRONICS", desc: "UW-ELECTRONICS" },
    { value: "UW-OIL-BASED FINISHES", desc: "UW-OIL-BASED FINISHES" }, { value: "UW–PAINT", desc: "UW–PAINT" },
    { value: "UW–PHARMACEUTICALS", desc: "UW–PHARMACEUTICALS" }];
    this.none = [{ code: 'None', desc: 'None' }];
    this.unknown = [{ code: 'Unknown', desc: 'Unknown' }];
    this.d_characteristic_codes = this.requestService.d_characteristic_codes;
    this.d_characteristic_codes_all = [...this.none, ...this.unknown, ...this.requestService.d_characteristic_codes]

    this.d_characteristic_toxics_codes = this.requestService.d_characteristic_toxics_codes;
    this.d_characteristic_toxics_codes_all = [...this.none, ...this.unknown, ...this.d_characteristic_toxics_codes];

    this.p_codes = this.requestService.p_codes;
    this.p_codes_all = [...this.none, ...this.unknown, ...this.p_codes];

    this.u_codes = this.requestService.u_codes;
    this.u_codes_all = [...this.none, ...this.unknown, ...this.u_codes];

    this.d_characteristic_selectedCodes = [this.none[0]];
    this.d_characteristic_toxics_selectedCodes = [this.none[0]];
    this.p_selectedCodes = [this.none[0]];
    this.u_selectedCodes = [this.none[0]];

    this.form = this.formBuilder.group({
      d_characteristic_form: [this.d_characteristic_selectedCodes[0]],
      d_characteristic_toxics_form: [this.d_characteristic_toxics_selectedCodes[0]],
      p_code_form: [this.p_selectedCodes[0]],
      u_code_form: [this.u_selectedCodes[0]],

    });
    this.uwaste_form = this.formBuilder.group({ uwaste_code_form: "" });
    if (this.expertClassificationMode) {
      this.expertService.getExpertData$.subscribe((data: any) => {
        if (data) {
          this.basicInfoData = [];
          this.basicInfoData.push(data);
          if (data.waste.uwaste_code == null || data.waste.uwaste_code  === '') {
            data.waste.uwaste_code = [];
          } else {
            this.uwaste_form.setValue({ uwaste_code_form: (data.waste.uwaste_code[0] || "") })
          }

          // D CHARACTERISTIC
          this.d_characteristic_codes = this.requestService.d_characteristic_codes;
          this.d_characteristic_codes_all = [...this.none, ...this.unknown, ...this.requestService.d_characteristic_codes]
          this.d_characteristic_selectedCodes = [];
          this.f.d_characteristic_form.setValue('');
          if (data.waste.d_characteristic_codes == null) {
            data.waste.d_characteristic_codes = [];
          }
          let dCharacteristicsCodes = this.filterInvalidCodes(data.waste.d_characteristic_codes, 'dCharacteristics')
          if (dCharacteristicsCodes != undefined) {
            dCharacteristicsCodes.map(code => {
              this.f.d_characteristic_form.setValue(code);
              this.add('D', false)
            })
          }

          // D CHARACTERISTIC - TOXICS
          this.d_characteristic_toxics_codes = this.requestService.d_characteristic_toxics_codes;
          this.d_characteristic_toxics_codes_all = [...this.none, ...this.unknown, ...this.d_characteristic_toxics_codes];
          this.d_characteristic_toxics_selectedCodes = [];
          this.f.d_characteristic_toxics_form.setValue('');
          if (data.waste.d_characteristic_toxics_codes == null) {
            data.waste.d_characteristic_toxics_codes = [];
          }
          let dToxicsCodes = this.filterInvalidCodes(data.waste.d_characteristic_toxics_codes, 'dToxic')

          if (dToxicsCodes != undefined) {
            dToxicsCodes.map(code => {
              this.f.d_characteristic_toxics_form.setValue(code);
              this.add('CT', false)
            })
          }

          // P CODES
          this.p_codes = this.requestService.p_codes;
          this.p_codes_all = [...this.none, ...this.unknown, ...this.p_codes];
          this.p_selectedCodes = [];
          this.f.p_code_form.setValue('');
          if (data.waste.p_codes == null) {
            data.waste.p_codes = [];
          }
          // NOTE: Actually returns an array
          let pCode = this.filterInvalidCodes(data.waste.p_codes, 'p')
          if (pCode.length != 0) {
            this.f.p_code_form.setValue(pCode[0]);
            this.add("P", false);
          }

          // U CODES
          this.u_codes = this.requestService.u_codes;
          this.u_codes_all = [...this.none, ...this.unknown, ...this.u_codes];
          this.u_selectedCodes = [];
          this.f.u_code_form.setValue('');
          if (data.waste.u_codes == null) {
            data.waste.u_codes = [];
          }
          let uCode = this.filterInvalidCodes(data.waste.u_codes, 'u')
          if (uCode.length != 0) {
            this.f.u_code_form.setValue(uCode[0]);
            this.add('U', false)
          }
        }
      });
    }
  }

  filterInvalidCodes(codes, type) {
    const codesArr = [];

    if (codes == undefined || codes.length == 0) {
      return codesArr;
    }

    switch (type) {
      case 'p':
        this.p_codes_all.filter((p, _i) => {
          if (codes.includes(p.code)) {
            codesArr.push(p.code)
          }
        })
        break;
      case 'u':
        this.u_codes_all.filter((u, _i) => {
          if (codes.includes(u.code)) {
            codesArr.push(u.code)
          }
        })
        break;
      case 'dToxic':
        this.d_characteristic_toxics_codes_all.filter((tc, _i) => {
          if (codes.includes(tc.code)) {
            codesArr.push(tc)
          }
        })
        break;
      case 'dCharacteristics':
        this.d_characteristic_codes_all.filter((cCode, _i) => {
          if (codes.includes(cCode.code)) {
            codesArr.push(cCode)
          }
        })
        break;
      default:
        break;
    }
    return codesArr;
  }

  add(formSelected, not_init = true) {
    switch (formSelected) {
      case 'D':
        if (this.f.d_characteristic_form.value.desc === this.none[0].desc) {
          if (!this.d_characteristic_selectedCodes.includes(this.f.d_characteristic_form.value)) {
            this.d_characteristic_selectedCodes.push(this.f.d_characteristic_form.value);
          }
          this.d_characteristic_selectedCodes = this.d_characteristic_selectedCodes.filter(code => code.desc === this.none[0].desc);
          if (this.expertClassificationMode) {
            this.d_characteristic_codes_all = [...this.none, ...this.unknown, ...this.d_characteristic_codes];
            // this.f.d_characteristic_form.setValue("None");
          } else {
            this.d_characteristic_codes_all = [...this.none, ...this.unknown, ...this.d_characteristic_codes];
            this.f.d_characteristic_form.setValue('');
          }
        } else if (this.f.d_characteristic_form.value.desc === this.unknown[0].desc) {
          if (!this.d_characteristic_selectedCodes.includes(this.f.d_characteristic_form.value)) {
            this.d_characteristic_selectedCodes.push(this.f.d_characteristic_form.value);
          }
          this.d_characteristic_selectedCodes = this.d_characteristic_selectedCodes.filter(code => code.desc === this.unknown[0].desc);
          this.d_characteristic_codes_all = [...this.none, ...this.unknown, ...this.d_characteristic_codes];
        } else {
          this.d_characteristic_selectedCodes.push(this.f.d_characteristic_form.value);
          this.d_characteristic_selectedCodes = this.d_characteristic_selectedCodes.filter(x => x.desc !== this.none[0].desc);
          this.d_characteristic_selectedCodes = this.d_characteristic_selectedCodes.filter(x => x.desc !== this.unknown[0].desc);
          this.d_characteristic_codes_all = this.d_characteristic_codes_all.filter(x => !this.d_characteristic_selectedCodes.includes(x));
          this.f.d_characteristic_form.setValue('');
        }
        if (not_init && this.expertClassificationMode) {
          this.update_data("d_characteristic_codes", this.get_codes_array(this.d_characteristic_selectedCodes));
        }
        break;
      case 'P':
        this.f.p_code_form.setValue(this.f.p_code_form.value)
        this.p_selectedCodes.length = 0
        if (not_init && this.expertClassificationMode) {
          this.update_data("p_codes", [this.f.p_code_form.value]);
        }
        break;
      case 'CT':
        if (this.f.d_characteristic_toxics_form.value.desc === this.none[0].desc) {
          if (!this.d_characteristic_toxics_selectedCodes.includes(this.f.d_characteristic_toxics_form.value)) {
            this.d_characteristic_toxics_selectedCodes.push(this.f.d_characteristic_toxics_form.value);
          }
          this.d_characteristic_toxics_selectedCodes = this.d_characteristic_toxics_selectedCodes.filter(x => x.desc === this.none[0].desc);
          if (this.expertClassificationMode) {
            this.d_characteristic_toxics_codes_all = [...this.none, ...this.unknown, ...this.d_characteristic_toxics_codes];
            // this.f.d_characteristic_form.setValue("None");
          } else {
            this.d_characteristic_toxics_codes_all = [...this.none, ...this.unknown, ...this.d_characteristic_toxics_codes];
            this.f.d_characteristic_toxics_form.setValue('');
          }
        } else if (this.f.d_characteristic_toxics_form.value.desc === this.unknown[0].desc) {
          if (!this.d_characteristic_toxics_selectedCodes.includes(this.f.d_characteristic_toxics_form.value)) {
            this.d_characteristic_toxics_selectedCodes.push(this.f.d_characteristic_toxics_form.value);
          }
          this.d_characteristic_toxics_selectedCodes = this.d_characteristic_toxics_selectedCodes.filter(x => x.desc === this.unknown[0].desc);
          this.d_characteristic_toxics_codes_all = [...this.none, ...this.unknown, ...this.d_characteristic_toxics_codes];

        } else {
          this.d_characteristic_toxics_selectedCodes.push(this.f.d_characteristic_toxics_form.value);
          this.d_characteristic_toxics_selectedCodes = this.d_characteristic_toxics_selectedCodes.filter(x => x.desc !== this.none[0].desc);
          this.d_characteristic_toxics_selectedCodes = this.d_characteristic_toxics_selectedCodes.filter(x => x.desc !== this.unknown[0].desc);
          this.d_characteristic_toxics_codes_all = this.d_characteristic_toxics_codes_all.filter(x => !this.d_characteristic_toxics_selectedCodes.includes(x));
          this.f.d_characteristic_toxics_form.setValue('');
        }
        if (not_init && this.expertClassificationMode) {
          this.update_data("d_characteristic_toxics_codes", this.get_codes_array(this.d_characteristic_toxics_selectedCodes));
        }
        break;
      case 'U':
        this.f.u_code_form.setValue(this.f.u_code_form.value)
        this.u_selectedCodes.length = 0
        if (not_init && this.expertClassificationMode) {
          this.update_data("u_codes", [this.f.u_code_form.value]);
        }
        break;
      default:
        break;
    }
  }

  public remove(i, formSelected, not_init = true) {
    if (formSelected === 'CT') {
      if (i === this.none[0].desc) {
        this.form.controls.d_characteristic_toxics_form.setValue('');
      }
      this.d_characteristic_toxics_selectedCodes = this.d_characteristic_toxics_selectedCodes.filter(x => x.desc !== i);
      this.d_characteristic_toxics_codes_all = this.d_characteristic_toxics_codes.filter(x => !this.d_characteristic_toxics_selectedCodes.includes(x));
      this.d_characteristic_toxics_codes_all = [...this.none, ...this.unknown, ...this.d_characteristic_toxics_codes_all];
      if (not_init && this.expertClassificationMode) {
        this.update_data("d_characteristic_toxics_codes", this.get_codes_array(this.d_characteristic_toxics_selectedCodes));
      }
    }
    if (formSelected === 'D') {
      if (i === this.none[0].desc) {
        this.form.controls.d_characteristic_form.setValue('');
      }
      this.d_characteristic_selectedCodes = this.d_characteristic_selectedCodes.filter(x => x.desc !== i);
      this.d_characteristic_codes_all = this.d_characteristic_codes.filter(x => !this.d_characteristic_selectedCodes.includes(x));
      this.d_characteristic_codes_all = [...this.none, ...this.unknown, ...this.d_characteristic_codes_all];
      if (not_init && this.expertClassificationMode) {
        this.update_data("d_characteristic_codes", this.get_codes_array(this.d_characteristic_selectedCodes));
      }
    }
  }
  checkGreaterThanZero(selection: any) {
    if (selection.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  submit() {
    this.submitted = true;
    const allSelections = [this.d_characteristic_selectedCodes, this.d_characteristic_toxics_selectedCodes, [this.f.p_code_form.value], [this.f.u_code_form.value]];

    if (allSelections.every(this.checkGreaterThanZero)) {
      this.post({
        d_characteristic_codes: this.d_characteristic_selectedCodes,
        d_characteristic_toxics_codes: this.d_characteristic_toxics_selectedCodes,
        p_codes: this.f.p_code_form.value,
        u_codes: this.f.u_code_form.value,
        rcra_codes: {
          d_characteristic_codes: this.d_characteristic_codes,
          d_characteristic_toxics_codes: this.d_characteristic_toxics_codes,
          p_codes: this.p_codes,
          u_codes: this.u_codes
        }
      });
    } else {
      this.toastr.error('Please select at least one code for each dropdown');
    }
  }
  change() {
    this.update_data("uwaste_code", [this.uwaste_form.value.uwaste_code_form]);
    // const data = {
    //   type: "waste",
    //   payload: { ...this.basicInfoData[0].waste, uwaste_code: [this.uwaste_form.value.uwaste_code_form], },
    //   targetId: this.basicInfoData[0].id
    // };
    // this.expertService.accept(data);
  }
  update_data(field, value) {
    const data = {
      type: "waste",
      payload: { ...this.basicInfoData[0].waste, [field]: value,
        rcra_codes: {
          d_characteristic_codes: this.d_characteristic_codes,
          d_characteristic_toxics_codes: this.d_characteristic_toxics_codes,
          p_codes: this.p_codes,
          u_codes: this.u_codes
      }},
      targetId: this.basicInfoData[0].id
    };
    this.expertService.accept(data);
  }
  get_codes_array(selected_array) {
    var code_array = [];
    for (var selected in selected_array) {
      code_array.push(selected_array[selected].code);
    }
    return code_array;
  }
  // get uwaste_selected(): any {
  //   return this.form.get('uwaste_selected');
  // }
  get f() {
    return this.form.controls;
  }
}
