import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { RequestService } from 'src/app/shared/services/request/request.service';

@Component({
  selector: 'app-un-number',
  templateUrl: './un-number.component.html',
  styleUrls: ['./un-number.component.less']
})
export class UnNumberComponent extends BaseComponent implements OnInit {
  public form: FormGroup;
  public dotNumber = false;
  public iataNumber = false;
  public imdgNumber = false;
  public disabledDotName = false;
  public disabledIataName = false;
  public disabledImdgName = false;

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public activeRoute: ActivatedRoute
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      un_dot_grp: this.formBuilder.group({
        un_dot: ['', [Validators.required, this.numberValidation]],
        dot_proper_shipping_name: [{
          value: '',
          disabled: this.disabledDotName
        }, [Validators.required, this.noWhitespaceValidator]]
      }),
      un_iata_grp: this.formBuilder.group({
        un_iata: ['', [Validators.required, this.numberValidation]],
        iata_proper_shipping_name: [{
          value: '',
          disabled: this.disabledIataName
        }, [Validators.required, this.noWhitespaceValidator]]
      }),
      un_imdg_grp: this.formBuilder.group({
        un_imdg: ['', [Validators.required, this.numberValidation]],
        imdg_proper_shipping_name: [{
          value: '',
          disabled: this.disabledImdgName
        }, [Validators.required, this.noWhitespaceValidator]]
      })
    });
  }

  disableShippingName(controlName) {
    Object.keys(this.form.controls).forEach((key) => {
      if ((this.form.controls[key] as any) instanceof FormGroup && (this.form.controls[key] as any).controls[controlName]) {
        if ((this.form.controls[key] as any).controls[controlName].disabled) {
          (this.form.controls[key] as any).controls[controlName].enable();
        } else {
          (this.form.controls[key] as any).controls[controlName].disable();
        }
      }
    });
  }

  private noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {'whitespace': true};
  }

  private numberValidation(control: FormControl) {
    const value = control.value;
    if(value && value !== 'Not Regulated' && value !== 'Could Not Find'){
      const positiveIntCheckRegex = /^\d+$/;
      const isValid = positiveIntCheckRegex.test(value) && value.length === 4;
      return isValid ? null : {'notInteger': true};
    }
    return null;
  }

  trimTrailingSpaces(){
    if(this.form.value.un_dot_grp.dot_proper_shipping_name){
      this.form.value.un_dot_grp.dot_proper_shipping_name = this.form.value.un_dot_grp.dot_proper_shipping_name.trim();
    }
    if(this.form.value.un_iata_grp.iata_proper_shipping_name){
      this.form.value.un_iata_grp.iata_proper_shipping_name = this.form.value.un_iata_grp.iata_proper_shipping_name.trim();
    }
    if(this.form.value.un_imdg_grp.imdg_proper_shipping_name){
      this.form.value.un_imdg_grp.imdg_proper_shipping_name = this.form.value.un_imdg_grp.imdg_proper_shipping_name.trim();
    }
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      this.trimTrailingSpaces();
      const request = this.requestService.createUnNumber(this.form);
      this.post(request);
    }
  }
}
