import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AngularTokenService } from 'angular-token';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.less']
})
export class PrivateComponent implements OnInit {
  @Output() handleTheme = new EventEmitter();
  user = '';
  themeVar = '';
  darkMode = false;
  title = 'PACE'
  constructor(private router: Router, private tokenService: AngularTokenService, public toastr: ToastrService) {}

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.themeVar = localStorage.getItem('theme');
    if(this.user["expert"])
      this.title = 'SPACE';
  }

  setTheme() {
    if (!this.darkMode) {
      this.darkMode = true;
      this.themeVar = 'container-dark';
      this.handleTheme.emit('container-dark');
      localStorage.setItem('theme', 'container-dark');
      return this.themeVar;
    }
    if (this.darkMode) {
      this.darkMode = false;
      this.themeVar = 'container-light';
      this.handleTheme.emit('container-light');
      localStorage.removeItem('theme');
      return this.themeVar;
    }
  }

  logout() {
    this.tokenService.signOut().subscribe(
      res => {
        localStorage.removeItem('user');
        this.router.navigate(['/login']);
      },
      error => {
        this.toastr.error('Failed trying to logout');
      }
    );
  }
}
