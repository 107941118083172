import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  symbols = [
    { value: 'no_symbol', text: 'No Symbol (or =)' },
    { value: '<', text: '< (or “less than”)' },
    { value: '<=', text: '<=' },
    { value: '>', text: '> (or “greater than”)' },
    { value: '>=', text: '>=' }
  ];

  symbols_degrees = [
    { value: 'no_symbol', text: 'No Symbol (or =)' },
    { value: '>', text: '> (or “greater than”)' }
  ];

  symbols_min = [
    { value: '>', text: '> (or “greater than”)' },
    { value: '>=', text: '>=' }
  ];

  symbols_max = [
    { value: '<', text: '< (or “less than”)' },
    { value: '<=', text: '<=' }
  ];
  units = [
    { value: 'fahrenheit', text: 'Fahrenheit (F)' },
    { value: 'celsius', text: 'Celsius (C)' },
    { value: 'not_given', text: 'Not given' }
  ];
  ca_state_codes = [
    {
      code: '121',
      desc:
        'Alkaline solution (pH > 12.5) with metals (antimony, arsenic, barium beryllium, cadmium, chromium, cobalt, copper, lead, mercury, molybdenum, nickel, selenium, silver, thallium, vanadium, and zinc)'
    },
    { code: '122', desc: 'Alkaline solution without metals pH > 12.5' },
    { code: '123', desc: 'Unspecified alkaline solution' },
    {
      code: '131',
      desc:
        'Aqueous solution (2 < pH < 12.5) containing reactive anions (azide, bromate, chlorate, cyanide, fluoride, hypochlorite, nitrite, perchlorate, and sulfide anions)'
    },
    { code: '132', desc: 'Aqueous solution with metals ( < restricted levels and see 121)' },
    { code: '133', desc: 'Aqueous solution with total organic residues 10 percent or more' },
    { code: '134', desc: 'Aqueous solution with total organic residues less than 10 percent' },
    { code: '135', desc: 'Unspecified aqueous solution' },
    { code: '141', desc: 'Off-specification, aged, or surplus inorganics' },
    { code: '151', desc: 'Asbestos-containing waste' },
    { code: '161', desc: 'FCC waste' },
    { code: '162', desc: 'Other spent catalyst' },
    { code: '171', desc: 'Metal sludge (see 121)' },
    { code: '172', desc: 'Metal dust (see 121) and machining waste' },
    { code: '181', desc: 'Other inorganic solid waste' },
    { code: '211', desc: 'Halogenated solvents (Chloroform, methyl chloride, perchloroethylene, etc.)' },
    { code: '212', desc: 'Oxygenated solvents (acetone, butanol, ethyl acetate, etc.)' },
    { code: '213', desc: 'Hydrocarbon solvents (benzene, hexane, Stoddard, etc.)' },
    { code: '214', desc: 'Unspecified solvent mixture' },
    { code: '221', desc: 'Waste oil and mixed oil' },
    { code: '222', desc: 'Oil / water separation sludge' },
    { code: '223', desc: 'Unspecified oil-containing waste' },
    { code: '231', desc: 'Pesticide rinse water' },
    { code: '232', desc: 'Pesticide and other waste associated with pesticide production' },
    { code: '241', desc: 'Tank bottom waste' },
    { code: '251', desc: 'Still bottom with halogenated organics' },
    { code: '252', desc: 'Other still bottom waste' },
    { code: '261', desc: 'Polychlorinated biphenyls and material containing PCBs' },
    { code: '271', desc: 'Organic monomer waste (includes unreacted resins)' },
    { code: '272', desc: 'Polymeric resin waste' },
    { code: '281', desc: 'Adhesives' },
    { code: '291', desc: 'Latex waste' },
    { code: '311', desc: 'Pharmaceutical waste' },
    { code: '321', desc: 'Sewage sludge' },
    { code: '322', desc: 'Biological waste other than sewage sludge' },
    { code: '331', desc: 'Off-specification, aged or surplus organics' },
    { code: '341', desc: 'Organic liquids (non-solvents) with halogens' },
    { code: '342', desc: 'Organic liquids with metals (see 121)' },
    { code: '343', desc: 'Unspecified organic liquid mixture' },
    { code: '351', desc: 'Organic solids with halogens' },
    { code: '352', desc: 'Other organic solids ' },
    { code: '411', desc: 'Alum and gypsum sludge' },
    { code: '421', desc: 'Lime sludge' },
    { code: '431', desc: 'Phosphate sludge' },
    { code: '441', desc: 'Sulfur sludge' },
    { code: '451', desc: 'Degreasing sludge' },
    { code: '461', desc: 'Paint sludge' },
    { code: '471', desc: 'Paper sludge / pulp' },
    { code: '481', desc: 'Tetraethyl lead sludge' },
    { code: '491', desc: 'Unspecified sludge waste' },
    { code: '511', desc: 'Empty pesticide containers 30 gallons or more' },
    { code: '512', desc: 'Other empty containers 30 gallons or more' },
    { code: '513', desc: 'Empty containers less than 30 gallons' },
    { code: '521', desc: 'Drilling mud' },
    { code: '531', desc: 'Chemical toilet waste' },
    { code: '541', desc: 'Photochemicals / photoprocessing waste' },
    { code: '551', desc: 'Laboratory waste chemicals' },
    { code: '561', desc: 'Detergent and soap' },
    { code: '571', desc: 'Fly ash, bottom ash, and retort ash' },
    { code: '581', desc: 'Gas scrubber waste' },
    { code: '591', desc: 'Baghouse waste' },
    { code: '611', desc: 'Contaminated soil from site clean-ups' },
    { code: '612', desc: 'Household wastes' },
    { code: '613', desc: 'Auto shredder waste' },
    { code: '711', desc: 'Liquids with cyanides ≥ 1000 Mg/L' },
    { code: '721', desc: 'Liquids with arsenic ≥ 500 Mg/L' },
    { code: '722', desc: 'Liquids with cadmium ≥ 100 Mg/L' },
    { code: '723', desc: 'Liquids with chromium (VI) ≥ 500 Mg/L' },
    { code: '724', desc: 'Liquids with lead ≥ 500 Mg/L' },
    { code: '725', desc: 'Liquids with mercury ≥ 20 Mg/L' },
    { code: '726', desc: 'Liquids with nickel ≥ 134 Mg/L' },
    { code: '727', desc: 'Liquids with selenium ≥ 100 Mg/L' },
    { code: '728', desc: 'Liquids with thallium ≥ 130 Mg/L' },
    { code: '731', desc: 'Liquids with polychlorinated biphenyls ≥ 50 Mg/L' },
    { code: '741', desc: 'Liquids with halogenated organic compounds ≥ 1000 Mg/L' },
    { code: '751', desc: 'Solids or sludges with halogenated organic compounds ≥ 1000 Mg/Kg' },
    { code: '791', desc: 'Liquid with pH ≤ 2' },
    {
      code: '792',
      desc:
        'Liquids with pH ≤ 2 with metals (antimony, arsenic, barium, beryllium, cadmium, chromium, cobalt, copper, lead, mercury, molybdenum, nickel, selenium, silver, thallium, vanadium and zinc)'
    },
    { code: '801', desc: 'Waste potentially containing dioxins' }
  ];
  tx_state_codes = [
    { code: '101', desc: 'Aqueous waste with low solvents' },
    { code: '102', desc: 'Aqueous waste with low other toxic organics' },
    { code: '103', desc: 'Spent acid with metals' },
    { code: '104', desc: 'Spent acid without metals' },
    { code: '105', desc: 'Acidic aqueous waste' },
    { code: '106', desc: 'Caustic solution with metals but no cyanides' },
    { code: '107', desc: 'Caustic solution with metals and cyanides' },
    { code: '108', desc: 'Caustic solution with cyanides but no metals' },
    { code: '109', desc: 'Spent caustic' },
    { code: '110', desc: 'Caustic aqueous waste' },
    { code: '111', desc: 'Aqueous waste with reactive sulfides' },
    { code: '112', desc: 'Aqueous waste with other reactives (e.g., explosives)' },
    { code: '113', desc: 'Other aqueous waste with high dissolved solids' },
    { code: '114', desc: 'Other aqueous waste with low dissolved solids' },
    { code: '115', desc: 'Scrubber water' },
    { code: '116', desc: 'Leachate' },
    { code: '117', desc: 'Waste liquid mercury' },
    { code: '119', desc: 'Other inorganic liquids (Specify in Comments)' },
    { code: '198', desc: 'Nonhazardous photographic chemical wastes (inorganic)' },
    { code: '199', desc: 'Brine solution that could also bear the form code 113' },
    { code: '201', desc: 'Concentrated solvent-water solution' },
    { code: '202', desc: 'Halogenated (e.g., chlorinated) solvent' },
    { code: '203', desc: 'Non-halogenated solvent' },
    { code: '204', desc: 'Halogenated/non-halogenated solvent mixture' },
    { code: '205', desc: 'Oil-water emulsion or mixture' },
    { code: '206', desc: 'Waste oil' },
    { code: '207', desc: 'Concentrated aqueous solution of other organics' },
    { code: '208', desc: 'Concentrated phenolics' },
    { code: '209', desc: 'Organic paint, ink, lacquer, or vanish' },
    { code: '210', desc: 'Adhesives or epoxies' },
    { code: '211', desc: 'Paint thinner or petroleum distillates' },
    { code: '212', desc: 'Reactive or polymerizable organic liquids' },
    { code: '219', desc: 'Other organic liquids (Specify in Comments)' },
    { code: '296', desc: 'Ethylene glycol based antifreeze' },
    {
      code: '297',
      desc: 'Nonhazardous liquids containing greater than or equal to (>) 50 and less than (<) 500 ppm PCBs'
    },
    { code: '298', desc: 'Nonhazardous liquids containing greater than or equal to (>) 500 ppm PCBs' },
    { code: '299', desc: 'Nonhazardous photographic chemical waste (organic)' },
    { code: '301', desc: 'Soil contaminated with organics' },
    { code: '302', desc: 'Soil contaminated with inorganics only' },
    { code: '303', desc: 'Ash, slag, or other residue from incineration of wastes' },
    { code: '304', desc: 'Other “dry” ash, slag, or thermal residue' },
    { code: '305', desc: '“Dry” lime or metal hydroxide solids chemically “fixed”' },
    { code: '306', desc: '“Dry” lime or metal hydroxide solids not “fixed”' },
    { code: '307', desc: 'Metal scale, filings, or scrap' },
    { code: '308', desc: 'Empty or crushed metal drums or containers' },
    { code: '309', desc: 'Batteries or battery parts, casings, cores' },
    { code: '310', desc: 'Spent solid filters or adsorbents' },
    { code: '311', desc: 'Asbestos solids and debris' },
    { code: '312', desc: 'Metal-cyanide salts/chemicals' },
    { code: '313', desc: 'Reactive cyanide salts/chemicals' },
    { code: '314', desc: 'Reactive sulfide salts/chemicals' },
    { code: '315', desc: 'Other reactive salts/chemicals' },
    { code: '316', desc: 'Other metal salts/chemicals' },
    { code: '319', desc: 'Other waste inorganic solids (Specify in Comments)' },
    { code: '388', desc: 'Empty or crushed glass containers' },
    { code: '389', desc: 'Nonhazardous sandblasting waste' },
    { code: '390', desc: 'Nonhazardous concrete/cement/ construction debris' },
    { code: '391', desc: 'Nonhazardous dewatered wastewater treatment sludge' },
    { code: '392', desc: 'Nonhazardous dewatered air pollution control device sludge' },
    { code: '393', desc: 'Catalyst waste' },
    {
      code: '394',
      desc: 'Nonhazardous solids containing greater than or equal to (>) 50 ppm and less than (<) 500 ppm PCBs'
    },
    { code: '395', desc: 'Nonhazardous solids containing greater than or equal to (>) 500 ppm PCBs' },
    {
      code: '396',
      desc:
        'Nonhazardous electrical equipment/devices containing greater than or equal to (>) 50ppm and less than (<) 500 ppm PCBs'
    },
    {
      code: '397',
      desc: 'Nonhazardous electrical equipment/devices containing greater than or equal to (>) 500 ppm PCBs'
    },
    {
      code: '398',
      desc: 'Nonhazardous soils containing greater than or equal to (>) 50 ppm and less than (<) 500 ppm PCBs'
    },
    { code: '399', desc: 'Nonhazardous soils containing greater than or equal to (>) 500 ppm PCBs' },
    { code: '401', desc: 'Halogenated pesticide solid' },
    { code: '402', desc: 'Non-halogenated pesticide solid' },
    { code: '403', desc: 'Solids resins or polymerized organics' },
    { code: '404', desc: 'Spent carbon' },
    { code: '405', desc: 'Reactive organic solid' },
    { code: '406', desc: 'Empty fiber or plastic containers' },
    { code: '407', desc: 'Other halogenated organic solids (Specify in Comments)' },
    { code: '409', desc: 'Other non-halogenated organic solids (Specify in Comments)' },
    { code: '488', desc: 'Wood debris' },
    { code: '489', desc: 'Petroleum contaminated solids' },
    { code: '490', desc: 'Sand blasting waste' },
    { code: '491', desc: 'Dewatered biological treatment sludge' },
    { code: '492', desc: 'Dewatered sewage or other untreated biological sludge' },
    { code: '493', desc: 'Catalyst waste' },
    { code: '494', desc: 'Solids containing greater than or equal to (>) 50 ppm and less than (<) 500 ppm PCBs' },
    { code: '495', desc: 'Solids containing greater than or equal to (>) 500 ppm PCBs' },
    {
      code: '496',
      desc: 'Electrical equipment/devices containing greater than or equal to (>) 50 ppm and less than (<) 500 ppm PCBs'
    },
    { code: '497', desc: 'Electrical equipment/devices containing greater than or equal to (>) 500 ppm PCBs' },
    { code: '498', desc: 'Soil containing greater than or equal to (>) 50 ppm and less than (<) 500 ppm PCBs' },
    { code: '499', desc: 'Soils containing greater than or equal to (>) 500 ppm PCBs' },
    { code: '501', desc: 'Lime sludge without metals' },
    { code: '502', desc: 'Lime sludge with metals/metal hydroxide sludge' },
    { code: '503', desc: 'Wastewater treatment sludge with toxic organics' },
    { code: '504', desc: 'Other wastewater treatment sludge' },
    { code: '505', desc: 'Untreated plating sludge without cyanides' },
    { code: '506', desc: 'Untreated plating sludge with cyanides' },
    { code: '507', desc: 'Other sludge with cyanides' },
    { code: '508', desc: 'Sludge with reactive sulfides' },
    { code: '509', desc: 'Sludge with other reactives' },
    { code: '510', desc: 'Degreasing sludge with metal scale or filings' },
    { code: '511', desc: 'Air pollution control device sludge (e.g., fly ash, wet scrubber sludge)' },
    { code: '512', desc: 'Sediment or lagoon dragout contaminated with organics' },
    { code: '513', desc: 'Sediment or lagoon dragout contaminated with inorganics only' },
    { code: '514', desc: 'Drilling mud' },
    { code: '515', desc: 'Asbestos slurry or sludge' },
    { code: '516', desc: 'Chloride or other brine sludge' },
    { code: '519', desc: 'Other inorganic sludges (Specify in Comments)' },
    { code: '597', desc: 'Catalyst waste' },
    {
      code: '598',
      desc: 'Nonhazardous sludges containing greater than or equal to (>) 50 ppm and less than (<) 500 ppm PCBs'
    },
    { code: '599', desc: 'Nonhazardous sludges containing greater than or equal to (>) 500 ppm PCBs' },
    { code: '601', desc: 'Still bottoms of halogenated (e.g., chlorinated) solvents or other organic liquids' },
    { code: '602', desc: 'Still bottoms on non-halogenated solvents or other organic liquids' },
    { code: '603', desc: 'Oily sludge' },
    { code: '604', desc: 'Organic paint or ink sludge' },
    { code: '605', desc: 'Reactive or polymerizable organics' },
    { code: '606', desc: 'Resins, tars, or tarry sludge' },
    { code: '607', desc: 'Biological treatment sludge' },
    { code: '608', desc: 'Sewage or other untreated biological sludge' },
    { code: '609', desc: 'Other organic sludges (Specify in Comments)' },
    { code: '695', desc: 'Petroleum contaminated sludges other than still bottoms and oily sludges' },
    { code: '696', desc: 'Grease' },
    { code: '697', desc: 'Catalyst waste' },
    {
      code: '698',
      desc: 'Nonhazardous sludges containing greater than or equal to (>) 50 ppm and less than (<) 500 ppm PCBs'
    },
    { code: '699', desc: 'Nonhazardous sludges containing greater than or equal to (>) 500 ppm PCBs' },
    { code: '701', desc: 'Inorganic gases' },
    { code: '801', desc: 'Organic gases' },
    { code: '902', desc: 'Supplemental plant production refuse' },
    { code: '999', desc: 'Plant Trash' }
  ];

  tx_class_codes = [{ code: '1' }, { code: '2' }, { code: '3' }, { code: 'H' }];

  none = [{ desc: 'None' }];
  unknown =[{desc: 'Unknown' }]
  d_characteristic_codes = [
    { code: 'D001', desc: 'Ignitable waste' },
    { code: 'D002', desc: 'Corrosive waste' },
    { code: 'D003', desc: 'Reactive waste' }
  ]
  
  d_characteristic_toxics_codes = [
    { code: 'D004', desc: 'Arsenic' },
    { code: 'D005', desc: 'Barium' },
    { code: 'D006', desc: 'Cadmium' },
    { code: 'D007', desc: 'Chromium' },
    { code: 'D008', desc: 'Lead' },
    { code: 'D009', desc: 'Mercury' },
    { code: 'D010', desc: 'Selenium' },
    { code: 'D011', desc: 'Silver' },
    {
      code: 'D012',
      desc:
        'Endrin(1,2,3,4,10,10-hexachloro-1,7-epoxy- 1,4,4a,5,6,7,8,8a-octahydro-1,4-endo, endo- 5,8-dimeth-ano-naphthalene)'
    },
    { code: 'D013', desc: 'Lindane (1,2,3,4,5,6-hexa- chlorocyclohexane, gamma isomer)' },
    { code: 'D014', desc: 'Methoxychlor (1,1,1-trichloro-2,2-bis [p- methoxyphenyl] ethane)' },
    { code: 'D015', desc: 'Toxaphene (C10 H10 Cl8, Technical chlorinated camphene, 67-69 percent chlorine)' },
    { code: 'D016', desc: '2,4-D (2,4-Dichlorophenoxyacetic acid)' },
    { code: 'D017', desc: '2,4,5-TP Silvex (2,4,5- Trichlorophenoxypropionic acid)' },
    { code: 'D018', desc: 'Benzene' },
    { code: 'D019', desc: 'Carbon tetrachloride' },
    { code: 'D020', desc: 'Chlordane' },
    { code: 'D021', desc: 'Chlorobenzene' },
    { code: 'D022', desc: 'Chloroform' },
    { code: 'D023', desc: 'o-Cresol' },
    { code: 'D024', desc: 'm-Cresol' },
    { code: 'D025', desc: 'p-Cresol' },
    { code: 'D026', desc: 'Cresol' },
    { code: 'D027', desc: '1,4-Dichlorobenzene' },
    { code: 'D028', desc: '1,2-Dichloroethane' },
    { code: 'D029', desc: '1,1-Dichloroethylene' },
    { code: 'D030', desc: '2,4-Dinitrotoluene' },
    { code: 'D031', desc: 'Heptachlor (and its epoxide)' },
    { code: 'D032', desc: 'Hexachlorobenzene' },
    { code: 'D033', desc: 'Hexachlorobutadiene' },
    { code: 'D034', desc: 'Hexachloroethane' },
    { code: 'D035', desc: 'Methyl ethyl ketone' },
    { code: 'D036', desc: 'Nitrobenzene' },
    { code: 'D037', desc: 'Pentachlorophenol' },
    { code: 'D038', desc: 'Pyridine' },
    { code: 'D039', desc: 'Tetrachloroethylene' },
    { code: 'D040', desc: 'Trichlorethylene' },
    { code: 'D041', desc: '2,4,5-Trichlorophenol' },
    { code: 'D042', desc: '2,4,6-Trichlorophenol' },
    { code: 'D043', desc: 'Vinyl chloride' }
  ];

  // k_codes = [
  //   {
  //     code: 'K001',
  //     desc:
  //       'Bottom sediment sludge from the treatment of wastewaters from wood preserving processes that use creosote and/or pentachlorophenol.'
  //   },
  //   { code: 'K002', desc: 'Wastewater treatment sludge from the production of chrome yellow and orange pigments.' },
  //   { code: 'K003', desc: 'Wastewater treatment sludge from the production of molybdate orange pigments.' },
  //   { code: 'K004', desc: 'Wastewater treatment sludge from the production of zinc yellow pigments.' },
  //   { code: 'K005', desc: 'Wastewater treatment sludge from the production of chrome green pigments.' },
  //   {
  //     code: 'K006',
  //     desc: 'Wastewater treatment sludge from the production of chrome oxide green pigments (anhydrous and hydrated).'
  //   },
  //   { code: 'K007', desc: 'Wastewater treatment sludge from the production of iron blue pigments.' },
  //   { code: 'K008', desc: 'Oven residue from the production of chrome oxide green pigments.' },
  //   { code: 'K009', desc: 'Distillation bottoms from the production of acetaldehyde from ethylene.' },
  //   { code: 'K010', desc: 'Distillation side cuts from the production of acetaldehyde from ethylene.' },
  //   { code: 'K011', desc: 'Bottom stream from the wastewater stripper in the production of acrylonitrile.' },
  //   { code: 'K013', desc: 'Bottom stream from the acetonitrile column in the production of acrylonitrile.' },
  //   { code: 'K014', desc: 'Bottoms from the acetonitrile purification column in the production of acrylonitrile.' },
  //   { code: 'K015', desc: 'Still bottoms from the distillation of benzyl chloride.' },
  //   { code: 'K016', desc: 'Heavy ends or distillation residues from the production of carbon tetrachloride.' },
  //   {
  //     code: 'K017',
  //     desc: 'Heavy ends (still bottoms) from the purification column in the production of epichlorohydrin.'
  //   },
  //   { code: 'K018', desc: 'Heavy ends from the fractionation column in ethyl chloride production.' },
  //   {
  //     code: 'K019',
  //     desc: 'Heavy ends from the distillation of ethylene dichloride in ethylene dichloride production.'
  //   },
  //   { code: 'K020', desc: 'Heavy ends from the distillation of vinyl chloride in vinyl chloride monomer production.' },
  //   { code: 'K021', desc: 'Aqueous spent antimony catalyst waste from fluoromethane production.' },
  //   { code: 'K022', desc: 'Distillation bottom tars from the production of phenol/acetone from cumene.' },
  //   { code: 'K023', desc: 'Distillation light ends from the production of phthalic anhydride from naphthalene.' },
  //   { code: 'K024', desc: 'Distillation bottoms from the production of phthalic anhydride from naphthalene.' },
  //   { code: 'K025', desc: 'Distillation bottoms from the production of nitrobenzene by the nitration of benzene.' },
  //   { code: 'K026', desc: 'Stripping still tails from the production of methyl ethyl pyridines.' },
  //   { code: 'K027', desc: 'Centrifuge and distillation residues from toluene diisocyanate production.' },
  //   {
  //     code: 'K028',
  //     desc: 'Spent catalyst from the hydrochlorinator reactor in the production of 1,1,1-trichloroethane.'
  //   },
  //   { code: 'K029', desc: 'Waste from the product steam stripper in the production of 1,1,1-trichloroethane.' },
  //   {
  //     code: 'K030',
  //     desc: 'Column bottoms or heavy ends from the combined production of trichloroethylene and perchloroethylene.'
  //   },
  //   { code: 'K031', desc: 'By-product salts generated in the production of MSMA and cacodylic acid.' },
  //   { code: 'K032', desc: 'Wastewater treatment sludge from the production of chlordane.' },
  //   {
  //     code: 'K033',
  //     desc: 'Wastewater and scrub water from the chlorination of cyclopentadiene in the production of chlordane.'
  //   },
  //   {
  //     code: 'K034',
  //     desc: 'Filter solids from the filtration of hexachlorocyclopentadiene in the production of chlordane.'
  //   },
  //   { code: 'K035', desc: 'Wastewater treatment sludges generated in the production of creosote.' },
  //   { code: 'K036', desc: 'Still bottoms from toluene reclamation distillation in the production of disulfoton.' },
  //   { code: 'K037', desc: 'Wastewater treatment sludges from the production of disulfoton.' },
  //   { code: 'K038', desc: 'Wastewater from the washing and stripping of phorate production.' },
  //   {
  //     code: 'K039',
  //     desc: 'Filter cake from the filtration of diethylphosphorodithioic acid in the production of phorate.'
  //   },
  //   { code: 'K040', desc: 'Wastewater treatment sludge from the production of phorate.' },
  //   { code: 'K041', desc: 'Wastewater treatment sludge from the production of toxaphene.' },
  //   {
  //     code: 'K042',
  //     desc:
  //       'Heavy ends or distillation residues from the distillation of tetrachlorobenzene in the production of 2,4,5-T.'
  //   },
  //   { code: 'K043', desc: '2,6-dichlorophenol waste from the production of 2,4-D.' },
  //   { code: 'K044', desc: 'Wastewater treatment sludges from the manufacturing and processing of explosives.' },
  //   { code: 'K045', desc: 'Spent carbon from the treatment of wastewater containing explosives.' },
  //   {
  //     code: 'K046',
  //     desc:
  //       'Wastewater treatment sludges from the manufacturing, formulation, and loading of lead-based initiating compounds.'
  //   },
  //   { code: 'K047', desc: 'Pink/red water from TNT operations.' },
  //   { code: 'K048', desc: 'Dissolved air flotation (DAF) float from the petroleum refining industry.' },
  //   { code: 'K049', desc: 'Slop oil emulsion solids from the petroleum refining industry.' },
  //   { code: 'K050', desc: 'Heat exchanger bundle cleaning sludge from the petroleum refining industry.' },
  //   { code: 'K051', desc: 'API separator sludge from the petroleum refining industry.' },
  //   { code: 'K052', desc: 'Tank bottoms (leaded) from the petroleum refining industry.' },
  //   { code: 'K060', desc: 'Ammonia still lime sludge from coking operations.' },
  //   { code: 'K061', desc: 'Emission control dust/sludge from the primary production of steel in electric furnaces.' },
  //   { code: 'K062', desc: 'Spent pickle liquor from steel finishing operations of plants that produce iron or steel.' },
  //   {
  //     code: 'K064',
  //     desc:
  //       'Acid plant blowdown slurry/sludge resulting from the thickening of blowdown slurry from primary copper production.'
  //   },
  //   {
  //     code: 'K065',
  //     desc:
  //       'Surface impoundment solids contained in and dredged from surface impoundments at primary lead smelting facilities.'
  //   },
  //   {
  //     code: 'K066',
  //     desc: 'Sludge from treatment of process wastewater and/or acid plant blowdown from primary zinc production.'
  //   },
  //   { code: 'K069', desc: 'Emission control dust/sludge from secondary lead smelting.' },
  //   {
  //     code: 'K071',
  //     desc:
  //       'Brine purification muds from the mercury cell process in chlorine production, in which separately prepurified brine is not used.'
  //   },
  //   {
  //     code: 'K073',
  //     desc:
  //       'Chlorinated hydrocarbon waste from the purification step of the diaphragm cell process using graphite anodes in chlorine production.'
  //   },
  //   { code: 'K083', desc: 'Distillation bottoms from aniline production.' },
  //   {
  //     code: 'K084',
  //     desc:
  //       'Wastewater treatment sludges generated during the production of veterinary pharmaceuticals from arsenic or organo- arsenic compounds.'
  //   },
  //   { code: 'K085', desc: 'Distillation or fractionation column bottoms from the production of chlorobenzenes.' },
  //   {
  //     code: 'K086',
  //     desc:
  //       'Solvent washes and sludges, caustic washes and sludges, or water washes and sludges from cleaning tubs and equipment used in the formulation of ink from pigments, driers, soaps, and stabilizers containing chromium and lead.'
  //   },
  //   { code: 'K087', desc: 'Decanter tank tar sludge from coking operations.' },
  //   { code: 'K088', desc: 'Spent potliners from primary aluminum reduction.' },
  //   { code: 'K090', desc: 'Emission control dust or sludge from ferrochromiumsilicon production.' },
  //   { code: 'K091', desc: 'Emission control dust or sludge from ferrochromium production.' },
  //   { code: 'K093', desc: 'Distillation light ends from the production of phthalic anhydride from ortho-xylene.' },
  //   { code: 'K094', desc: 'Distillation bottoms from the production of phthalic anhydride from ortho-xylene.' },
  //   { code: 'K095', desc: 'Distillation bottoms from the production of 1,1,1-trichloroethane.' },
  //   { code: 'K096', desc: 'Heavy ends from the heavy ends column from the production of 1,1,1-trichloroethane.' },
  //   { code: 'K097', desc: 'V acuum stripper discharge from the chlordane chlorinator in the production of chlordane.' },
  //   { code: 'K098', desc: 'Untreated process wastewater from the production of toxaphene.' },
  //   { code: 'K099', desc: 'Untreated wastewater from the production of 2,4-D.' },
  //   {
  //     code: 'K100',
  //     desc: 'Waste leaching solution from acid leaching of emission control dust/sludge from secondary lead smelting.'
  //   },
  //   {
  //     code: 'K101',
  //     desc:
  //       'Distillation tar residues from the distillation of aniline-based compounds in the production of veterinary pharmaceuticals from arsenic or organo-arsenic compounds.'
  //   },
  //   {
  //     code: 'K102',
  //     desc:
  //       'Residue from the use of activated carbon for decolorization in the production of veterinary pharmaceuticals from arsenic or organo-arsenic compounds.'
  //   },
  //   { code: 'K103', desc: 'Process residues from aniline extraction from the production of aniline.' },
  //   { code: 'K104', desc: 'Combined wastewaters generated from nitrobenzene/aniline production.' },
  //   {
  //     code: 'K105',
  //     desc: 'Separated aqueous stream from the reactor product washing step in the production of chlorobenzenes.'
  //   },
  //   { code: 'K106', desc: 'Wastewater treatment sludge from the mercury cell process in chlorine production.' },
  //   {
  //     code: 'K107',
  //     desc:
  //       'Column bottoms from product separation from the production of 1,1-dimethylhydrazine (UDMH) from carboxylic acid hydrazides.'
  //   },
  //   {
  //     code: 'K108',
  //     desc:
  //       'Condensed column overheads from product separation and condensed reactor vent gases from the production of 1,1- dimethylhydrazine from carboxylic acid hydrazides.'
  //   },
  //   {
  //     code: 'K109',
  //     desc:
  //       'Spent filter cartridges from product purification from the product of 1,1- dimethylhydrazine from carboxylic acid hydrazides.'
  //   },
  //   {
  //     code: 'K110',
  //     desc:
  //       'Condensed column overheads from intermediate separation from the production of 1,1-dimethylhydrazine from carboxylic acid hydrazides.'
  //   },
  //   { code: 'K111', desc: 'Product washwaters from the production of dinitrotoluene via nitration of toluene.' },
  //   {
  //     code: 'K112',
  //     desc:
  //       'Reaction by-product water from the drying column in the production of toluenediamine via hydrogenation of dinitrotoluene.'
  //   },
  //   {
  //     code: 'K113',
  //     desc:
  //       'Condensed liquid light ends from purification of toluenediamine in production of toluenediamine via hydrogenation of dinitrotoluene.'
  //   },
  //   {
  //     code: 'K114',
  //     desc:
  //       'Vicinals from the purification of toluenediamine in production of toluenediamine via hydrogenation of dinitrotoluene.'
  //   },
  //   {
  //     code: 'K115',
  //     desc:
  //       'Heavy ends from purification of toluenediamine in the production of toluenediamine via hydrogenation of dinitrotoluene.'
  //   },
  //   {
  //     code: 'K116',
  //     desc:
  //       'Organic condensate from the solvent recovery column in the production of toluene diisocyanate via phosgenation of toluenediamine.'
  //   },
  //   {
  //     code: 'K117',
  //     desc:
  //       'Wastewater from the reactor vent gas scrubber in the production of ethylene dibromide via bromination of ethene.'
  //   },
  //   {
  //     code: 'K118',
  //     desc:
  //       'Spent adsorbent solids from purification of ethylene dibromide in the production of ethylene dibromide via bromination of ethene.'
  //   },
  //   {
  //     code: 'K123',
  //     desc:
  //       'Process wastewater (including supernates, filtrates, and washwaters) from theproduction of ethylenebisdithiocarbamic acid and its salts. Hazardous Code T.'
  //   },
  //   {
  //     code: 'K124',
  //     desc:
  //       'Reactor vent scrubber water from the production of ethylenebisdithiocarbamic acid and its salts. Hazardous Code T.'
  //   },
  //   {
  //     code: 'K125',
  //     desc:
  //       'Filtration, evaporation, and centrifugation solids from the production of ethylenebisdithiocarbamic acid and its salts. Hazardous Code T and C.'
  //   },
  //   {
  //     code: 'K126',
  //     desc:
  //       'Baghouse dust and floor sweepings in milling and packaging operations from production or formulation of ethylenebisdithiocarbamic acid and its salts. Hazardous Code T.'
  //   },
  //   {
  //     code: 'K131',
  //     desc:
  //       'Wastewater from the reactor and spent sulfuric acid from the acid dryer from the production of methyl bromide.'
  //   },
  //   { code: 'K132', desc: 'Spent absorbent and wastewater separator solids from the production of methyl bromide.' },
  //   {
  //     code: 'K136',
  //     desc:
  //       'Still bottoms from the purification of ethylene dibromide in the production of ethylene dibromide via bromination of ethene.'
  //   },
  //   {
  //     code: 'K141',
  //     desc:
  //       'Process residues from the recovery of coal tar, including, but not limited to, tar collecting sump residues from the production of coke from coal or the recovery of coke by-products produced from coal. This listing does not include K087 (decanter tank sludge from coking operations).'
  //   },
  //   {
  //     code: 'K142',
  //     desc:
  //       'Tank storage residues from the production of coke from coal or from the recovery of coke by-products from coal.'
  //   },
  //   {
  //     code: 'K143',
  //     desc:
  //       'Process residues from the recovery of light oil, including, but not limited to, those generated in stills, decanters, and wash oil recovery units from the recovery of coke by-products produced from coal.'
  //   },
  //   {
  //     code: 'K144',
  //     desc:
  //       'Wastewater sump residues from light oil refining, including, but not limited to, intercepting or contamination sump sludges from the recovery of coke by-products produced from coal.'
  //   },
  //   {
  //     code: 'K145',
  //     desc:
  //       'Residues from naphthalene collection and recovery operations from the recovery of coke by-products produced from coal.'
  //   },
  //   { code: 'K147', desc: 'Tar storage residues from coal tar refining.' },
  //   { code: 'K148', desc: 'Residues from coal tar distillation, including, but not limited to, still bottoms.' },
  //   {
  //     code: 'K149',
  //     desc:
  //       'Distillation bottoms from the production of alpha (or methyl-) chlorinated tolunes, ring-chlorinated tolunes, benzol chlorides, and compounds with mixtures of these functional groups. [This waste does not include still bottoms from the distillation of benzol chloride]'
  //   },
  //   {
  //     code: 'K150',
  //     desc:
  //       'Organic residules excluding spent carbon adsorbent, from the spent chlorine gas and hydrochloric acid recovery processes associated with the production of alpha (or methyl-) chlorinated tolunes, benzol chlorides, and compounds with mixtures of these functional groups.'
  //   },
  //   {
  //     code: 'K151',
  //     desc:
  //       'Wastewater treatment sludges, excluding neutralization and biological sludges, generated during the treatment of wastewaters from the production of alpha (or methyl-) chlorinated tolunes, benzol chlorides, and compounds with mixtures of these functional groups.'
  //   }
  // ];

  p_codes = [
    {
      code: 'P01',
      desc:
        '2H-1-Benzopyran-2-one, 4-hydroxy-3-(3- oxo-1-phenylbutyl)-, & salts, when present at concentrations greater than 0.3%'
    },
    { code: 'P001', desc: 'Warfarin, & salts, when present at concentrations greater than 0.3%' },
    { code: 'P002', desc: '1-Acetyl-2-thiourea' },
    { code: 'P002', desc: 'Acetamide, N-(aminothioxomethyl)-' },
    { code: 'P003', desc: '2-Propenal' },
    { code: 'P003', desc: 'Acrolein' },
    {
      code: 'P004',
      desc:
        '1,4,5,8-Dimethanonaphthalene, 1,2,3,4,10,10-hexa-chloro-1,4,4a,5,8,8a,- hexahydro-, (1alpha, 4alpha, 4abeta, 5alpha, 8alpha, 8abeta)-'
    },
    { code: 'P004', desc: 'Aldrin' },
    { code: 'P005', desc: '2-Propen-1-ol' },
    { code: 'P005', desc: 'Allyl alcohol' },
    { code: 'P006', desc: 'Aluminum phosphide (R,T)' },
    { code: 'P007', desc: '3(2H)-Isoxazolone, 5-(aminomethyl)-' },
    { code: 'P007', desc: '5-(Aminomethyl)-3-isoxazolol' },
    { code: 'P008', desc: '4-Aminopyridine' },
    { code: 'P008', desc: '4-Pyridinamine' },
    { code: 'P009', desc: 'Ammonium picrate (R)' },
    { code: 'P009', desc: 'Phenol, 2,4,6-trinitro-, ammonium salt (R)' },
    { code: 'P010', desc: 'Arsenic acid H3 AsO4' },
    { code: 'P011', desc: 'Arsenic oxide As2O5' },
    { code: 'P011', desc: 'Arsenic pentoxide' },
    { code: 'P012', desc: 'Arsenic oxide As2O3' },
    { code: 'P012', desc: 'Arsenic trioxide' },
    { code: 'P013', desc: 'Barium cyanide' },
    { code: 'P014', desc: 'Benzenethiol' },
    { code: 'P014', desc: 'Thiophenol' },
    { code: 'P015', desc: 'Beryllium' },
    { code: 'P016', desc: 'Dichloromethyl ether' },
    { code: 'P016', desc: 'Methane, oxybis[chloro-' },
    { code: 'P017', desc: '2-Propanone, 1-bromo-' },
    { code: 'P017', desc: 'Bromoacetone' },
    { code: 'P018', desc: 'Brucine' },
    { code: 'P018', desc: 'Strychnidin-10-one, 2,3-dimethoxy- P020 Dinoseb' },
    { code: 'P020', desc: 'Phenol, 2-(1-methylpropyl)-4,6-dinitro-' },
    { code: 'P021', desc: 'Calcium cyanide' },
    { code: 'P021', desc: 'Calcium cyanide Ca(CN)2' },
    { code: 'P022', desc: 'Carbon disulfide' },
    { code: 'P023', desc: 'Acetaldehyde, chloro-' },
    { code: 'P023', desc: 'Chloroacetaldehyde' },
    { code: 'P024', desc: 'Benzenamine, 4-chloro-' },
    { code: 'P024', desc: 'p-Chloraniline' },
    { code: 'P026', desc: '1-(o-Chlorophenyl)thiourea' },
    { code: 'P026', desc: 'Thiourea, (2-chlorophenyl)-' },
    { code: 'P027', desc: '3-Chloropropionitrile' },
    { code: 'P027', desc: 'Propanenitrile, 3-chloro-' },
    { code: 'P028', desc: 'Benzene, (chloromethyl)-' },
    { code: 'P028', desc: 'Benzyl chloride' },
    { code: 'P029', desc: 'Copper cyanide' },
    { code: 'P029', desc: 'Copper cyanide Cu(CN)' },
    { code: 'P030', desc: 'Cyanides (soluble cyanide salts), not otherwise specified' },
    { code: 'P031', desc: 'Cyanogen' },
    { code: 'P031', desc: 'Ethanedinitrile P033 Cyanogen chloride' },
    { code: 'P033', desc: 'Cyanogen chloride (CN)Cl' },
    { code: 'P034', desc: '2-Cyclohexyl-4,6-dinitrophenol' },
    { code: 'P034', desc: 'Phenol, 2-cyclohexyl-4,6-dinitro- P036 Arsonous dichloride, phenyl-' },
    { code: 'P036', desc: 'Dichlorophenylarsine' },
    {
      code: 'P037',
      desc:
        '2,7:3,6-Dimethanonaphth[2,3-b]oxirene, 3,4,5,6,9,9-hexachloro-1a,2,2a,3,6,6a,7,7a- octahydro-, (1aalpha, 2beta, 2aalpha, 3beta, 6beta, 6aalpha, 7beta, 7aalpha)-'
    },
    { code: 'P037', desc: 'Dieldrin' },
    { code: 'P038', desc: 'Arsine, diethyl-' },
    { code: 'P038', desc: 'Diethylarsine' },
    { code: 'P039', desc: 'Disulfoton' },
    { code: 'P039', desc: 'Phosphorodithioic acid, O,O-diethyl S-[2- (ethylthio)ethyl] ester' },
    { code: 'P040', desc: 'O,O-Diethyl O-pyrazinyl phosphorothioate' },
    { code: 'P040', desc: 'Phosphorothioic acid, O,O-diethyl O- pyrazinyl ester' },
    { code: 'P041', desc: 'Diethyl-p-nitrophenyl phosphate' },
    { code: 'P041', desc: 'Phosphoric acid, diethyl 4-nitrophenyl ester' },
    { code: 'P042', desc: '1,2-Benzenediol, 4-[1-hydroxy-2- (methylamino)ethyl]-, (R)-' },
    { code: 'P042', desc: 'Epinephrine' },
    { code: 'P043', desc: 'Diisopropylfluorophosphate (DFP)' },
    { code: 'P043', desc: 'Phosphorofluoridic acid, bis(1-methylethyl) ester' },
    { code: 'P044', desc: 'Dimethoate' },
    { code: 'P044', desc: 'Phosphorodithioic acid, O,O-dimethyl S-[2- (methylamino)-2-oxoethyl] ester' },
    { code: 'P045', desc: '2-Butanone, 3,3-dimethyl-1-(methylthio)-, O-[methylamino)carbonyl] oxime' },
    { code: 'P045', desc: 'Thiofanox' },
    { code: 'P046', desc: 'alpha,alpha-Dimethylphenethylamine' },
    { code: 'P046', desc: 'Benzeneethanamine, alpha, alpha-dimethyl-' },
    { code: 'P047', desc: '4,6-Dinitro-o-cresol, & salts' },
    { code: 'P047', desc: 'Phenol, 2-methyl-4,6-dinitro-, & salts' },
    { code: 'P048', desc: '2,4-Dinitrophenol' },
    { code: 'P048', desc: 'Phenol, 2,4-dinitro-' },
    { code: 'P049', desc: 'Dithiobiuret' },
    { code: 'P049', desc: 'Thioimidodicarbonic diamide [(H2N)C(S)]2NH' },
    {
      code: 'P050',
      desc: '6,9-Methano-2,4,3- benzodioxathiepin,6,7,8,9,10,10-hexachloro- 1,5,5a,6,9,9a-hexahydro-,3-oxide'
    },
    { code: 'P050', desc: 'Endosulfan' },
    {
      code: 'P051',
      desc:
        '2,7:3,6-Dimethanonaphth[2,3-b]oxirene, 3,4,5,6,9,9-hexachloro-1a,2,2a,3,6,6a,7,7a- octahydro-, (1aalpha, 2beta, 2abeta, 3alpha, 6alpha, 6abeta, 7beta, 7aalpha)- & metabolites'
    },
    { code: 'P051', desc: 'Endrin' },
    { code: 'P051', desc: 'Endrin, & metabolites P054 Aziridine' },
    { code: 'P054', desc: 'Ethyleneimine' },
    { code: 'P056', desc: 'Fluorine' },
    { code: 'P057', desc: 'Acetamide, 2-fluoro-' },
    { code: 'P057', desc: 'Fluoroacetamide' },
    { code: 'P058', desc: 'Acetic acid, fluoro-, sodium salt' },
    { code: 'P058', desc: 'Fluoroacetic acid, sodium salt' },
    { code: 'P059', desc: '4,7-Methano-1H-indene, 1,4,5,6,7,8,8- heptachloro-3a,4,7,7a-tetrahydro-' },
    { code: 'P059', desc: 'Heptachlor' },
    {
      code: 'P060',
      desc:
        '1,4,5,8-Dimethanonaphthalene, 1,2,3,4,10,10-hexa-chloro-1,4,4a,5,8,8a,- hexahydro-, (1alpha, 4alpha, 4abeta, 5beta, 8beta, 8abeta)-'
    },
    { code: 'P060', desc: 'Isodrin' },
    { code: 'P062', desc: 'Hexaethyl tetraphosphate' },
    { code: 'P062', desc: 'Tetraphosphoric acid, hexaethyl ester' },
    { code: 'P063', desc: 'Hydrocyanic acid' },
    { code: 'P063', desc: 'Hydrogen cyanide' },
    { code: 'P064', desc: 'Methane, isocyanato-' },
    { code: 'P064', desc: 'Methyl isocyanate' },
    { code: 'P065', desc: 'Fulminic acid, mercury(2+) salt (R,T)' },
    { code: 'P065', desc: 'Mercury fulminate (R,T)' },
    { code: 'P066', desc: 'Ethanimidothioic acid, N- [[(methylamino)carbonyl]oxy]-, methyl ester' },
    { code: 'P066', desc: 'Methomyl' },
    { code: 'P067', desc: '1,2-Propylenimine' },
    { code: 'P067', desc: 'Aziridine, 2-methyl-' },
    { code: 'P068', desc: 'Hydrazine, methyl-' },
    { code: 'P068', desc: 'Methyl hydrazine' },
    { code: 'P069', desc: '2-Methyllactonitrile' },
    { code: 'P069', desc: 'Propanenitrile, 2-hydroxy-2-methyl-' },
    { code: 'P070', desc: 'Aldicarb' },
    { code: 'P070', desc: 'Propanal, 2-methyl-2-(methylthio)-, O- [(methylamino)carbonyl]oxime' },
    { code: 'P071', desc: 'Methyl parathion' },
    { code: 'P071', desc: 'Phosphorothioic acid, O,O,-dimethyl O-(4- nitrophenyl) ester' },
    { code: 'P072', desc: 'alpha-Naphthylthiourea' },
    { code: 'P072', desc: 'Thiourea, 1-naphthalenyl-' },
    { code: 'P073', desc: 'Nickel carbonyl' },
    { code: 'P073', desc: 'Nickel carbonyl Ni(CO)4, (T-4)-' },
    { code: 'P074', desc: 'Nickel cyanide' },
    { code: 'P074', desc: 'Nickel cyanide Ni(CN)2' },
    { code: 'P075', desc: 'Nicotine, & salts' },
    { code: 'P075', desc: 'Pyridine, 3-(1-methyl-2-pyrrolidinyl)-,(S)-, & salts' },
    { code: 'P076', desc: 'Nitric oxide' },
    { code: 'P076', desc: 'Nitrogen oxide NO' },
    { code: 'P077', desc: 'Benzenamine, 4-nitro-' },
    { code: 'P077', desc: 'p-Nitroaniline' },
    { code: 'P078', desc: 'Nitrogen dioxide' },
    { code: 'P078', desc: 'Nitrogen oxide NO2' },
    { code: 'P081', desc: '1,2,3-Propanetriol, trinitrate (R)' },
    { code: 'P081', desc: 'Nitroglycerine (R)' },
    { code: 'P082', desc: 'Methanimine, N-methyl-N-nitroso-' },
    { code: 'P082', desc: 'N-Nitrosodimethylamine' },
    { code: 'P084', desc: 'N-Nitrosomethylvinylamine' },
    { code: 'P084', desc: 'Vinylamine, N-methyl-N-nitroso-' },
    { code: 'P085', desc: 'Diphosphoramide, octamethyl-' },
    { code: 'P085', desc: 'Octamethylpyrophosphoramide' },
    { code: 'P087', desc: 'Osmium oxide OsO4, (T-4)-' },
    { code: 'P087', desc: 'Osmium tetroxide' },
    { code: 'P088', desc: '7-Oxabicyclo[2.2.1]heptane-2,3- dicarboxylic acid' },
    { code: 'P088', desc: 'Endothall' },
    { code: 'P089', desc: 'Parathion' },
    { code: 'P089', desc: 'Phosphorothioic acid, O,O-diethyl-O-(4- nitrophenyl) ester' },
    { code: 'P092', desc: 'Mercury, (acetato-O)phenyl-' },
    { code: 'P092', desc: 'Phenylmercury acetate' },
    { code: 'P093', desc: 'Phenylthiourea' },
    { code: 'P093', desc: 'Thiourea, phenyl-' },
    { code: 'P094', desc: 'Phorate' },
    { code: 'P094', desc: 'Phosphorodithioic acid, O,O-diethyl S- [(ethylthio)methyl] ester' },
    { code: 'P095', desc: 'Carbonic dichloride' },
    { code: 'P095', desc: 'Phosgene' },
    { code: 'P096', desc: 'Hydrogen phosphide' },
    { code: 'P096', desc: 'Phosphine' },
    { code: 'P097', desc: 'Famphur' },
    { code: 'P097', desc: 'Phosphorothioic acid O-[4- [(dimethylamino)sulfonyl]phenyl] O,O-dimethyl ester' },
    { code: 'P098', desc: 'Potassium cyanide' },
    { code: 'P098', desc: 'Potassium cyanide K(CN)' },
    { code: 'P099', desc: 'Argentate (1-), bis(cyano-C)-, potassium' },
    { code: 'P099', desc: 'Potassium silver cyanide P101 Ethyl cyanide' },
    { code: 'P101', desc: 'Propanenitrile' },
    { code: 'P102', desc: '2-Propyn-1-ol' },
    { code: 'P102', desc: 'Propargyl alcohol' },
    { code: 'P103', desc: 'Selenourea' },
    { code: 'P104', desc: 'Silver cyanide' },
    { code: 'P104', desc: 'Silver cyanide Ag(CN)' },
    { code: 'P105', desc: 'Sodium azide' },
    { code: 'P106', desc: 'Sodium cyanide' },
    { code: 'P106', desc: 'Sodium cyanide Na(CN)' },
    { code: 'P107', desc: 'Strontium sulfide SrS' },
    { code: 'P108', desc: 'Strychnidin-10-one, & salts' },
    { code: 'P108', desc: 'Strychnine, & salts' },
    { code: 'P109', desc: 'Tetraethyldithiopyrophosphate' },
    { code: 'P109', desc: 'Thiodiphosphoric acid, tetraethyl ester' },
    { code: 'P110', desc: 'Plumbane, tetraethyl-' },
    { code: 'P110', desc: 'Tetraethyl lead' },
    { code: 'P111', desc: 'Diphosphoric acid, tetraethyl ester' },
    { code: 'P111', desc: 'Tetraethyl pyrophosphate' },
    { code: 'P112', desc: 'Methane, tetranitro- (R)' },
    { code: 'P112', desc: 'Tetranitromethane (R)' },
    { code: 'P113', desc: 'Thallic oxide' },
    { code: 'P113', desc: 'Thallium oxide Tl2O3' },
    { code: 'P114', desc: 'Selenious acid, dithallium (1+) salt' },
    { code: 'P114', desc: 'Thallium(I) selenite' },
    { code: 'P115', desc: 'Sulfuric acid, dithallium (1+) salt' },
    { code: 'P115', desc: 'Thallium(I) sulfate' },
    { code: 'P116', desc: 'Hydrazinecarbothioamide' },
    { code: 'P116', desc: 'Thiosemicarbazide' },
    { code: 'P118', desc: 'Methanethiol, trichloro-' },
    { code: 'P118', desc: 'Trichloromethanethiol' },
    { code: 'P119', desc: 'Ammonium vanadate' },
    { code: 'P119', desc: 'V anadic acid, ammonium salt' },
    { code: 'P120', desc: 'Vanadium oxide V2O5' },
    { code: 'P120', desc: 'V anadium pentoxide' },
    { code: 'P121', desc: 'Zinc cyanide' },
    { code: 'P121', desc: 'Zinc cyanide Zn(CN)2' },
    { code: 'P122', desc: 'Zinc phosphide Zn3P2, when present at concentrations greater than 10% (R,T)' },
    { code: 'P123', desc: 'Toxaphene' },
    { code: "P127",	desc: "7-Benzofuranol, 2,3-dihydro-2,2-dimethyl-, methylcarbamate."},
    { code: "P127",	desc: "Carbofuran."},
    { code: "P128",	desc: "Mexacarbate."},
    { code: "P128",	desc: "Phenol, 4-(dimethylamino)-3,5-dimethyl-, methylcarbamate (ester)."},
    { code: "P185",	desc: "1,3-Dithiolane-2-carboxaldehyde, 2,4-dimethyl-, O- [(methylamino)- carbonyl]oxime."},
    { code: "P185",	desc: "Tirpate."},
    { code: "P188",	desc: "Benzoic acid, 2-hydroxy-, compd. with (3aS-cis)-1,2,3,3a,8,8a-hexahydro-1,3a,8-trimethylpyrrolo[2,3-b]indol-5-yl methylcarbamate ester (1:1)."},
    { code: "P188",	desc: "Physostigmine salicylate."},
    { code: "P189",	desc: "Carbamic acid, [(dibutylamino)- thio]methyl-, 2,3-dihydro-2,2-dimethyl- 7-benzofuranyl ester."},
    { code: "P189",	desc: "Carbosulfan."},
    { code: "P190",	desc: "Carbamic acid, methyl-, 3-methylphenyl ester."},
    { code: "P190",	desc: "Metolcarb."},
    { code: "P191",	desc: "Carbamic acid, dimethyl-, 1-[(dimethyl-amino)carbonyl]- 5-methyl-1H- pyrazol-3-yl ester."},
    { code: "P191",	desc: "Dimetilan."},
    { code: "P192",	desc: "Carbamic acid, dimethyl-, 3-methyl-1- (1-methylethyl)-1H- pyrazol-5-yl ester."},
    { code: "P192",	desc: "Isolan."},
    { code: "P194",	desc: "Ethanimidothioic acid, 2-(dimethylamino)-N-[[(methylamino) carbonyl]oxy]-2-oxo-, methyl ester."},
    { code: "P194",	desc: "Oxamyl."},
    { code: "P196",	desc: "Manganese, bis(dimethylcarbamodithioato-S,S′)-,"},
    { code: "P196",	desc: "Manganese dimethyldithiocarbamate."},
    { code: "P197",	desc: "Formparanate."},
    { code: "P197",	desc: "Methanimidamide, N,N-dimethyl-N′-[2-methyl-4-[[(methylamino)carbonyl]oxy]phenyl]-"},
    { code: "P198",	desc: "Formetanate hydrochloride."},
    { code: "P198",	desc: "Methanimidamide, N,N-dimethyl-N′-[3-[[(methylamino)-carbonyl]oxy]phenyl]-, monohydrochloride."},
    { code: "P199",	desc: "Methiocarb."},
    { code: "P199",	desc: "Phenol, (3,5-dimethyl-4-(methylthio)-, methylcarbamate"},
    { code: "P201",	desc: "Phenol, 3-methyl-5-(1-methylethyl)-, methyl carbamate."},
    { code: "P201",	desc: "Promecarb"},
    { code: "P202",	desc: "m-Cumenyl methylcarbamate."},
    { code: "P202",	desc: "3-Isopropylphenyl N-methylcarbamate."},
    { code: "P202",	desc: "Phenol, 3-(1-methylethyl)-, methyl carbamate."},
    { code: "P203",	desc: "Aldicarb sulfone."},
    { code: "P203",	desc: "Propanal, 2-methyl-2-(methyl-sulfonyl)-, O-[(methylamino)carbonyl] oxime."},
    { code: "P204",	desc: "Physostigmine."},
    { code: "P204",	desc: "Pyrrolo[2,3-b]indol-5-ol, 1,2,3,3a,8,8a-hexahydro-1,3a,8-trimethyl-,  methylcarbamate (ester), (3aS-cis)-."},
    { code: "P205",	desc: "Zinc, bis(dimethylcarbamodithioato-S,S′)-,"},
    { code: "P205",	desc: "Ziram."},
  ];

  u_codes = [
    { code: 'U001', desc: 'Acetaldehyde (I)' },
    { code: 'U001', desc: 'Ethanal (I)' },
    { code: 'U002', desc: '2-Propanone (I)' },
    { code: 'U002', desc: 'Acetone (I)' },
    { code: 'U003', desc: 'Acetonitrile (I,T)' },
    { code: 'U004', desc: 'Acetophenone' },
    { code: 'U004', desc: 'Ethanone, 1-phenyl-' },
    { code: 'U005', desc: '2-Acetylaminofluorene' },
    { code: 'U005', desc: 'Acetamide, N-9H-fluoren-2-yl' },
    { code: 'U006', desc: 'Acetyl chloride (C,R,T)' },
    { code: 'U007', desc: '2-Propenamide' },
    { code: 'U007', desc: 'Acrylamide' },
    { code: 'U008', desc: '2-Propenoic acid (I)' },
    { code: 'U008', desc: 'Acrylic acid (I)' },
    { code: 'U009', desc: '2-Propenenitrile' },
    { code: 'U009', desc: 'Acrylonitrile' },
    {
      code: 'U010',
      desc:
        "Azirino [2',3':3,4]pyrrolo[1,2-a]indole-4,7- dione, 6-amino-8-[[(aminocarbonyl)oxy]methyl]- 1,1a,2,8,8a,8b-hexahydro-8a-methoxy-5- methyl-, [1aS-(1aalpha, 8beta, 8aalpha, 8balpha)]-"
    },
    { code: 'U010', desc: 'Mitomycin C' },
    { code: 'U011', desc: '1H-1,2,4-Triazol-3-amine' },
    { code: 'U011', desc: 'Amitrole' },
    { code: 'U012', desc: 'Aniline (I,T)' },
    { code: 'U012', desc: 'Benzenamine (I,T)' },
    { code: 'U014', desc: 'Auramine' },
    { code: 'U014', desc: "Benzenamine, 4,4'-carbonimidoylbis[N,N- dimethyl-" },
    { code: 'U015', desc: 'Azaserine' },
    { code: 'U015', desc: 'L-Serine, diazoacetate (ester)' },
    { code: 'U016', desc: 'Benz[c]acridine' },
    { code: 'U017', desc: 'Benzal chloride' },
    { code: 'U017', desc: 'Benzene, (dichloromethyl)-' },
    { code: 'U018', desc: 'Benz[a]anthracene' },
    { code: 'U019', desc: 'Benzene (I,T)' },
    { code: 'U020', desc: 'Benzenesulfonic acid chloride (C,R)' },
    { code: 'U020', desc: 'Benzenesulfonyl chloride (C,R)' },
    { code: 'U021', desc: "[1,1'-Biphenyl]-4,4'-diamine" },
    { code: 'U021', desc: 'Benzidine' },
    { code: 'U022', desc: 'Benzo[a]pyrene' },
    { code: 'U023', desc: 'Benzene, (trichloromethyl)-' },
    { code: 'U023', desc: 'Benzotrichloride (C,R,T)' },
    { code: 'U024', desc: 'Dichloromethoxy ethane' },
    { code: 'U024', desc: "Ethane, 1,1'-[methylenebis(oxy)]bis[2- chloro-" },
    { code: 'U025', desc: 'Dichloroethyl ether' },
    { code: 'U025', desc: "Ethane, 1,1'-oxybis[2-chloro-" },
    { code: 'U026', desc: 'Chlornaphazin' },
    { code: 'U026', desc: "Naphthalenamine, N,N'-bis(2-chloroethyl)-" },
    { code: 'U027', desc: 'Dichloroisopropyl ether' },
    { code: 'U027', desc: "Propane, 2,2'-oxybis[2-chloro-" },
    { code: 'U028', desc: '1,2-Benzenedicarboxylic acid, bis(2- ethylhexyl) ester' },
    { code: 'U028', desc: 'Diethylhexyl phthalate' },
    { code: 'U029', desc: 'Methane, bromo-' },
    { code: 'U029', desc: 'Methyl bromide' },
    { code: 'U030', desc: '4-Bromophenyl phenyl ether' },
    { code: 'U030', desc: 'Benzene, 1-bromo-4-phenoxy-' },
    { code: 'U031', desc: '1-Butanol (I)' },
    { code: 'U031', desc: 'n-Butyl alcohol (I)' },
    { code: 'U032', desc: 'Calcium chromate' },
    { code: 'U032', desc: 'Chromic acid H2CrO4, calcium salt' },
    { code: 'U033', desc: 'Carbon oxyfluoride (R,T)' },
    { code: 'U033', desc: 'Carbonic difluoride' },
    { code: 'U034', desc: 'Acetaldehyde, trichloro-' },
    { code: 'U034', desc: 'Chloral' },
    { code: 'U035', desc: 'Benzenebutanoic acid, 4-[bis(2- chloroethyl)amino]-' },
    { code: 'U035', desc: 'Chlorambucil' },
    { code: 'U036', desc: '4,7-Methano-1H-indene, 1,2,4,5,6,7,8,8- octachloro-2,3,3a,4,7,7a-hexahydro-' },
    { code: 'U036', desc: 'Chlordane, alpha & gamma isomers' },
    { code: 'U037', desc: 'Benzene, chloro-' },
    { code: 'U037', desc: 'Chlorobenzene' },
    { code: 'U038', desc: 'Benzeneacetic acid, 4-chloro-alpha-(4- chlorophenyl)-alpha-hydroxy-, ethyl ester' },
    { code: 'U038', desc: 'Chlorobenzilate' },
    { code: 'U039', desc: 'p-Chloro-m-cresol' },
    { code: 'U039', desc: 'Phenol, 4-chloro-3-methyl- U041 Epichlorohydrin' },
    { code: 'U041', desc: 'Oxirane, (chloromethyl)-' },
    { code: 'U042', desc: '2-Chloroethyl vinyl ether' },
    { code: 'U042', desc: 'Ethene, (2-chloroethoxy)-' },
    { code: 'U043', desc: 'Ethene, chloro-' },
    { code: 'U043', desc: 'Vinyl chloride' },
    { code: 'U044', desc: 'Chloroform' },
    { code: 'U044', desc: 'Methane, trichloro-' },
    { code: 'U045', desc: 'Methane, chloro- (I,T)' },
    { code: 'U045', desc: 'Methyl chloride (I,T)' },
    { code: 'U046', desc: 'Chloromethyl methyl ether' },
    { code: 'U046', desc: 'Methane, chloromethoxy-' },
    { code: 'U047', desc: 'beta-Chloronaphthalene' },
    { code: 'U047', desc: 'Naphthalene, 2-chloro-' },
    { code: 'U048', desc: 'o-Chlorophenol' },
    { code: 'U048', desc: 'Phenol, 2-chloro-' },
    { code: 'U049', desc: '4-Chloro-o-toluidine, hydrochloride' },
    { code: 'U049', desc: 'Benzenamine, 4-chloro-2-methyl-, hydrochloride' },
    { code: 'U050', desc: 'Chrysene' },
    { code: 'U051', desc: 'Creosote' },
    { code: 'U052', desc: 'Cresol (Cresylic acid)' },
    { code: 'U052', desc: 'Phenol, methyl-' },
    { code: 'U053', desc: '2-Butenal' },
    { code: 'U053', desc: 'Crotonaldehyde' },
    { code: 'U055', desc: 'Benzene, (1-methylethyl)- (I)' },
    { code: 'U055', desc: 'Cumene (I)' },
    { code: 'U056', desc: 'Benzene, hexahydro- (I)' },
    { code: 'U056', desc: 'Cyclohexane (I)' },
    { code: 'U057', desc: 'Cyclohexanone (I)' },
    { code: 'U058', desc: '2H-1,3,2-Oxazaphosphorin-2-amine, N,N- bis(2-chloroethyl)tetrahydro-, 2-oxide' },
    { code: 'U058', desc: 'Cyclophosphamide' },
    {
      code: 'U059',
      desc:
        '5,12-Naphthacenedione, 8-acetyl-10-[(3- amino-2,3,6-trideoxy)-alpha-L-lyxo- hexopyranosyl)oxy]-7,8,9,10-tetrahydro- 6,8,11-trihydroxy-1-methoxy-, (8S-cis)-'
    },
    { code: 'U059', desc: 'Daunomycin' },
    { code: 'U060', desc: "Benzene, 1,1'-(2,2-dichloroethylidene)bis[4- chloro-" },
    { code: 'U060', desc: 'DDD' },
    { code: 'U061', desc: "Benzene, 1,1'-(2,2,2- trichloroethylidene)bis[4-chloro-" },
    { code: 'U061', desc: 'DDT' },
    { code: 'U062', desc: 'Carbamothioic acid, bis(1-methylethyl)-, S- (2,3-dichloro-2-propenyl) ester' },
    { code: 'U062', desc: 'Diallate' },
    { code: 'U063', desc: 'Dibenz[a,h]anthracene' },
    { code: 'U064', desc: 'Benzo[rst]pentaphene' },
    { code: 'U064', desc: 'Dibenzo[a,i]pyrene' },
    { code: 'U066', desc: '1,2-Dibromo-3-chloropropane' },
    { code: 'U066', desc: 'Propane, 1,2-dibromo-3-chloro-' },
    { code: 'U067', desc: 'Ethane, 1,2-dibromo-' },
    { code: 'U067', desc: 'Ethylene dibromide' },
    { code: 'U068', desc: 'Methane, dibromo-' },
    { code: 'U068', desc: 'Methylene bromide' },
    { code: 'U069', desc: '1,2-Benzenedicarboxylic acid, dibutyl ester' },
    { code: 'U069', desc: 'Dibutyl phthalate' },
    { code: 'U070', desc: 'Benzene, 1,2-dichloro-' },
    { code: 'U070', desc: 'o-Dichlorobenzene' },
    { code: 'U071', desc: 'Benzene, 1,3-dichloro-' },
    { code: 'U071', desc: 'm-Dichlorobenzene' },
    { code: 'U072', desc: 'Benzene, 1,4-dichloro-' },
    { code: 'U072', desc: 'p-Dichlorobenzene' },
    { code: 'U073', desc: "[1,1'-Biphenyl]-4,4'-diamine, 3,3'-dichloro-" },
    { code: 'U073', desc: "3,3'-Dichlorobenzidine" },
    { code: 'U074', desc: '1,4-Dichloro-2-butene (I,T)' },
    { code: 'U074', desc: '2-Butene, 1,4-dichloro- (I,T)' },
    { code: 'U075', desc: 'Dichlorodifluoromethane' },
    { code: 'U075', desc: 'Methane, dichlorodifluoro-' },
    { code: 'U076', desc: 'Ethane, 1,1-dichloro-' },
    { code: 'U076', desc: 'Ethylidene dichloride' },
    { code: 'U077', desc: 'Ethane, 1,2-dichloro-' },
    { code: 'U077', desc: 'Ethylene dichloride' },
    { code: 'U078', desc: '1,1-Dichloroethylene' },
    { code: 'U078', desc: 'Ethene, 1,1-dichloro-' },
    { code: 'U079', desc: '1,2-Dichloroethylene' },
    { code: 'U079', desc: 'Ethene, 1,2-dichloro-,(E)-' },
    { code: 'U080', desc: 'Methane, dichloro-' },
    { code: 'U080', desc: 'Methylene chloride' },
    { code: 'U081', desc: '2,4-Dichlorophenol' },
    { code: 'U081', desc: 'Phenol, 2,4-dichloro-' },
    { code: 'U082', desc: '2,6-Dichlorophenol' },
    { code: 'U082', desc: 'Phenol, 2,6-dichloro-' },
    { code: 'U083', desc: 'Propane, 1,2-dichloro-' },
    { code: 'U083', desc: 'Propylene dichloride' },
    { code: 'U084', desc: '1,3-Dichloropropene' },
    { code: 'U084', desc: '1-Propene, 1,3-dichloro-' },
    { code: 'U085', desc: '1,2:3,4-Diepoxybutane (I,T)' },
    { code: 'U085', desc: "2,2'-Bioxirane" },
    { code: 'U086', desc: 'Hydrazine, 1,2-diethyl-' },
    { code: 'U086', desc: "N,N'-Diethylhydrazine" },
    { code: 'U087', desc: 'O,O-Diethyl S-methyl dithiophosphate' },
    { code: 'U087', desc: 'Phosphorodithioic acid, O,O-diethyl S- methyl ester' },
    { code: 'U088', desc: '1,2-Benzenedicarboxylic acid, diethyl ester' },
    { code: 'U088', desc: 'Diethyl phthalate' },
    { code: 'U089', desc: 'Diethylstilbesterol' },
    { code: 'U089', desc: "Phenol, 4,4'-(1,2-diethyl-1,2-ethenediyl)bis, (E)-" },
    { code: 'U090', desc: '1,3-Benzodioxole, 5-propyl-' },
    { code: 'U090', desc: 'Dihydrosafrole' },
    { code: 'U091', desc: "[1,1'-Biphenyl]-4,4'-diamine, 3,3'- dimethoxy-" },
    { code: 'U091', desc: "3,3'-Dimethoxybenzidine" },
    { code: 'U092', desc: 'Dimethylamine (I)' },
    { code: 'U092', desc: 'Methanamine, N-methyl- (I)' },
    { code: 'U093', desc: 'Benzenamine, N,N-dimethyl-4-(phenylazo)-' },
    { code: 'U093', desc: 'p-Dimethylaminoazobenzene' },
    { code: 'U094', desc: '7,12-Dimethylbenz[a]anthracene' },
    { code: 'U094', desc: 'Benz[a]anthracene, 7,12-dimethyl-' },
    { code: 'U095', desc: "[1,1'-Biphenyl]-4,4'-diamine, 3,3'-dimethyl-" },
    { code: 'U095', desc: "3,3'-Dimethylbenzidine" },
    { code: 'U096', desc: 'alpha,alpha-Dimethylbenzylhydroperoxide (R)' },
    { code: 'U096', desc: 'Hydroperoxide, 1-methyl-1-phenylethyl- (R)' },
    { code: 'U097', desc: 'Carbamic chloride, dimethyl-' },
    { code: 'U097', desc: 'Dimethylcarbamoyl chloride' },
    { code: 'U098', desc: '1,1-Dimethylhydrazine' },
    { code: 'U098', desc: 'Hydrazine, 1,1-dimethyl-' },
    { code: 'U099', desc: '1,2-Dimethylhydrazine' },
    { code: 'U099', desc: 'Hydrazine, 1,2-diphenyl-' },
    { code: 'U101', desc: '2,4-Dimethylphenol' },
    { code: 'U101', desc: 'Phenol, 2,4-dimethyl-' },
    { code: 'U102', desc: '1,2-Benzenedicarboxylic acid, dimethyl ester' },
    { code: 'U102', desc: 'Dimethyl phthalate' },
    { code: 'U103', desc: 'Dimethyl sulfate' },
    { code: 'U103', desc: 'Sulfuric acid, dimethyl ester U105 2,4-Dinitrotoluene' },
    { code: 'U105', desc: 'Benzene, 1-methyl-2,4-dinitro-' },
    { code: 'U106', desc: '2,6-Dinitrotoluene' },
    { code: 'U106', desc: 'Benzene, 2-methyl-1,3-dinitro-' },
    { code: 'U107', desc: '1,2-Benzenedicarboxylic acid, dioctyl ester' },
    { code: 'U107', desc: 'Di-n-octyl phthalate' },
    { code: 'U108', desc: '1,4-Diethyleneoxide' },
    { code: 'U108', desc: '1,4-Dioxane' },
    { code: 'U109', desc: '1,2-Diphenylhydrazine' },
    { code: 'U109', desc: 'Hydrazine, 1,2-diphenyl-' },
    { code: 'U110', desc: '1-Propanimine, N-propyl-(I)' },
    { code: 'U110', desc: 'Dipropylamine (I)' },
    { code: 'U111', desc: '1-Propanamine, N-nitroso-N-propyl-' },
    { code: 'U111', desc: 'Di-n-propylnitrosamine' },
    { code: 'U112', desc: 'Acetic acid, ethyl ester (I)' },
    { code: 'U112', desc: 'Ethyl acetate (I)' },
    { code: 'U113', desc: '2-Propenoic acid, ethyl ester (I)' },
    { code: 'U113', desc: 'Ethyl acrylate (I)' },
    { code: 'U114', desc: 'Carbamodithioic acid, 1,2-ethanediylbis-, salts & esters' },
    { code: 'U114', desc: 'Ethylenebisdithiocarbamic acid, salts & esters' },
    { code: 'U115', desc: 'Ethylene oxide (I,T)' },
    { code: 'U115', desc: 'Oxirane (I,T)' },
    { code: 'U116', desc: '2-Imidazolidinethione' },
    { code: 'U116', desc: 'Ethylenethiourea' },
    { code: 'U117', desc: "Ethane, 1,1'-oxybis-(I)" },
    { code: 'U117', desc: 'Ethyl ether (I)' },
    { code: 'U118', desc: '2-Propenoic acid, 2-methyl-, ethyl ester' },
    { code: 'U118', desc: 'Ethyl methacrylate' },
    { code: 'U119', desc: 'Ethyl methanesulfonate' },
    { code: 'U119', desc: 'Methanesulfonic acid, ethyl ester' },
    { code: 'U121', desc: 'Methane, trichlorofluoro-' },
    { code: 'U121', desc: 'Trichloromonofluoromethane' },
    { code: 'U122', desc: 'Formaldehyde' },
    { code: 'U123', desc: 'Formic acid (C,T)' },
    { code: 'U124', desc: 'Furan (I)' },
    { code: 'U124', desc: 'Furfuran (I)' },
    { code: 'U125', desc: '2-Furancarboxaldehyde (I)' },
    { code: 'U125', desc: 'Furfural (I)' },
    { code: 'U126', desc: 'Glycidylaldehyde' },
    { code: 'U126', desc: 'Oxiranecarboxyaldehyde' },
    { code: 'U127', desc: 'Benzene, hexachloro-' },
    { code: 'U127', desc: 'Hexachlorobenzene' },
    { code: 'U128', desc: '1,3-Butadiene, 1,1,2,3,4,4-hexachloro-' },
    { code: 'U128', desc: 'Hexachlorobutadiene' },
    { code: 'U129', desc: 'Cyclohexane, 1,2,3,4,5,6-hexachloro-, (1alpha, 2alpha, 3beta, 4alpha, 5alpha, 6beta)-' },
    { code: 'U129', desc: 'Lindane' },
    { code: 'U130', desc: '1,3-Cyclopentadiene, 1,2,3,4,5,5- hexachloro-' },
    { code: 'U130', desc: 'Hexachlorocyclopentadiene' },
    { code: 'U131', desc: 'Ethane, hexachloro-' },
    { code: 'U131', desc: 'Hexachloroethane' },
    { code: 'U132', desc: 'Hexachlorophene' },
    { code: 'U132', desc: "Phenol, 2,2'-methylenebis[3,4,6-trichloro-" },
    { code: 'U133', desc: 'Hydrazine (R,T)' },
    { code: 'U120', desc: 'Fluoranthene' },
    { code: 'U134', desc: 'Hydrofluoric acid (C,T)' },
    { code: 'U134', desc: 'Hydrogen fluoride (C,T)' },
    { code: 'U135', desc: 'Hydrogen sulfide' },
    { code: 'U135', desc: 'Hydrogen sulfide H2S' },
    { code: 'U136', desc: 'Arsinic acid, dimethyl-' },
    { code: 'U136', desc: 'Cacodylic acid' },
    { code: 'U137', desc: 'Indeno[1,2,3-cd]pyrene' },
    { code: 'U138', desc: 'Methane, iodo-' },
    { code: 'U138', desc: 'Methyl iodide' },
    { code: 'U140', desc: '1-Propanol, 2-methyl- (I,T)' },
    { code: 'U140', desc: 'Isobutyl alcohol (I,T)' },
    { code: 'U141', desc: '1,3-Benzodioxole, 5-(1-propenyl)-' },
    { code: 'U141', desc: 'Isosafrole' },
    {
      code: 'U142',
      desc: '1,3,4-Metheno-2H-cyclobuta[cd]pentalen-2- one, 1,1a,3,3a,4,5,5,5a,5b,6-decachlorooctahydro-'
    },
    { code: 'U142', desc: 'Kepone' },
    {
      code: 'U143',
      desc:
        '2-Butenoic acid, 2-methyl-, 7-[[2,3- dihydroxy-2-(1-methoxyethyl)-3-methyl-1- oxobutoxy]methyl]-2,3,5,7a-tetrahydro-1H- pyrrolizin-1-yl ester, [1S-[1alpha(Z), 7(2S*,3R*), 7aalpha]]-'
    },
    { code: 'U143', desc: 'Lasiocarpine' },
    { code: 'U144', desc: 'Acetic acid, lead(2+) salt' },
    { code: 'U144', desc: 'Lead acetate' },
    { code: 'U145', desc: 'Lead phosphate' },
    { code: 'U145', desc: 'Phosphoric acid, lead(2+) salt (2:3)' },
    { code: 'U146', desc: 'Lead subacetate' },
    { code: 'U146', desc: 'Lead, bis(acetato-O)tetrahydroxytri-' },
    { code: 'U147', desc: '2,5-Furandione' },
    { code: 'U147', desc: 'Maleic anhydride' },
    { code: 'U148', desc: '3,6-Pyridazinedione, 1,2-dihydro-' },
    { code: 'U148', desc: 'Maleic hydrazide' },
    { code: 'U149', desc: 'Malononitrile' },
    { code: 'U149', desc: 'Propanedinitrile' },
    { code: 'U150', desc: 'L-Phenylalanine, 4-[bis(2-chloroethyl)amino]-' },
    { code: 'U150', desc: 'Melphalan' },
    { code: 'U151', desc: 'Mercury' },
    { code: 'U152', desc: '2-Propenenitrile, 2-methyl- (I,T)' },
    { code: 'U152', desc: 'Methacrylonitrile (I,T)' },
    { code: 'U153', desc: 'Methanethiol (I,T)' },
    { code: 'U153', desc: 'Thiomethanol (I,T)' },
    { code: 'U154', desc: 'Methanol (I)' },
    { code: 'U154', desc: 'Methyl alcohol (I)' },
    { code: 'U155', desc: "1,2-Ethanediamine, N,N-dimethyl-N'-2- pyridinyl-N'-(2-thienylmethyl)-" },
    { code: 'U155', desc: 'Methapyrilene' },
    { code: 'U156', desc: 'Carbonochloridic acid, methyl ester, (I,T)' },
    { code: 'U156', desc: 'Methyl chlorocarbonate (I,T)' },
    { code: 'U157', desc: '3-Methylcholanthrene' },
    { code: 'U157', desc: 'Benz[j]aceanthrylene, 1,2-dihydro-3- methyl-' },
    { code: 'U158', desc: "4,4'-Methylenebis(2-chloroaniline)" },
    { code: 'U158', desc: "Benzenamine, 4,4'-methylenebis[2-chloro-" },
    { code: 'U159', desc: '2-Butanone (I,T)' },
    { code: 'U159', desc: 'Methyl ethyl ketone (MEK) (I,T)' },
    { code: 'U160', desc: '2-Butanone, peroxide (R,T)' },
    { code: 'U160', desc: 'Methyl ethyl ketone peroxide (R,T)' },
    { code: 'U161', desc: '4-Methyl-2-pentanone (I)' },
    { code: 'U161', desc: 'Methyl isobutyl ketone (I)' },
    { code: 'U161', desc: 'Pentanol, 4-methyl-' },
    { code: 'U162', desc: '2-Propenoic acid, 2-methyl-, methyl ester (I,T)' },
    { code: 'U162', desc: 'Methyl methacrylate (I,T)' },
    { code: 'U163', desc: "Guanidine, N-methyl-N'-nitro-N-nitroso-" },
    { code: 'U163', desc: 'MNNG' },
    { code: 'U164', desc: '4(1H)-Pyrimidinone, 2,3-dihydro-6-methyl- 2-thioxo-' },
    { code: 'U164', desc: 'Methylthiouracil' },
    { code: 'U165', desc: 'Naphthalene' },
    { code: 'U166', desc: '1,4-Naphthalenedione' },
    { code: 'U166', desc: '1,4-Naphthoquinone' },
    { code: 'U167', desc: '1-Napthalenamine' },
    { code: 'U167', desc: 'alpha-Naphthylamine' },
    { code: 'U168', desc: '2-Napthalenamine' },
    { code: 'U168', desc: 'beta-Naphthylamine' },
    { code: 'U169', desc: 'Benzene, nitro-' },
    { code: 'U169', desc: 'Nitrobenzene (I,T)' },
    { code: 'U170', desc: 'p-Nitrophenol (I,T)' },
    { code: 'U170', desc: 'Phenol, 4-nitro-' },
    { code: 'U171', desc: '2-Nitropropane (I,T)' },
    { code: 'U171', desc: 'Propane, 2-nitro- (I,T)' },
    { code: 'U172', desc: '1-Butanamine, N-butyl-N-nitroso-' },
    { code: 'U172', desc: 'N-Nitrosodi-n-butylamine' },
    { code: 'U173', desc: "Ethanol, 2,2'-(nitrosoimino)bis-" },
    { code: 'U173', desc: 'N-Nitrosodiethanolamine' },
    { code: 'U174', desc: 'Ethanamine, N-ethyl-N-nitroso-' },
    { code: 'U174', desc: 'N-Nitrosodiethylamine U176 N-Nitroso-N-ethylurea' },
    { code: 'U176', desc: 'Urea, N-ethyl-N-nitroso-' },
    { code: 'U177', desc: 'N-Nitroso-N-methylurea' },
    { code: 'U177', desc: 'Urea, N-methyl-N-nitroso-' },
    { code: 'U178', desc: 'Carbamic acid, methylnitroso-, ethyl ester' },
    { code: 'U178', desc: 'N-Nitroso-N-methylurethane' },
    { code: 'U179', desc: 'N-Nitrosopiperidine' },
    { code: 'U179', desc: 'Piperidine, 1-nitroso-' },
    { code: 'U180', desc: 'N-Nitrosopyrrolidine' },
    { code: 'U180', desc: 'Pyrrolidine, 1-nitroso-' },
    { code: 'U181', desc: '5-Nitro-o-toluidine' },
    { code: 'U181', desc: 'Benzenamine, 2-methyl-5-nitro' },
    { code: 'U182', desc: '1,3,5-Trioxane, 2,4,6-trimethyl-' },
    { code: 'U182', desc: 'Paraldehyde' },
    { code: 'U183', desc: 'Benzene, pentachloro-' },
    { code: 'U183', desc: 'Pentachlorobenzene' },
    { code: 'U184', desc: 'Ethane, pentachloro-' },
    { code: 'U184', desc: 'Pentachloroethane' },
    { code: 'U185', desc: 'Benzene, pentachloronitro-' },
    { code: 'U185', desc: 'Pentachloronitrobenzene (PCNB)' },
    { code: 'U186', desc: '1,3-Pentadiene (I)' },
    { code: 'U186', desc: '1-Methylbutadiene (I)' },
    { code: 'U187', desc: 'Acetamide, N-(4-ethoxyphenyl)-' },
    { code: 'U187', desc: 'Phenacetin' },
    { code: 'U188', desc: 'Phenol' },
    { code: 'U189', desc: 'Phosphorus sulfide (R)' },
    { code: 'U189', desc: 'Sulfur phosphide (R)' },
    { code: 'U190', desc: '1,3-Isobenzofurandione' },
    { code: 'U190', desc: 'Phthalic anhydride' },
    { code: 'U191', desc: '2-Picoline' },
    { code: 'U191', desc: 'Pyridine, 2-methyl-' },
    { code: 'U192', desc: 'Benzamide, 3,5-dichloro-N-(1,1-dimethyl- 2-propynyl)-' },
    { code: 'U192', desc: 'Pronamide' },
    { code: 'U193', desc: '1,2-Oxathiolane, 2,2-dioxide' },
    { code: 'U193', desc: '1,3-Propane sultone' },
    { code: 'U194', desc: '1-Propanamine (I,T)' },
    { code: 'U194', desc: 'n-Propylamine (I,T)' },
    { code: 'U196', desc: 'Pyridine' },
    { code: 'U197', desc: '2,5-Cyclohexadiene-1,4-dione' },
    { code: 'U197', desc: 'p-Benzoquinone' },
    { code: 'U200', desc: 'Reserpine' },
    {
      code: 'U200',
      desc:
        'Yohimban-16-carboxylic acid, 11,17-dimethoxy-18-[(3,4,5-trimethoxybenzoyl)oxy]-, methyl ester, (3beta, 16beta, 17alpha, 18beta, 20alpha)-'
    },
    { code: 'U201', desc: '1,3-Benzenediol' },
    { code: 'U201', desc: 'Resorcinol' },
    { code: 'U202', desc: '1,2-Benzisothiazol-3(2H)-one, 1,1-dioxide, & salts' },
    { code: 'U202', desc: 'Saccharin, & salts' },
    { code: 'U203', desc: '1,3-Benzodioxole, 5-(2-propenyl)-' },
    { code: 'U203', desc: 'Safrole' },
    { code: 'U204', desc: 'Selenious acid' },
    { code: 'U204', desc: 'Selenium dioxide' },
    { code: 'U205', desc: 'Selenium sulfide' },
    { code: 'U205', desc: 'Selenium sulfide SeS2 (R,T)' },
    { code: 'U206', desc: 'D-Glucose, 2-deoxy-2- [[(methylnitrosoamino)-carbonyl]amino]-' },
    { code: 'U206', desc: 'Glucopyranose, 2-deoxy-2-(3-methyl-3- nitrosoureido)-,D-' },
    { code: 'U206', desc: 'Streptozotocin' },
    { code: 'U207', desc: '1,2,4,5-Tetrachlorobenzene' },
    { code: 'U207', desc: 'Benzene, 1,2,4,5-tetrachloro-' },
    { code: 'U208', desc: '1,1,1,2-Tetrachloroethane' },
    { code: 'U208', desc: 'Ethane, 1,1,1,2-tetrachloro-' },
    { code: 'U209', desc: '1,1,2,2-Tetrachloroethane' },
    { code: 'U209', desc: 'Ethane, 1,1,2,2-tetrachloro-' },
    { code: 'U210', desc: 'Ethene, tetrachloro-' },
    { code: 'U210', desc: 'Tetrachloroethylene' },
    { code: 'U211', desc: 'Carbon tetrachloride' },
    { code: 'U211', desc: 'Methane, tetrachloro- U213 Furan, tetrahydro-(I)' },
    { code: 'U213', desc: 'Tetrahydrofuran (I)' },
    { code: 'U214', desc: 'Acetic acid, thallium(1+) salt' },
    { code: 'U214', desc: 'Thallium(I) acetate' },
    { code: 'U215', desc: 'Carbonic acid, dithallium(1+) salt' },
    { code: 'U215', desc: 'Thallium(I) carbonate' },
    { code: 'U216', desc: 'Thallium chloride Tlcl' },
    { code: 'U216', desc: 'Thallium(I) chloride' },
    { code: 'U217', desc: 'Nitric acid, thallium(1+) salt' },
    { code: 'U217', desc: 'Thallium(I) nitrate' },
    { code: 'U218', desc: 'Ethanethioamide' },
    { code: 'U218', desc: 'Thioacetamide' },
    { code: 'U219', desc: 'Thiourea' },
    { code: 'U220', desc: 'Benzene, methyl-' },
    { code: 'U220', desc: 'Toluene' },
    { code: 'U221', desc: 'Benzenediamine, ar-methyl-' },
    { code: 'U221', desc: 'Toluenediamine' },
    { code: 'U222', desc: 'Benzenamine, 2-methyl-, hydrochloride' },
    { code: 'U222', desc: 'o-Toluidine hydrochloride' },
    { code: 'U223', desc: 'Benzene, 1,3-diisocyanatomethyl- (R,T)' },
    { code: 'U223', desc: 'Toluene diisocyanate (R,T) U225 Bromoform' },
    { code: 'U225', desc: 'Methane, tribromo-' },
    { code: 'U226', desc: 'Ethane, 1,1,1-trichloro-' },
    { code: 'U226', desc: 'Methyl chloroform' },
    { code: 'U227', desc: '1,1,2-Trichloroethane' },
    { code: 'U227', desc: 'Ethane, 1,1,2-trichloro-' },
    { code: 'U228', desc: 'Ethene, trichloro-' },
    { code: 'U228', desc: 'Trichloroethylene' },
    { code: 'U234', desc: '1,3,5-Trinitrobenzene (R,T)' },
    { code: 'U234', desc: 'Benzene, 1,3,5-trinitro-' },
    { code: 'U235', desc: '1-Propanol, 2,3-dibromo-, phosphate (3:1)' },
    { code: 'U235', desc: 'Tris(2,3,-dibromopropyl) phosphate' },
    {
      code: 'U236',
      desc:
        "2,7-Naphthalenedisulfonic acid,3,3'-[(3,3'- dimethyl[1,1'-biphenyl]-4,4'-diyl)bis(azo)bis[5-amino-4-hydroxy]-, tetrasodium salt"
    },
    { code: 'U236', desc: 'Trypan blue' },
    { code: 'U237', desc: '2,4-(1H,3H)-Pyrimidinedione, 5-[bis(2- chloroethyl)amino]-' },
    { code: 'U237', desc: 'Uracil mustard' },
    { code: 'U238', desc: 'Carbamic acid, ethyl ester' },
    { code: 'U238', desc: 'Ethyl carbamate (urethane)' },
    { code: 'U239', desc: 'Benzene, dimethyl- (I,T)' },
    { code: 'U239', desc: 'Xylene (I)' },
    { code: 'U240', desc: '2,4-D, salts & esters' },
    { code: 'U240', desc: 'Acetic acid, (2,4-dichlorophenoxy)-, salts & esters' },
    { code: 'U243', desc: '1-Propene, 1,1,2,3,3,3-hexachloro- U243 Hexachloropropene' },
    { code: 'U244', desc: 'Thioperoxydicarbonic diamide [(H2N)C(S)]2S2, tetramethyl-' },
    { code: 'U244', desc: 'Thiram' },
    { code: 'U246', desc: 'Cyanogen bromide (CN)Br' },
    { code: 'U247', desc: "Benzene, 1,1'-(2,2,2- trichloroethylidene)bis[4-methoxy-" },
    { code: 'U247', desc: 'Methoxychlor' },
    {
      code: 'U248',
      desc:
        '2H-1-Benzopyran-2-one, 4-hydroxy-3-(3- oxo-1-phenyl-butyl)-, & salts, when present at concentrations of 0.3% or less'
    },
    { code: 'U248', desc: 'Warfarin, & salts, when present at concentrations of 0.3% or less' },
    { code: 'U249', desc: 'Zinc phosphide Zn3P2, when present at concentrations of 10% or less' },
    { code: 'U328', desc: 'Benzenamine, 2-methyl- U328 o-Toluidine' },
    { code: 'U353', desc: 'Benzenamine, 4-methyl- U353 p-Toluidine' },
    { code: 'U359', desc: 'Ethanol, 2-ethoxy-' },
    { code: 'U359', desc: 'Ethylene glycol monoethyl ether' },
    { code: "U364", desc: "Bendiocarb phenol." },
    { code: "U364", desc: "1,3-Benzodioxol-4-ol, 2,2-dimethyl-," },
    { code: "U367", desc: "7-Benzofuranol, 2,3-dihydro-2,2-dimethyl-" },
    { code: "U367", desc: "Carbofuran phenol." },
    { code: "U372", desc: "Carbamic acid, 1H-benzimidazol-2-yl, methyl ester." },
    { code: "U372", desc: "Carbendazim." },
    { code: "U373", desc: "Carbamic acid, phenyl-, 1-methylethyl ester." },
    { code: "U373", desc: "Propham." },
    { code: "U387", desc: "Carbamothioic acid, dipropyl-, S-(phenylmethyl) ester." },
    { code: "U387", desc: "Prosulfocarb." },
    { code: "U389", desc: "Carbamothioic acid, bis(1-methylethyl)-, S-(2,3,3-trichloro-2-propenyl) ester." },
    { code: "U389", desc: "Triallate." },
    { code: "U394", desc: "A2213." },
    { code: "U394", desc: "Ethanimidothioic acid, 2-(dimethylamino)-N-hydroxy-2-oxo-, methyl ester." },
    { code: "U395", desc: "Diethylene glycol, dicarbamate." },
    { code: "U395", desc: "Ethanol, 2,2′-oxybis-, dicarbamate." },
    { code: "U404", desc: "Ethanamine, N,N-diethyl-" },
    { code: "U404", desc: "Triethylamine." },
    { code: "U409", desc: "Carbamic acid, [1,2-phenylenebis (iminocarbonothioyl)]bis-, dimethyl ester." },
    { code: "U409", desc: "Thiophanate-methyl." },
    { code: "U410", desc: "Ethanimidothioic acid, N,N′- [thiobis[(methylimino)carbonyloxy]]bis-, dimethyl ester" },
    { code: "U410", desc: "Thiodicarb." },
    { code: "U411", desc: "Phenol, 2-(1-methylethoxy)-, methylcarbamate." },
    { code: "U411", desc: "Propoxur." },
  ];

  intended_use_categories = [
    {
      name: 'Antifreeze/Coolant',
      keyword: 'ANTIFREEZE',
      subcategories: [{ name: 'Other', keyword: 'OTHER' }]
    },
    {
      name: 'Batteries',
      keyword: 'BATTERY',
      subcategories: [
        { name: 'Alkaline', keyword: 'ALKALINE' },
        { name: 'Lead-acid', keyword: 'LEAD_ACID' },
        { name: 'Lithium Manganese Dioxide', keyword: 'LITHIUM_MANGANESE_DIOXIDE' },
        { name: 'Lithium metal', keyword: 'LITHIUM_METAL' },
        { name: 'Lithium-ion polymer', keyword: 'LITHIUM_ION' },
        { name: 'Nickel metal hydride', keyword: 'NICKEL_METAL_HYDRIDE' },
        { name: 'Nickel-cadmium', keyword: 'NICKEL_CADMIUM' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Silver oxide', keyword: 'SILVER_OXIDE' },
        { name: 'Zinc aire', keyword: 'ZINC_AIR' },
        { name: 'Zinc carbon', keyword: 'ZINC_CARBON' }
      ]
    },
    {
      name: 'Building/Construction, Tools and Hardware',
      keyword: 'CONSTRUCTION',
      subcategories: [
        { name: 'Hardware/Handtools/Containers', keyword: 'HARDWARE_AND_HAND_TOOLS' },
        { name: 'Masonry and cement', keyword: 'MASONRY_AND_CEMENT' },
        { name: 'Metal work products', keyword: 'METAL_WORK_PRODUCTS' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Plumbing', keyword: 'PLUMBING' },
        { name: 'Putty/Grout', keyword: 'PUTTY_AND_GROUT' },
        { name: 'Remover/Strippers', keyword: 'REMOVER_AND_STRIPPER' },
        { name: 'Saws/Knives', keyword: 'SAWS_AND_KNIVES' },
        { name: 'Wall texture/Spackling', keyword: 'WALL_TEXTURE_AND_SPACKLING' }
      ]
    },
    {
      name: 'Capacitors',
      keyword: 'CAPACITOR',
      subcategories: [{ name: 'Other', keyword: 'OTHER' }]
    },
    {
      name: 'Cleaning product',
      keyword: 'CLEANER',
      subcategories: [
        { name: 'All purpose/General cleaner', keyword: 'ALL_PURPOSE' },
        { name: 'Auto cleaning Product', keyword: 'AUTOMOTIVE' },
        { name: 'Bathroom cleaners', keyword: 'BATHROOM' },
        { name: 'Bleach', keyword: 'BLEACH' },
        { name: 'Disinfectant', keyword: 'DISINFECTANT' },
        { name: 'Cleaning wipes', keyword: 'WIPES' },
        { name: 'Drain/Septic', keyword: 'DRAIN_AND_SEPTIC' },
        { name: 'Floor/Carpet/Concrete cleaners', keyword: 'FLOOR_AND_CARPET' },
        { name: 'Furniture cleaning', keyword: 'FURNITURE' },
        { name: 'Glass cleaner', keyword: 'GLASS' },
        { name: 'Kitchen cleaners', keyword: 'KITCHEN' },
        { name: 'Laundry care', keyword: 'LAUNDRY' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Soaps', keyword: 'SOAP' }
      ]
    },
    {
      name: 'Consumables',
      keyword: 'CONSUMABLE',
      subcategories: [
        { name: 'Beer', keyword: 'BEER' },
        { name: 'Cooking oil', keyword: 'COOKING_OIL' },
        { name: 'Extracts/Seasonings/Flavour enhancers (shelf stable)', keyword: 'EXTRACT_AND_SEASONING' },
        { name: 'Food', keyword: 'FOOD' },
        { name: 'Medicine', keyword: 'MEDICINE' },
        { name: 'Non-alcoholic beverages', keyword: 'NON_ALCOHOLIC_BEVERAGE' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Spirits', keyword: 'SPIRITS' },
        { name: 'Wine', keyword: 'WINE' }
      ]
    },
    {
      name: 'Detonators',
      keyword: 'DETONATOR',
      subcategories: [{ name: 'Other', keyword: 'OTHER' }]
    },
    {
      name: 'Electronics',
      keyword: 'ELECTRONICS',
      subcategories: [
        { name: 'Computer', keyword: 'COMPUTER' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'TV', keyword: 'TV' }
      ]
    },
    {
      name: 'Engines',
      keyword: 'ENGINE',
      subcategories: [{ name: 'Other', keyword: 'OTHER' }]
    },
    {
      name: 'Explosives',
      keyword: 'EXPLOSIVE',
      subcategories: [
        { name: 'Article', keyword: 'ARTICLE' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Powder', keyword: 'POWDER' }
      ]
    },
    {
      name: 'Fertilizer',
      keyword: 'FERTILIZER',
      subcategories: [{ name: 'Other', keyword: 'OTHER' }]
    },
    {
      name: 'Fire Extinguishers',
      keyword: 'FIRE_EXTINGUISHER',
      subcategories: [
        { name: 'ABC powder fire extinguisher', keyword: 'ABC_POWDER' },
        { name: 'Carbon dioxide fire extinguisher', keyword: 'CARBON_DIOXIDE' },
        { name: 'Foam fire extinguisher', keyword: 'FOAM' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Water mist fire extinguisher', keyword: 'WATER_MIST' },
        { name: 'Wet chemical fire extinguisher', keyword: 'WET_CHEMICAL' }
      ]
    },
    {
      name: 'Firelighting Tools/Matches',
      keyword: 'FIRE_STARTER',
      subcategories: [
        { name: 'Butane mini-torch', keyword: 'BUTANE_MINI_TORCH' },
        { name: 'Charcoal', keyword: 'CHARCOAL' },
        { name: 'Lighter fluid', keyword: 'LIGHTER_FLUID' },
        { name: 'Lighters', keyword: 'LIGHTERS' },
        { name: 'Matches', keyword: 'MATCHES' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Solid fire starter', keyword: 'SOLID_FIRE_STARTER' }
      ]
    },
    {
      name: 'Fireworks/Flares/Signals',
      keyword: 'FIREWORKS_AND_FLARES',
      subcategories: [
        { name: 'Fireworks', keyword: 'FIREWORKS' },
        { name: 'Flares', keyword: 'FLARES' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Signals', keyword: 'SIGNALS' }
      ]
    },
    {
      name: 'Glue/Adhesives/Resins',
      keyword: 'ADHESIVE',
      subcategories: [
        { name: 'Glue/Adhesive/Epoxy', keyword: 'GLUE_ADHESIVE_EPOXY' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Resin/Caulk/Sealant', keyword: 'RESIN_CAULK_SEALANT' },
        { name: 'Tape', keyword: 'TAPE' }
      ]
    },
    {
      name: 'Ice melt',
      keyword: 'ICE_MELT',
      subcategories: [{ name: 'Other', keyword: 'OTHER' }]
    },
    {
      name: 'Inflatables',
      keyword: 'INFLATABLE',
      subcategories: [
        { name: 'Air-bag', keyword: 'AIR_BAG' },
        { name: 'Air-mattress', keyword: 'AIR_MATTRESS' },
        { name: 'Lifejackets/Vests/Belts', keyword: 'LIFEJACKET' },
        { name: 'Other', keyword: 'OTHER' }
      ]
    },
    {
      name: 'Ink',
      keyword: 'INK',
      subcategories: [
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Pen', keyword: 'PEN' },
        { name: 'Printer ink/Toner', keyword: 'PRINTER_INK_AND_TONER' }
      ]
    },
    {
      name: 'Lightbulbs',
      keyword: 'LIGHTBULB',
      subcategories: [
        { name: 'CFL', keyword: 'CFL' },
        { name: 'Fluorescent', keyword: 'FLUORESCENT' },
        { name: 'Halogen lamp', keyword: 'HALOGEN_LAMP' },
        { name: 'Incandescent', keyword: 'INCANDESCENT' },
        { name: 'Infrared lamp', keyword: 'INFRARED_LAMP' },
        { name: 'LED', keyword: 'LED' },
        { name: 'Metal-halide lamp', keyword: 'METAL_HALIDE_LAMP' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Sodium-vapor lamp', keyword: 'SODIUM_VAPOR_LAMP' }
      ]
    },
    {
      name: 'Lubricant',
      keyword: 'LUBRICANT',
      subcategories: [
        { name: 'Grease', keyword: 'GREASE' },
        { name: 'Multi-purpose lubricant', keyword: 'MULTI_PURPOSE_LUBRICANT' },
        { name: 'Other', keyword: 'OTHER' }
      ]
    },
    {
      name: 'Motor oil/Fuel/Fuel additive',
      keyword: 'OIL_AND_FUEL',
      subcategories: [
        { name: 'Fuel', keyword: 'FUEL' },
        { name: 'Fuel treatment/Additive', keyword: 'FUEL_ADDITIVE' },
        { name: 'Motor oil', keyword: 'MOTOR_OIL' },
        { name: 'Other', keyword: 'OTHER' }
      ]
    },
    {
      name: 'Nicotine/Tobacco',
      keyword: 'NICOTINE_AND_TOBACCO',
      subcategories: [
        { name: 'Nicotine Cessation', keyword: 'NICOTINE_CESSATION' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Tobacco', keyword: 'TOBACCO' },
        { name: 'Vape related', keyword: 'VAPE_RELATED' }
      ]
    },
    {
      name: 'Odorizers',
      keyword: 'ODORIZER',
      subcategories: [
        { name: 'Air sanitizer', keyword: 'AIR_SANITIZER' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Room deodorizer/Air freshener', keyword: 'AIR_FRESHENER' }
      ]
    },
    {
      name: 'Other',
      keyword: 'OTHER',
      subcategories: [{ name: 'Other', keyword: 'OTHER' }]
    },
    {
      name: 'Paint/Paint related',
      keyword: 'PAINT_RELATED',
      subcategories: [
        { name: 'Coatings', keyword: 'COATING' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Paint', keyword: 'PAINT' },
        { name: 'Stain/Waterproofing/Sealers', keyword: 'STAIN_WATERPROOFING_SEALER' },
        { name: 'Thinner/Solvent', keyword: 'THINNER_AND_SOLVENT' }
      ]
    },
    {
      name: 'Personal care items/Cosmetics',
      keyword: 'PERSONAL_CARE',
      subcategories: [
        { name: 'Baby care', keyword: 'BABY_CARE' },
        { name: 'First aid', keyword: 'FIRST_AID' },
        { name: 'Fragrance', keyword: 'FRAGRANCE' },
        { name: 'Hair care', keyword: 'HAIR_CARE' },
        { name: 'Hair dye/Coloring products', keyword: 'HAIR_DYE_AND_COLORING' },
        { name: 'Make up', keyword: 'MAKE_UP' },
        { name: 'Nail care', keyword: 'NAIL_CARE' },
        { name: 'Oral care', keyword: 'ORAL_CARE' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Personal cleanliness products', keyword: 'PERSONAL_CLEANLINESS' },
        { name: 'Shaving products', keyword: 'SHAVING' },
        { name: 'Sunscreen/Sunless tanners', keyword: 'SUNSCREEN_AND_TANNERS' }
      ]
    },
    {
      name: 'Poisons: Insecticides/Pesticides/Rodenticides',
      keyword: 'POISON',
      subcategories: [
        { name: 'Fungicide', keyword: 'FUNGICIDE' },
        { name: 'Herbicide', keyword: 'HERBICIDE' },
        { name: 'Moss killer', keyword: 'MOSS_KILLER' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Pesticide/Insecticide', keyword: 'PESTICIDE_INSECTICIDE' },
        { name: 'Rodenticide', keyword: 'RODENTICIDE' }
      ]
    },
    {
      name: 'Pet care',
      keyword: 'PET_CARE',
      subcategories: [{ name: 'Other', keyword: 'OTHER' }]
    },
    {
      name: 'Refrigerating gas/Freezing appliances',
      keyword: 'REFRIGERANT',
      subcategories: [
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Refrigerating appliances', keyword: 'REFRIGERATING_APPLIANCE' },
        { name: 'Refrigerating gas', keyword: 'REFRIGERATING_GAS' }
      ]
    },
    {
      name: 'Rocket Related',
      keyword: 'ROCKET_RELATED',
      subcategories: [{ name: 'Other', keyword: 'OTHER' }]
    },
    {
      name: 'Sporting goods/Self-defense',
      keyword: 'SELF_DEFENSE',
      subcategories: [
        { name: 'Firearms ammunition/Cartridges/Artillery', keyword: 'FIREARMS_AND_AMMUNITION' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'Pepper spray', keyword: 'PEPPER_SPRAY' },
        { name: 'Tasers', keyword: 'TASERS' }
      ]
    },
    {
      name: 'Swimming pool products',
      keyword: 'POOL_PRODUCT',
      subcategories: [
        { name: 'Algaecide', keyword: 'ALGAECIDE' },
        { name: 'Clarifier', keyword: 'CLARIFIER' },
        { name: 'Other', keyword: 'OTHER' },
        { name: 'PH down', keyword: 'PH_DOWN' },
        { name: 'PH up', keyword: 'PH_UP' },
        { name: 'Pool shock chlorine', keyword: 'POOL_SHOCK_CHLORINE' },
        { name: 'Stabilizer/Conditioner', keyword: 'STABILIZER_AND_CONDITIONER' }
      ]
    },
    {
      name: 'Windshield washer fluid',
      keyword: 'WINDSHIELD_WASHER_FLUID',
      subcategories: [{ name: 'Other', keyword: 'OTHER' }]
    }
  ];

  wa_codes = {
    wt: ['WT01', 'WT02', 'None', 'Unknown'],
    wp: ['WP01', 'WP02', 'WP03', 'None', 'Unknown'],
    ws: ['WSC2', 'None', 'Unknown']
  }

  // ct_codes = ['CR01', 'CR02', 'CR04', 'CR05'];
  // il_codes = ['IL14', 'IL15', 'IL Class A', 'IL Class B'];
  // md_codes = ['M001'];
  // mi_codes = ['029L', '001U', '002U', '003U', '004U', '005U', '006U', '007U', '008U',
  // '009U', '011U', '012U', '014U', '020U', '022U', '023U', '027U', '029U', '032U', '033U',
  // '034U', '036U', '037U', '038U', '040U', '042U', '043U', '044U', '046U', '049U', '050U',
  // '051U', '052U', '054U', '055U', '056U', '057U', '058U', '059U', '061U', '063U', '064U',
  // '065U', '068U', '070U', '071U', '073U', '074U', '075U', '076U', '078U', '079U', '082U',
  // '083U', '086U', '088U', '092U', '093U', '094U', '095U', '097U', '098U', '099U', '100U',
  // '101U', '102U', '103U', '104U', '106U', '108U', '110U', '111U', '112U', '113U', '115U',
  // '116U', '117U', '118U', '119U', '120U', '121U', '124U', '127U', '128U', '129U', '131U',
  // '132U', '136U', '137U', '138U', '141U', '142U', '143U', '147U', '148U', '150U', '151U',
  // '152U', '154U', '157U', '158U', '159U', '160U', '161U', '162U', '163U', '164U', '165U',
  // '166U', '167U', '169U', '170U', '171U', '172U', '173U', '175U'];
  // mn_codes = ['MN01', 'MN03'];
  // mo_codes = ['M011', 'MH02', 'D098'];
  // nh_codes = ['NH01', 'NH02', 'NH03'];
  // nj_codes = ['ID27A'];
  // ny_codes = ['B005'];

  constructor() {}

  public createFindProduct(form: FormGroup) {
    let request: any;

    if (form.controls.whatIsneeded.value === 'none' && form.controls.sds_url.value === '') {
      request = {
        what_is_needed: form.controls.whatIsneeded.value,
        sds_revision_date: form.controls.revision_date.value
      };
    } else if (form.controls.whatIsneeded.value === 'product_label_url') {
      request = {
        what_is_needed: form.controls.whatIsneeded.value,
        product_label_url: form.controls.product_label_url.value,
        sds_revision_date: form.controls.revision_date.value
      };
    } else {
      request = {
        what_is_needed: form.controls.whatIsneeded.value,
        sds_url: form.controls.sds_url.value,
        sds_revision_date: form.controls.revision_date.value
      };
    }
    return request;
  }

  public createFlashPoint(form: FormGroup) {
    const request: any = {
      format_input: form.controls.format.value,
      symbol: form.controls.symbol.value,
      // symbol_min: form.controls.symbol_min.value,
      // symbol_max: form.controls.symbol_max.value,
      value: form.controls.value.value,
      // value_min: form.controls.value_min.value,
      // value_max: form.controls.value_max.value,
      unit: form.controls.unit.value
    };
    return request;
  }

  public createBolingPoint(form: FormGroup) {
    const request: any = {
      format_input: form.controls.format.value,
      symbol: form.controls.symbol.value,
      // symbol_min: form.controls.symbol_min.value,
      // symbol_max: form.controls.symbol_max.value,
      value: form.controls.value.value,
      // value_min: form.controls.value_min.value,
      // value_max: form.controls.value_max.value,
      unit: form.controls.unit.value
    };
    return request;
  }

  public createMeltingPoint(form: FormGroup) {
    const request: any = {
      format_input: form.controls.format.value,
      symbol: form.controls.symbol.value,
      // symbol_min: form.controls.symbol_min.value,
      // symbol_max: form.controls.symbol_max.value,
      value: form.controls.value.value,
      // value_min: form.controls.value_min.value,
      // value_max: form.controls.value_max.value,
      unit: form.controls.unit.value
    };
    return request;
  }

  public createPh(form: FormGroup) {
    const request: any = {
      format_input: form.controls.format.value,
      ph_value: form.controls.ph_value.value,
      ph_min_value: form.controls.ph_min_value.value,
      ph_max_value: form.controls.ph_max_value.value,
      symbol_min: form.controls.symbol_min.value,
      symbol_max: form.controls.symbol_max.value,
      symbol: form.controls.symbol.value
    };
    return request;
  }

  public createIntendedUse(form: FormGroup, userExpert) {
    let request: any;
    if (userExpert) {
      request = {
        intended_use: form.controls.intended_use.value.name,
        intended_use_subCategory: form.controls.intended_use_subCategory.value.name
      };
    } else {
      if (form.controls.intended_use_subCategory.value && !userExpert) {
        request = {
          recommended_use: form.controls.recommended_use.value,
          intended_use: form.controls.intended_use.value.keyword,
          intended_use_subcategory: form.controls.intended_use_subCategory.value.keyword
        };
      } else {
        request = {
          recommended_use: form.controls.recommended_use.value,
          intended_use: form.controls.intended_use.value.keyword
        };
      }
    }
    return request;
  }

  public createIship(form: FormGroup) {
    const f = form.controls;

    const defaultNotFoundText = 'Could Not Find';

    const dotHazard = this.checkUnValueExists((f.dot_grp as FormGroup).controls, 'dot_hazard_division')
      ? (f.dot_grp as FormGroup).controls.dot_hazard_division.value
      : (f.dot_grp as FormGroup).controls.dot_hazard_class.value;
    const iataHazard = this.checkUnValueExists((f.iata_grp as FormGroup).controls, 'iata_hazard_division')
      ? (f.iata_grp as FormGroup).controls.iata_hazard_division.value
      : (f.iata_grp as FormGroup).controls.iata_hazard_class.value;
    const imdgHazard = this.checkUnValueExists((f.imdg_grp as FormGroup).controls, 'imdg_hazard_division')
      ? (f.imdg_grp as FormGroup).controls.imdg_hazard_division.value
      : (f.imdg_grp as FormGroup).controls.imdg_hazard_class.value;

    const dotSubHazard = this.checkUnValueExists((f.dot_grp as FormGroup).controls, 'dot_subhazard_division')
      ? (f.dot_grp as FormGroup).controls.dot_subhazard_division.value
      : (f.dot_grp as FormGroup).controls.dot_subhazard_class.value;
    const iataSubHazard = this.checkUnValueExists((f.iata_grp as FormGroup).controls, 'iata_subhazard_division')
      ? (f.iata_grp as FormGroup).controls.iata_subhazard_division.value
      : (f.iata_grp as FormGroup).controls.iata_subhazard_class.value;
    const imdgSubHazard = this.checkUnValueExists((f.imdg_grp as FormGroup).controls, 'imdg_subhazard_division')
      ? (f.imdg_grp as FormGroup).controls.imdg_subhazard_division.value
      : (f.imdg_grp as FormGroup).controls.imdg_subhazard_class.value;

    const dotCompatibility = this.checkUnValueExists((f.dot_grp as FormGroup).controls, 'dot_hazard_compatibility')
      ? (f.dot_grp as FormGroup).controls.dot_hazard_compatibility.value
      : defaultNotFoundText;
    const iataCompatibility = this.checkUnValueExists((f.iata_grp as FormGroup).controls, 'iata_hazard_compatibility')
      ? (f.iata_grp as FormGroup).controls.iata_hazard_compatibility.value
      : defaultNotFoundText;
    const imdgCompatibility = this.checkUnValueExists((f.imdg_grp as FormGroup).controls, 'imdg_hazard_compatibility')
      ? (f.imdg_grp as FormGroup).controls.imdg_hazard_compatibility.value
      : defaultNotFoundText;

    const dotPackingGroup = this.checkUnValueExists((f.dot_grp as FormGroup).controls, 'dot_hazard_packing_group')
      ? (f.dot_grp as FormGroup).controls.dot_hazard_packing_group.value
      : defaultNotFoundText;
    const iataPackingGroup = this.checkUnValueExists((f.iata_grp as FormGroup).controls, 'iata_hazard_packing_group')
      ? (f.iata_grp as FormGroup).controls.iata_hazard_packing_group.value
      : defaultNotFoundText;
    const imdgPackingGroup = this.checkUnValueExists((f.imdg_grp as FormGroup).controls, 'imdg_hazard_packing_group')
      ? (f.imdg_grp as FormGroup).controls.imdg_hazard_packing_group.value
      : defaultNotFoundText;

    const request: any = {
      dot: {
        hazard: dotHazard,
        subhazard: dotSubHazard,
        compatibility: dotCompatibility,
        packing_group: this.checkPackingValue(dotHazard, dotPackingGroup),
        proper_shipping_name: form.value.un_dot_grp.dot_proper_shipping_name
          ? form.value.un_dot_grp.dot_proper_shipping_name
          : defaultNotFoundText
      },
      iata: {
        hazard: iataHazard,
        subhazard: iataSubHazard,
        compatibility: iataCompatibility,
        packing_group: this.checkPackingValue(iataHazard, iataPackingGroup),
        proper_shipping_name: form.value.un_iata_grp.iata_proper_shipping_name
          ? form.value.un_iata_grp.iata_proper_shipping_name
          : defaultNotFoundText
      },
      imdg: {
        hazard: imdgHazard,
        subhazard: imdgSubHazard,
        compatibility: imdgCompatibility,
        packing_group: this.checkPackingValue(imdgHazard, imdgPackingGroup),
        proper_shipping_name: form.value.un_imdg_grp.imdg_proper_shipping_name
          ? form.value.un_imdg_grp.imdg_proper_shipping_name
          : defaultNotFoundText,
        un: form.value.un_imdg_grp.un_imdg
      }
    };

    if (form.value.un_dot_grp.un_dot === defaultNotFoundText) {
      if (form.value.na_num === defaultNotFoundText) {
        request.dot = { ...request.dot, un: defaultNotFoundText };
      } else {
        request.dot = { ...request.dot, na_number: form.value.na_num };
      }
    } else {
      request.dot = { ...request.dot, un: form.value.un_dot_grp.un_dot };
    }

    if (form.value.un_iata_grp.un_iata === defaultNotFoundText) {
      if (form.value.id_num === defaultNotFoundText) {
        request.iata = { ...request.iata, un: defaultNotFoundText };
      } else {
        request.iata = { ...request.iata, id_number: form.value.id_num };
      }
    } else {
      request.iata = { ...request.iata, un: form.value.un_iata_grp.un_iata };
    }

    return request;
  }

  private checkUnValueExists(formControls, formControl): boolean {
    if (formControls[formControl]) {
      if (formControls[formControl].value && formControls[formControl].value !== 'Not Restricted') {
        return true;
      }
    }
    return false;
  }

  private checkPackingValue(hazardClassValue, packingGroupValue) {
    const notRestrictedText = 'Not Restricted';
    return (hazardClassValue === notRestrictedText ? notRestrictedText : packingGroupValue);
  }

  public createValidateSdsPairing(form: FormGroup) {
    const request = {
      is_pairing_valid: form.controls.is_pairing_valid.value,
      pdf_is: form.controls.pdf_is.value
    };
    if (form.controls.is_pairing_valid.value === 'no') {
      request['invalid_pairing_reason'] = form.controls.invalid_pairing_reason.value;
      if (form.controls.invalid_pairing_reason.value === 'other') {
        request['comment'] = form.controls.comment.value;
      }
    } else {
      request['quality'] = form.controls.quality.value;
    }
    if (form.controls.what_is_needed.value !== '') {
      request['what_is_needed'] = form.controls.what_is_needed.value;
    }
    if (form.controls.revision_date.value) {
      request['sds_revision_date'] = form.controls.revision_date.value;
    }
    return request;
  }

  public createUnNumber(form: FormGroup) {
    const request = {
      un_dot: form.value.un_dot_grp.un_dot,
      un_iata: form.value.un_iata_grp.un_iata,
      un_imdg: form.value.un_imdg_grp.un_imdg,
      dot_proper_shipping_name: form.value.un_dot_grp.dot_proper_shipping_name,
      iata_proper_shipping_name: form.value.un_iata_grp.iata_proper_shipping_name,
      imdg_proper_shipping_name: form.value.un_imdg_grp.imdg_proper_shipping_name
    };
    if (!request.dot_proper_shipping_name) {
      request.dot_proper_shipping_name = 'Could Not Find';
    }
    if (!request.iata_proper_shipping_name) {
      request.iata_proper_shipping_name = 'Could Not Find';
    }
    if (!request.imdg_proper_shipping_name) {
      request.imdg_proper_shipping_name = 'Could Not Find';
    }
    return request;
  }

  public createHazard(form: FormGroup) {
    const f = form.controls;
    let dotHazard;
    let iataHazard;
    let imdgHazard;
    let dotSubHazard;
    let iataSubHazard;
    let imdgSubHazard;
    let dotCompatibility;
    let iataCompatibility;
    let imdgCompatibility;
    let dotPackingGroup;
    let iataPackingGroup;
    let imdgPackingGroup;

    const defaultNotFoundText = 'Could Not Find';

    dotHazard = this.checkUnValueExists(f, 'dot_hazard_division')
      ? f.dot_hazard_division.value
      : f.dot_hazard_class.value;
    iataHazard = this.checkUnValueExists(f, 'iata_hazard_division')
      ? f.iata_hazard_division.value
      : f.iata_hazard_class.value;
    imdgHazard = this.checkUnValueExists(f, 'imdg_hazard_division')
      ? f.imdg_hazard_division.value
      : f.imdg_hazard_class.value;

    dotSubHazard = this.checkUnValueExists(f, 'dot_subhazard_division')
      ? f.dot_subhazard_division.value
      : f.dot_subhazard_class.value;
    iataSubHazard = this.checkUnValueExists(f, 'iata_subhazard_division')
      ? f.iata_subhazard_division.value
      : f.iata_subhazard_class.value;
    imdgSubHazard = this.checkUnValueExists(f, 'imdg_subhazard_division')
      ? f.imdg_subhazard_division.value
      : f.imdg_subhazard_class.value;

    dotCompatibility = this.checkUnValueExists(f, 'dot_hazard_compatibility')
      ? f.dot_hazard_compatibility.value
      : defaultNotFoundText;
    iataCompatibility = this.checkUnValueExists(f, 'iata_hazard_compatibility')
      ? f.iata_hazard_compatibility.value
      : defaultNotFoundText;
    imdgCompatibility = this.checkUnValueExists(f, 'imdg_hazard_compatibility')
      ? f.imdg_hazard_compatibility.value
      : defaultNotFoundText;

    dotPackingGroup = this.checkUnValueExists(f, 'dot_hazard_packing_group')
      ? f.dot_hazard_packing_group.value
      : defaultNotFoundText;
    iataPackingGroup = this.checkUnValueExists(f, 'iata_hazard_packing_group')
      ? f.iata_hazard_packing_group.value
      : defaultNotFoundText;
    imdgPackingGroup = this.checkUnValueExists(f, 'imdg_hazard_packing_group')
      ? f.imdg_hazard_packing_group.value
      : defaultNotFoundText;

    const request: any = {
      dot: {
        hazard: dotHazard,
        sub_hazard: dotSubHazard,
        compatibility: dotCompatibility,
        packing_group: dotPackingGroup
      },
      iata: {
        hazard: iataHazard,
        sub_hazard: iataSubHazard,
        compatibility: iataCompatibility,
        packing_group: iataPackingGroup
      },
      imdg: {
        hazard: imdgHazard,
        sub_hazard: imdgSubHazard,
        compatibility: imdgCompatibility,
        packing_group: imdgPackingGroup
      }
    };
    return request;
  }
}
