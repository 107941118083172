import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TaskService } from "src/app/shared/services/task/task.service";
import { DomSanitizer } from "@angular/platform-browser";
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  AbstractControl
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { BaseComponent } from "../base/base.component";
import { RequestService } from "src/app/shared/services/request/request.service";
import { ExpertService } from 'src/app/shared/services/expert/expert.service';

@Component({
  selector: "app-ph",
  templateUrl: "./ph.component.html",
  styleUrls: ["./ph.component.less"]
})
export class PhComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  showSingle = false;
  showRange = false;
  symbols = [];
  symbols_min = [];
  symbols_max = [];
  treeData = [];

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public activeRoute: ActivatedRoute,
    private expertService: ExpertService
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
    this.form = this.formBuilder.group({
      format: ["", Validators.required],
      symbol: [null],
      ph_value: [null],
      ph_min_value: [null],
      symbol_min: [null],
      ph_max_value: [null],
      symbol_max: [null]
    });
    
    if(this.expertClassificationMode){
      this.expertService.getExpertData$.subscribe((data: any) => {
        if (data) {
        this.treeData.push(data);
        this.loadInfo(data.pH);
        }
      })
    }
    }
    
  

  ngOnInit() {
    this.symbols = this.requestService.symbols;
    this.symbols_min = this.requestService.symbols_min;
    this.symbols_max = this.requestService.symbols_max;
  }

  changeFormat() {
    this.showSingle = false;
    this.showRange = false;
    this.f.ph_value.setValidators(null);
    this.f.ph_min_value.setValidators(null);
    this.f.ph_max_value.setValidators(null);
    this.f.symbol_min.setValidators(null);
    this.f.symbol_max.setValidators(null);
    this.f.symbol.setValidators(null);

    switch (this.f.format.value) {
      case 'specific_value':
        this.showSingle = true;
        this.f.ph_value.setValidators([
          Validators.required,
          Validators.min(0),
          Validators.max(14)
        ]);
        this.f.symbol.setValidators(Validators.required);
        break;
      case 'specific_range':
        this.showRange = true;
        this.f.ph_min_value.setValidators([
          Validators.required,
          Validators.min(0),
          Validators.max(14),
          this.minValidate()
        ]);
        this.f.symbol_min.setValidators(Validators.required);
        this.f.symbol_max.setValidators(Validators.required);
        this.f.ph_max_value.setValidators([
          Validators.required,
          Validators.min(0),
          Validators.max(14),
          this.minValidate()
        ]);
        break;
    }
    this.f.ph_value.updateValueAndValidity();
    this.f.ph_min_value.updateValueAndValidity();
    this.f.ph_max_value.updateValueAndValidity();
    this.f.symbol_max.updateValueAndValidity();
    this.f.symbol_min.updateValueAndValidity();
    this.f.symbol.updateValueAndValidity();
  }

  loadInfo(data){
    this.f.format.setValue(data.format_input);
    this.changeFormat();
    switch (this.f.format.value) {
      case 'specific_value':
        this.showSingle = true;
        this.f.ph_value.setValue(data.ph_value);
        this.f.symbol.setValue(data.symbol);
        break;
        
      case 'specific_range':
        this.showRange = true;
        this.f.ph_min_value.setValue(data.ph_min_value);
        this.f.ph_max_value.setValue(data.ph_max_value);
        this.f.symbol_min.setValue(data.symbol_min);
        this.f.symbol_max.setValue(data.symbol_max);
        break;
    }
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      const request = this.requestService.createPh(this.form);
      this.post(request);
    }
  }

  discard(){
    this.expertService.exitComponent();
    this.router.navigate(['../transportation'], { relativeTo: this.route });
  }

  accept(){
    this.submitted = true;
    if (this.form.valid) {
      const request = this.requestService.createPh(this.form);
      // call Jonathans service
      this.router.navigate(['../'], { relativeTo: this.route });
      this.expertService.exitComponent();
      const data = { type: "pH", payload: request, targetId: this.treeData[0].id };
      this.expertService.accept(data);
    }
  }

  minValidate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!this.f.ph_min_value.value || !this.f.ph_max_value.value) {
        return { minValidate: true };
      }
      if (this.f.ph_min_value.value < this.f.ph_max_value.value) {
        this.f.ph_min_value.setErrors(null);
        this.f.ph_max_value.setErrors(null);
        return null;
      }
      this.toastr.error('Min value must be less than max value');
      return { minValidate: true };
    };
  }

  get f() {
    return this.form.controls;
  }
}
