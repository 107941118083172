import { Component, OnInit, ElementRef, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RequestService } from 'src/app/shared/services/request/request.service';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';

@Component({
  selector: 'app-waste',
  templateUrl: './waste.component.html',
  styleUrls: ['./waste.component.less']
})

export class WasteComponent implements OnInit {
  @ViewChildren('waste') div:ElementRef;
  form: FormGroup;
  CT_items = [];
  MD_items = [];
  miCodes = [];
  MI_items = [];
  MN_items = [];
  MO_items = [];
  NH_items = [];
  NJ_items = [];
  NY_items = [];
  VT_items = [];
  MA_items = [];
  ME_items = [];
  RI_items = [];
  PA_items = [];
  OR_items = [];
  treeData = [];
  native_controls = ['ct', 'md', 'mi', 'mn', 'mo', 'nh', 'nj', 'ny', 'vt', 'ma', 'me', 'or', 'pa', 'ri'];
  multi_controls = ['vt', 'ma', 'me', 'ri', 'or'];
  onInit: any;
  orCodes = [];
  allCodes = {};

  get f() {
    return this.form.controls;
  }

  constructor(
    public requestService: RequestService,
    private formBuilder: FormBuilder,
    private expertService: ExpertService
  ) {
    this.form = this.formBuilder.group({
      ct: [null, Validators.required],
      md: [null, Validators.required],
      mi: [null, Validators.required],
      mn: [null, Validators.required],
      mo: [null, Validators.required],
      nh: [null, Validators.required],
      nj: [null, Validators.required],
      ny: [null, Validators.required],
      vt: ['', Validators.required],
      ma: ['', Validators.required],
      me: ['', Validators.required],
      or: ['', Validators.required],
      pa: ['', Validators.required],
      ri: ['', Validators.required]
    });

    this.expertService.getExpertData$.subscribe((data: any) => {
      if (data) {
        let filteredData = this.filterInvalidCodes(data.waste)
        this.treeData = [];
        this.onInit = true;
        if (!this.isEmpty(filteredData)) {
          data["waste"] = filteredData;
        }
        this.treeData.push(data);
        this.native_controls.forEach(c => this.f[c].setValue(data["waste"][c]));
        this.multi_controls.forEach(c => this.multiple_change(c));
        this.onInit = false;
      }
    });
  }

  disableScroll(){
    let waste_div = this.div['first'].nativeElement
    var x=waste_div.scrollLeft;
    var y=waste_div.scrollTop;
    waste_div.onscroll=function(){waste_div.scrollTo(x, y);};
  }

  enableScroll(){
    let waste_div = this.div['first'].nativeElement
    waste_div.onscroll=function(){};
  }

  ngOnInit() {
    this.allCodes = {
      njCodes: ['None', 'Unknown', 'ID27A'],
      vtCodes: ['None', 'Unknown', 'VT01', 'VT02', 'VT06', 'VT08', 'VT11', 'VT20'],
      riCodes: ['None', 'Unknown', 'R006', 'R007', 'R009', 'R010'],
      maCodes: ['None', 'Unknown', 'MA00', 'MA01', 'MA95'],
      meCodes: ['M002', 'M003', 'MRD001', 'MRD002', 'MRD003', 'MRD006', 'MRD007', 'MRD008', 'MRD009', 'MRD011'].concat(this.requestService.p_codes.map(p => p.code).concat('None').concat('Unknown')),
      paCodes: ['None', 'Unknown', '302', '204', '210', '399', '405', '703'],
      nyCodes: ['None', 'Unknown', 'B005'],
      nhCodes: ['None', 'Unknown', 'NH01', 'NH02', 'NH03'],
      moCodes: ['None', 'Unknown', 'M011', 'MH02', 'D098'],
      mnCodes: ['None', 'Unknown', 'MN01', 'MN03'],
      mdCodes: ['None', 'Unknown', 'M001'],
      miCodes: ['None', 'Unknown', '029L', '001U', '002U', '003U', '004U', '005U', '006U', '007U', '008U',
      '009U', '011U', '012U', '014U', '020U', '022U', '023U', '027U', '029U', '032U', '033U',
      '034U', '036U', '037U', '038U', '040U', '042U', '043U', '044U', '046U', '049U', '050U',
      '051U', '052U', '054U', '055U', '056U', '057U', '058U', '059U', '061U', '063U', '064U',
      '065U', '068U', '070U', '071U', '073U', '074U', '075U', '076U', '078U', '079U', '082U',
      '083U', '086U', '088U', '092U', '093U', '094U', '095U', '097U', '098U', '099U', '100U',
      '101U', '102U', '103U', '104U', '106U', '108U', '110U', '111U', '112U', '113U', '115U',
      '116U', '117U', '118U', '119U', '120U', '121U', '124U', '127U', '128U', '129U', '131U',
      '132U', '136U', '137U', '138U', '141U', '142U', '143U', '147U', '148U', '150U', '151U',
      '152U', '154U', '157U', '158U', '159U', '160U', '161U', '162U', '163U', '164U', '165U',
      '166U', '167U', '169U', '170U', '171U', '172U', '173U', '175U'],
      ctCodes: ['None', 'Unknown', 'CR01', 'CR02', 'CR04', 'CR05'],
      orCodes: ['None', 'Unknown', 'ORP001', 'ORP002', 'ORP003', 'ORP004', 'ORP005', 'ORP006', 'ORP007', 'ORP008', 'ORP009', 'ORP010',
      'ORP011', 'ORP012', 'ORP013', 'ORP014', 'ORP015', 'ORP016', 'ORP017', 'ORP018', 'ORP020', 'ORP021', 'ORP022', 'ORP023',
      'ORP024', 'ORP026', 'ORP027', 'ORP028', 'ORP029', 'ORP030', 'ORP031', 'ORP033', 'ORP034', 'ORP036', 'ORP037', 'ORP038',
      'ORP039', 'ORP040', 'ORP041', 'ORP042', 'ORP043', 'ORP044', 'ORP045', 'ORP046', 'ORP047', 'ORP048', 'ORP049', 'ORP050',
      'ORP051', 'ORP054', 'ORP056', 'ORP057', 'ORP058', 'ORP059', 'ORP060', 'ORP062', 'ORP063', 'ORP064', 'ORP065', 'ORP066',
      'ORP067', 'ORP068', 'ORP069', 'ORP070', 'ORP071', 'ORP072', 'ORP073', 'ORP074', 'ORP075', 'ORP076', 'ORP077', 'ORP078',
      'ORP081', 'ORP082', 'ORP084', 'ORP085', 'ORP087', 'ORP088', 'ORP089', 'ORP092', 'ORP093', 'ORP094', 'ORP095', 'ORP096',
      'ORP097', 'ORP098', 'ORP099', 'ORP101', 'ORP102', 'ORP103', 'ORP104', 'ORP105', 'ORP106', 'ORP108', 'ORP109', 'ORP110',
      'ORP111', 'ORP112', 'ORP113', 'ORP114', 'ORP115', 'ORP116', 'ORP118', 'ORP119', 'ORP120', 'ORP121', 'ORP123', 'ORP127',
      'ORP128', 'ORP185', 'ORP188', 'ORP189', 'ORP190', 'ORP191', 'ORP192', 'ORP194', 'ORP196', 'ORP197', 'ORP198', 'ORP199',
      'ORP201', 'ORP202', 'ORP203', 'ORP204', 'ORP205', 'ORU001', 'ORU002', 'ORU003', 'ORU004', 'ORU005', 'ORU006', 'ORU007',
      'ORU008', 'ORU009', 'ORU010', 'ORU011', 'ORU012', 'ORU014', 'ORU015', 'ORU016', 'ORU017', 'ORU018', 'ORU019', 'ORU020',
      'ORU021', 'ORU022', 'ORU023', 'ORU024', 'ORU025', 'ORU026', 'ORU027', 'ORU028', 'ORU029', 'ORU030', 'ORU031', 'ORU032',
      'ORU033', 'ORU034', 'ORU035', 'ORU036', 'ORU037', 'ORU038', 'ORU039', 'ORU041', 'ORU042', 'ORU043', 'ORU044', 'ORU045',
      'ORU046', 'ORU047', 'ORU048', 'ORU049', 'ORU050', 'ORU051', 'ORU052', 'ORU053', 'ORU055', 'ORU056', 'ORU057', 'ORU058',
      'ORU059', 'ORU060', 'ORU061', 'ORU062', 'ORU063', 'ORU064', 'ORU066', 'ORU067', 'ORU068', 'ORU069', 'ORU070', 'ORU071',
      'ORU072', 'ORU073', 'ORU074', 'ORU075', 'ORU076', 'ORU077', 'ORU078', 'ORU079', 'ORU080', 'ORU081', 'ORU082', 'ORU083',
      'ORU084', 'ORU085', 'ORU086', 'ORU087', 'ORU088', 'ORU089', 'ORU090', 'ORU091', 'ORU092', 'ORU093', 'ORU094', 'ORU095',
      'ORU096', 'ORU097', 'ORU098', 'ORU099', 'ORU101', 'ORU102', 'ORU103', 'ORU105', 'ORU106', 'ORU107', 'ORU108', 'ORU109',
      'ORU110', 'ORU111', 'ORU112', 'ORU113', 'ORU114', 'ORU115', 'ORU116', 'ORU117', 'ORU118', 'ORU119', 'ORU120', 'ORU121',
      'ORU122', 'ORU123', 'ORU124', 'ORU125', 'ORU126', 'ORU127', 'ORU128', 'ORU129', 'ORU130', 'ORU131', 'ORU132', 'ORU133',
      'ORU134', 'ORU135', 'ORU136', 'ORU137', 'ORU138', 'ORU140', 'ORU141', 'ORU142', 'ORU143', 'ORU144', 'ORU145', 'ORU146',
      'ORU147', 'ORU148', 'ORU149', 'ORU150', 'ORU151', 'ORU152', 'ORU153', 'ORU154', 'ORU155', 'ORU156', 'ORU157', 'ORU158',
      'ORU159', 'ORU160', 'ORU161', 'ORU162', 'ORU163', 'ORU164', 'ORU165', 'ORU166', 'ORU167', 'ORU168', 'ORU169', 'ORU170',
      'ORU171', 'ORU172', 'ORU173', 'ORU174', 'ORU176', 'ORU177', 'ORU178', 'ORU179', 'ORU180', 'ORU181', 'ORU182', 'ORU183',
      'ORU184', 'ORU185', 'ORU186', 'ORU187', 'ORU188', 'ORU189', 'ORU190', 'ORU191', 'ORU192', 'ORU193', 'ORU194', 'ORU196',
      'ORU197', 'ORU200', 'ORU201', 'ORU202', 'ORU203', 'ORU204', 'ORU205', 'ORU206', 'ORU207', 'ORU208', 'ORU209', 'ORU210',
      'ORU211', 'ORU213', 'ORU214', 'ORU215', 'ORU216', 'ORU217', 'ORU218', 'ORU219', 'ORU220', 'ORU221', 'ORU222', 'ORU223',
      'ORU225', 'ORU226', 'ORU227', 'ORU228', 'ORU234', 'ORU235', 'ORU236', 'ORU237', 'ORU238', 'ORU239', 'ORU240', 'ORU243',
      'ORU244', 'ORU246', 'ORU247', 'ORU248', 'ORU249', 'ORU271', 'ORU278', 'ORU279', 'ORU280', 'ORU328', 'ORU353', 'ORU359',
      'ORU364', 'ORU367', 'ORU372', 'ORU373', 'ORU387', 'ORU389', 'ORU394', 'ORU395', 'ORU404', 'ORU409', 'ORU410', 'ORU411'
      ]
    }
    this.PA_items = this.allCodes["paCodes"];
    this.NY_items = this.allCodes["nyCodes"];
    this.NH_items = this.allCodes["nhCodes"];
    this.MO_items = this.allCodes["moCodes"];
    this.MN_items = this.allCodes["mnCodes"];
    this.MD_items = this.allCodes["mdCodes"];
    this.CT_items = this.allCodes["ctCodes"];
    this.MI_items = this.allCodes["miCodes"];
    this.NJ_items = this.allCodes["njCodes"];
    this.MA_items = this.allCodes["maCodes"];
    this.ME_items = this.allCodes["meCodes"];
    this.OR_items = this.allCodes["orCodes"];
    this.RI_items = this.allCodes["riCodes"];
    this.VT_items = this.allCodes["vtCodes"];
  }

  isEmpty(obj) {
    for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }
    return true;
}

  filterInvalidCodes(codes) {
    let obj = {}
    for (const key in codes) {
      if (this.allCodes[key]) {
        for (const subKey in this.allCodes) {
          if (codes[key] && codes[key].length > 0) {
            if (subKey.startsWith(key)) {
              if (Array.isArray(codes[key])) {
                obj[key] = this.allCodes[subKey].filter((val, index) => codes[key].includes(this.allCodes[subKey][index]));
              } else {
                obj[key] = this.allCodes[subKey].filter((val, index) => codes[key].includes(this.allCodes[subKey][index])).toString();
              }
            }
          }
        }
      } else {
        obj[key] = codes[key]
      }
    }
    return obj;
  }

  single_change(state) {
    const data = {
      type: "waste",
      payload: { ...this.treeData[0].waste, [state]: this.f[state].value, [`${state}_codes`]: this.allCodes[`${state}Codes`] },
      targetId: this.treeData[0].id
    };
    this.treeData[0].waste = data.payload;
    this.expertService.accept(data);
  }

  multiple_change(state) {
    if ((this.f[state].value || '').includes('None')) {
      this.form.controls[state].setValue(['None']);
    } else if ((this.f[state].value || '').includes('Unknown')) {
      this.form.controls[state].setValue(['Unknown']);
    }

    switch (state) {
      case 'vt':
        if ((this.f.vt.value || '').includes('None')) {
          this.VT_items = ['None'];
        } else if ((this.f.vt.value || '').includes('Unknown')) {
          this.VT_items = ['Unknown'];
        } else {
          this.VT_items = this.allCodes["vtCodes"];
        }
        break;

      case 'ma':
        if ((this.f.ma.value || '').includes('None')) {
          this.MA_items = ['None'];
        } else if ((this.f.ma.value || '').includes('Unknown')) {
          this.MA_items = ['Unknown'];
        } else {
          this.MA_items = this.allCodes["maCodes"];
        }
        break;

      case 'me':
        if ((this.f.me.value || '').includes('None')) {
          this.ME_items = ['None'];
        } else if ((this.f.me.value || '').includes('Unknown')) {
          this.ME_items = ['Unknown'];
        } else {
          this.ME_items = this.allCodes["meCodes"];
        }
        break;

      case 'ri':
        if ((this.f.ri.value || '').includes('None')) {
          this.RI_items = ['None'];
        } else if ((this.f.ri.value || '').includes('Unknown')) {
          this.RI_items = ['Unknown'];
        } else {
          this.RI_items = this.allCodes["riCodes"];
        }
        break;

      case 'or':
        if ((this.f.or.value || '').includes('None')) {
          this.OR_items = ['None'];
        } else if ((this.f.or.value || '').includes('Unknown')) {
          this.OR_items = ['Unknown'];
        } else {
          this.OR_items = this.allCodes["orCodes"];
        }
        break;
    }

    if (!this.onInit) {
      const data = {
        type: "waste",
        payload: { ...this.treeData[0].waste, [state]: this.f[state].value, [`${state}_codes`]: this.allCodes[`${state}Codes`] },
        targetId: this.treeData[0].id
      };
      this.expertService.accept(data);
    }
  }

  private accept(data) {
    this.expertService.accept(data);
  }
}
