import { Component, OnInit, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import { ExpertService } from "./../../../../shared/services/expert/expert.service";
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { debug } from 'util';

@Component({
  selector: 'app-expert-classification-container',
  templateUrl: './expert-classification-container.component.html',
  styleUrls: ['./expert-classification-container.component.less'],
})
export class ExpertClassificationContainerComponent implements OnInit {
  data:any;
  apiCalled = false
  @Input() themeVar: string;
  modifiedData = [];
  subscription: Subscription;
  id = 0;

  constructor(public route: ActivatedRoute, public expertService: ExpertService, public router: Router,) {
    this.data = ''
    this.id = this.route.snapshot.params.id;
    this.expertService.fetchData(this.id)
  }

  ngOnInit() {
    this.data = ''
    this.subscription = this.expertService.getModifiedData$.subscribe(data => {
      if (data) {
        this.changeDataHandler(data);
      }
    })
  }

  changeDataHandler(modifiedData: any) {
    this.data = this.expertService.getMainState
    if (modifiedData) {
      if (this.data.id === modifiedData.targetId) {
        this.data = { ...this.data, [modifiedData.type]: modifiedData.payload };
        this.sendDataToAll(this.data);
      } else {
        this.data["children"].map((child, index) => {
          if (child.id === modifiedData.targetId) {
            this.data.children[index] = { ...child, [modifiedData.type]: modifiedData.payload }
            this.sendDataToAll(this.data.children[index]);
          } else {
            if (child.children && child.children.length >= 1) {
              child["children"].map((val, subIndex) => {
                if (val.id === modifiedData.targetId) {
                  this.data.children[index].children[subIndex] = { ...val, [modifiedData.type]: modifiedData.payload }
                  this.sendDataToAll(this.data.children[index].children[subIndex]);
                }
              })
            }

          }
        })
      }
      this.expertService.setStateForm = { request: this.data, expert_data: {task_id: this.id} };
    }

  }

  sendDataToAll(data) {
    this.expertService.setSelectedTreeItem = data.id
    this.expertService.sendData(data)
    this.expertService.sendTreeData(this.data)
  }

}
