import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators, Form } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';

@Component({
  selector: 'app-il',
  templateUrl: './il.component.html',
  styleUrls: ['./il.component.less']
})
export class IlComponent implements OnInit {
  @Output() handleOpen: EventEmitter<any> = new EventEmitter();
  @Output() handleClose: EventEmitter<any> = new EventEmitter();

  treeData = [];
  public ilForm: FormGroup;
  public ilCodes: any;
  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public activeRoute: ActivatedRoute,
    private expertService: ExpertService
  ) { }

  ngOnInit() {
    this.ilForm = this.formBuilder.group({
      waste_code: ['', Validators.required],
      class_code: ['', Validators.required]
    });
    this.expertService.getExpertData$.subscribe((data: any) => {
      if (data) {
        this.treeData = [];
        this.treeData.push(data);
        if(data.waste.il==null){
          data.waste.il = {};
        }
        this.f.waste_code.setValue(data.waste.il.waste_code);
        this.f.class_code.setValue(data.waste.il.class_code);
      }

    });
    this.ilCodes = {
      waste_codes: ['None', 'Unknown', 'IL14', 'IL15', 'IL16', 'IL17', 'IL18', 'IL19', 'IL20'],
      class_codes: ['None', 'Unknown', 'CLASS_A', 'CLASS_B']
    };
  }

  change() {
    const data = { 
      type: "waste", 
      payload: {...this.treeData[0].waste, 
        il: { 
          waste_code: this.f.waste_code.value,
          class_code: this.f.class_code.value
        },
        il_codes: this.ilCodes
      }, 
      targetId: this.treeData[0].id 
    };
    this.expertService.accept(data);
  }

  onClose(){
    this.handleClose.emit(null);
  }

  onOpen(){
    this.handleOpen.emit(null);
  }

  get f(){
    return this.ilForm.controls;
  }
}
