import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ListTaskComponent } from './task/list-task/list-task.component';
import { WaComponent } from './task/forms/wa/wa.component';
import { RcraComponent } from './task/forms/rcra/rcra.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoginComponent } from './user/components/login/login.component';
import { PrivateComponent } from './private/private.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ToastrModule } from 'ngx-toastr';
import { BlockUIModule } from 'ng-block-ui';
import { SharedModule } from './shared/shared.module';
import { FlashPointComponent } from './task/forms/flash-point/flash-point.component';
import { BoilingPointComponent } from './task/forms/boiling-point/boiling-point.component';
import { PhComponent } from './task/forms/ph/ph.component';
import { CasNumberAndWeightsComponent } from './task/forms/cas-number-and-weights/cas-number-and-weights.component';
import { FormComponent } from './task/forms/form/form.component';
import { BaseComponent } from './task/forms/base/base.component';
import { AngularTokenModule } from 'angular-token';
import { environment } from 'src/environments/environment';
import { CaComponent } from './task/forms/ca/ca.component';
import { TxComponent } from './task/forms/tx/tx.component';
import { NoContentComponent } from './no-content/no-content.component';
import { ReportComponent } from './report/report.component';
import { AgentReportComponent } from './report/components/agent/agent-report/agent-report.component';
import { ListGroupComponent } from './group/list-group/list-group.component';
import { ResetPasswordComponent } from './user/components/reset-password/reset-password.component';
import { ChangePasswordComponent } from './user/components/change-password/change-password.component';
import { IntendedUseComponent } from './task/forms/intended-use/intended-use.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FindProductInfoComponent } from './task/forms/find-product-info/find-product-info.component';
import { SupervisorReportComponent } from './report/components/supervisor/supervisor-report/supervisor-report.component';
import { SupervisorReportByTaskComponent } from './report/components/supervisor/supervisor-report-by-task/supervisor-report-by-task.component';
import { SdsFindInfoComponent } from './task/forms/sds-find-info/sds-find-info.component';
import { ValidateSdsPairingComponent } from './task/forms/validate-sds-pairing/validate-sds-pairing.component';
import { MeltingPointComponent } from './task/forms/melting-point/melting-point.component';
import { PhysicalPointComponent } from './shared/components/physical-point/physical-point.component';
import { TabBarComponent } from './task/forms/expert-classification/tab-bar/tab-bar.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { UnNumberComponent } from './task/forms/un-number/un-number.component';
import { UnDropDownComponent } from './task/un-drop-down/un-drop-down.component';
import { HazardDropDownComponent } from './task/hazard-drop-down/hazard-drop-down.component';
import { HazardComponent } from './task/forms/hazard/hazard.component';
import { AgentReportAccuracyByTaskComponent } from './report/components/agent/agent-report-accuracy-by-task/agent-report-accuracy-by-task.component';
import { SupervisorReportAccuracyByTaskComponent } from './report/components/supervisor/supervisor-report-accuracy-by-task/supervisor-report-accuracy-by-task.component';
import { ProductContainerComponent } from './task/forms/expert-classification/product-container/product-container.component';
import { TreeComponent } from './task/forms/expert-classification/tree/tree.component';
import { ProductInfoComponent } from './task/forms/expert-classification/product-info/product-info.component';
import { ExpertClassificationContainerComponent } from './task/forms/expert-classification/expert-classification-container/expert-classification-container.component';
import { RightPanelComponent } from './task/forms/expert-classification/right-panel/right-panel.component';
import { IshipComponent } from './task/forms/iship/iship.component';
import { ReplacePipe } from "./shared/replacePipe";
import { PackagingInfoComponent } from './task/forms/expert-classification/packaging-info/packaging-info.component';
import { TransportationComponent } from './task/forms/expert-classification/transportation/transportation.component';
import { WasteComponent } from './task/forms/expert-classification/waste/waste.component';
import { IlComponent } from './task/forms/il/il.component';

@NgModule({
  declarations: [
    AppComponent,
    ListTaskComponent,
    WaComponent,
    RcraComponent,
    LoginComponent,
    PrivateComponent,
    FlashPointComponent,
    BoilingPointComponent,
    PhComponent,
    CasNumberAndWeightsComponent,
    FormComponent,
    BaseComponent,
    CaComponent,
    TxComponent,
    NoContentComponent,
    ReportComponent,
    AgentReportComponent,
    ListGroupComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    IntendedUseComponent,
    UnNumberComponent,
    UnDropDownComponent,
    FindProductInfoComponent,
    SupervisorReportComponent,
    SupervisorReportByTaskComponent,
    SdsFindInfoComponent,
    ValidateSdsPairingComponent,
    SdsFindInfoComponent,
    MeltingPointComponent,
    PhysicalPointComponent,
    IshipComponent,
    HazardComponent,
    HazardDropDownComponent,
    AgentReportAccuracyByTaskComponent,
    SupervisorReportAccuracyByTaskComponent,
    ProductContainerComponent,
    TreeComponent,
    ProductInfoComponent,
    ExpertClassificationContainerComponent,
    RightPanelComponent,
    TabBarComponent,
    ReplacePipe,
    PackagingInfoComponent,
    TransportationComponent,
    WasteComponent,
    IlComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    PaginationModule.forRoot(),
    BlockUIModule.forRoot(),
    SharedModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      positionClass: 'toast-top-full-width'
    }),
    HttpClientModule,
    AngularTokenModule.forRoot({
      apiBase: environment.apiUrl,
      resetPasswordCallback: `${window.location.origin}/change-password`
    }),
    BsDatepickerModule.forRoot()
  ],
  providers: [AngularTokenModule],
  bootstrap: [AppComponent]
})
export class AppModule {}
