import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {
  msg = '';

  private validSelects = ['not_regulated', 'unknown'];

  constructor(public toastr: ToastrService) {
    this.toastr.toastrConfig.autoDismiss = false;
    this.toastr.toastrConfig.disableTimeOut = true;
  }

  validate(data): boolean {
    this.msg = '';
    if (!this.internalValidate(data)) {
      this.toastr.error(this.msg, `Please review Transportation & Waste required fields`, { enableHtml: true });
      return false;
    }
    return true; // TODO: remove that return.
  }

  internalValidate(data): boolean {
    let result = this.validateTransportation(data.transportation, data.product_name, data.upc);
    result = result && this.validateWaste(data.waste, data.product_name, data.upc);
    if (data.children) {
      data.children.forEach(child => {
        const child_result = this.internalValidate(child);
        result = result && child_result;
      });
    }
    return result;
  }

  private validateTransportation(data, product_name, upc) {
    const errors = {
      core: [],
      ground: [],
      air: [],
      sea: []
    };
    const result = [
      this.validateTransformationItem(data.core, product_name, errors.core),
      this.validateTransformationItem(data.ground, product_name, errors.ground),
      this.validateTransformationItem(data.air, product_name, errors.air),
      this.validateTransformationItem(data.sea, product_name, errors.sea)
    ];

    if (result.includes(false)) {
      this.msg += `- ${product_name}  ${upc} transportation has invalid fields<br>`;
      this.msg += errors.core.length > 0 ? `Please review the following fields in core ${errors.core.join(', ')}<br>` : '';
      this.msg += errors.ground.length > 0 ? `Please review the following fields in ground - ${errors.ground.join(', ')}<br>` : '';
      this.msg += errors.air.length > 0 ? `Please review the following fields in air - ${errors.air.join(', ')}<br>` : '';
      this.msg += errors.sea.length > 0 ? `Please review the following fields in sea - ${errors.sea.join(', ')}<br>` : '';
      this.msg += `<br>`;
      return false;
    }
    return true;
  }

  private validateTransformationItem(data, product_name, errors) {
    let result = true;
    if (!this.validateTransportationField('un_number', 'select_un_number', data)) {
      errors.push('UN Number');
      result = false;
    }
    if (!this.validateTransportationField('proper_shipping_name', 'select_proper_shipping_name', data)) {
      errors.push('Proper Shipping Name');
      result = false;
    }
    if (!this.validateTransportationField('technical_name', 'select_technical_name', data)) {
      errors.push('Technical Name');
      result = false;
    }
    if (!this.validateTransportationField('hazard_class_division', 'hazard_class_division', data)) {
      errors.push('Hazard Class/ivision');
      result = false;
    }
    if (!this.validateTransportationField('compatibility_group', 'compatibility_group', data)) {
      errors.push('Compatibility Group');
      result = false;
    }
    if (!this.validateTransportationField('subrisk', 'subrisk', data)) {
      errors.push('Subrisk');
      result = false;
    }
    if (!this.validateTransportationField('packing_group', 'packing_group', data)) {
      errors.push('Packaging Group');
      result = false;
    }
    if (!this.validateTransportationField('marine_pollutant', 'marine_pollutant', data)) {
      errors.push('Marine Pollutant');
      result = false;
    }
    if (!this.validateTransportationField('limited_quantity', 'limited_quantity', data)) {
      errors.push('Limited Quantity');
      result = false;
    }
    if (!this.validateTransportationField('excepted_quantity', 'excepted_quantity', data)) {
      errors.push('Excepted Quantity');
      result = false;
    }
    if (!this.validateTransportationField('reportable_quantity', 'reportable_quantity', data)) {
      errors.push('Reportable Quantity');
      result = false;
    }
    if ('transportation_exception' in data &&
      !this.validateTransportationField('transportation_exception', 'transportation_exception', data)) {
      errors.push('Transportation Exception');
      result = false;
    }
    return result;
  }

  private validateWaste(data, product_name, upc): boolean {
    const errors = [];
    const result = [
      this.validateField(data.ca.cacode, 'California codes', errors, 'CA'),
      this.validateField(data.ca.hazardous, 'CA hazardous', errors, 'CA'),
      this.validateField(data.ct, 'CT', errors, 'CT'),
      this.validateField(data.il, 'IL', errors, 'IL'),
      this.validateField(data.ma, 'MA', errors, 'MA'),
      this.validateField(data.md, 'MD', errors, 'MD'),
      this.validateField(data.me, 'ME', errors, 'ME'),
      this.validateField(data.mi, 'MN', errors, 'MN'),
      this.validateField(data.mo, 'MO', errors, 'MO'),
      this.validateField(data.nh, 'NH', errors, 'NH'),
      this.validateField(data.nj, 'NJ', errors, 'NJ'),
      this.validateField(data.ny, 'NY', errors, 'NY'),
      this.validateField(data.or, 'OR', errors, 'OR'),
      this.validateField(data.p_codes, 'P Code', errors, 'RCRA'),
      this.validateField(data.ri, 'RI', errors, 'RI'),
      this.validateField(data.tx.code, 'Form codes', errors, 'TX'),
      this.validateField(data.tx.class_code, 'Class codes', errors, 'TX'),
      this.validateField(data.u_codes, 'U Codde', errors, 'RCRA'),
      this.validateField(data.uwaste_code, 'Universal Waste', errors, 'RCRA'),
      this.validateField(data.vt, 'VT', errors, 'VT'),
      this.validateField(data.wa.ws, 'WT codes', errors, 'WT'),
      this.validateField(data.wa.wp, 'WP codes', errors, 'WP'),
      this.validateField(data.wa.wt, 'WS codes', errors, 'WS'),
      this.validateField(data.d_characteristic_codes, 'D Characteristic', errors, 'RCRA'),
      this.validateField(data.d_characteristic_toxics_codes, 'D Characteristic - Toxics', errors, 'RCRA')
    ];
    if (result.includes(false)) {
      this.msg += `- ${product_name} ${upc} waste has invalid fields<br>`;
      this.msg += errors.length > 0 ? `Please review the following: ${errors.map(e => ' ' + e.facet + ' - ' + e.name)}<br>` : '';
      this.msg += `<br>`;
      return false;
    }
    return true;
  }

  private validateField(value, name?, errors?, facet?) {
    if (value instanceof Array) {
      if (value.length === 0) {
        if (name && errors) {
          errors.push({facet, name});
        }
        return false;
      }
      return true;
    }
    if (!value) {
      if (name && errors) {
        errors.push({facet, name});
      }
      return false;
    }
    return true;
  }

  private validateTransportationField(fieldName, selectName, data) {
    if (this.validSelects.includes(data[fieldName])) {
      return true;
    }
    return this.validateField(data[fieldName]);
  }
}
