import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject }    from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: 'root'
})

export class ExpertService {

  constructor(private httpClient: HttpClient) {}

  private expertDataSource = new BehaviorSubject<any>(null);
  private modifiedData = new BehaviorSubject<any>(null);
  private treeData = new BehaviorSubject<any>(null);
  private treeItem = new BehaviorSubject<any>(null);
  private discard = new BehaviorSubject<any>(false);
  private selectedItem = new BehaviorSubject<any>(false);
  private physicalData = new BehaviorSubject<any>(null);

  // Observable data streams
  getExpertData$ = this.expertDataSource.asObservable();
  getModifiedData$ = this.modifiedData.asObservable();
  getTreeData$ = this.treeData.asObservable();
  getTreeItem$ = this.treeItem.asObservable();
  getDiscard$ = this.discard.asObservable();
  disableInteraction$ = this.selectedItem.asObservable();
  getPhysicalData$ = this.physicalData.asObservable();

  private _stateForm: any;
  private _selectedTreeElement = '';
  private _mainState: any;
  private _selectedComponent = '';
  lastId = 0;

  get getStateForm() {return this._stateForm; }
  set setStateForm(value) {this._stateForm = value; }

  get getSelectedTreeItem() {return this._selectedTreeElement; }
  set setSelectedTreeItem(value) {this._selectedTreeElement = value; }


  get getMainState() {return this._mainState; }
  set setMainState(value) {this._mainState = value; }

  get getSelectedComponent() {return this._selectedComponent; }
  set setSelectedComponent(value) {this._selectedComponent = value; }
  

  sendData(data: any) {
    this._stateForm =  { request: data, expert_data: {task_id: this.lastId } };;
    this.expertDataSource.next(data);
  }
  
  sendTreeData(data: any) {
    this.treeData.next(data);
  }

  sendSelectedTreeItem(data: any) {
    this.expertDataSource.next(data);
  }

  getData(): Observable<any> {
    return this.expertDataSource.asObservable();
  }

  sendPhysicalData(data: any){
    this.physicalData.next(data);
  }

  exitComponent(){
    this._selectedComponent = '';
    this.selectedItem.next(false)
    this.discard.next(!this.discard);
  }

  disableTree(state) {
    this.selectedItem.next(state)
  }
  
  accept(data: any) {
    this.modifiedData.next(data)
  }

  get(id: number) {
    return this.httpClient.get(`${environment.apiUrl}tasks/${id}`);
  }

  fetchData(id: number) {
    if (this.lastId !== id) {
      this.lastId = id
      this.get(id).subscribe(
        res => {
          let dataFromApi = ''
          let data = res["results"][0]["data"];
          if (typeof data !== "string") {
            dataFromApi = data
          } else {
            dataFromApi = JSON.parse(data)
          }
          this._mainState = dataFromApi
          this.sendData(dataFromApi)
          this.sendTreeData(dataFromApi)
        },
        error =>  console.error('error message', error)
      ) 
    }
  }
  
  save(id: number, data: any){
    this.lastId = 0
    return this.httpClient.post(
      `${environment.apiUrl}tasks/${id}/results`,
      data
    );
  }

  submit(id: number, data: any){
    return this.httpClient.post(
      `${environment.apiUrl}tasks/${id}/results`,
      data
    );
  }
}
