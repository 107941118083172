import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators, Form } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { RequestService } from 'src/app/shared/services/request/request.service';

@Component({
  selector: 'app-find-product-info',
  templateUrl: './find-product-info.component.html',
  styleUrls: ['./find-product-info.component.less']
})
export class FindProductInfoComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  showSds = false;
  showRevisionDate = false;
  showProductLabel = false;

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public activeRoute: ActivatedRoute
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      whatIsneeded: ['', [Validators.required]],
      sds_url: '',
      product_label_url: '',
      revision_date: ['', [Validators.required]]
    });
  }

  changeNeed() {
    this.hideAll();
    switch (this.f.whatIsneeded.value) {
      case 'sds_url':
        this.showSds = true;
        this.f.sds_url.setValidators([
          Validators.pattern(
            '^(http://www.|https://www.|http://|https://)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$'
          ),
          Validators.required
        ]);

        this.f.sds_url.updateValueAndValidity();
        break;
      case 'product_label_url':
        this.showProductLabel = true;
        this.f.product_label_url.setValidators([
          Validators.pattern(
            '^(http://www.|https://www.|http://|https://)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$'
          ),
          Validators.required
        ]);
        this.f.product_label_url.updateValueAndValidity();
        break;
      case 'none':
        this.showSds = false;
        this.showProductLabel = false;
        this.showRevisionDate = false;
        this.f.sds_url.setValidators(null);
        this.f.sds_url.updateValueAndValidity();
        this.f.product_label_url.setValidators(null);
        this.f.product_label_url.updateValueAndValidity();
        this.f.revision_date.setValidators(null);
        this.f.revision_date.updateValueAndValidity();
        break;
    }
  }

  revisionDate() {
    if (this.f.sds_url.valid) {
      this.showRevisionDate = true;
      this.f.revision_date.setValidators(Validators.required);
      this.f.revision_date.updateValueAndValidity();
    } else {
      this.showRevisionDate = false;
      this.f.revision_date.setValidators(null);
      this.f.revision_date.updateValueAndValidity();
    }
  }

  hideAll() {
    this.showRevisionDate = false;
    this.showSds = false;
    this.showProductLabel = false;
    this.f.sds_url.setValidators(null);
    this.f.sds_url.setValue(null);
    this.f.sds_url.updateValueAndValidity();
    this.f.revision_date.setValue(null);
    this.f.revision_date.setValidators(null);
    this.f.revision_date.updateValueAndValidity();
    this.f.product_label_url.setValidators(null);
    this.f.product_label_url.setValue('');
    this.f.product_label_url.updateValueAndValidity();
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      const request = this.requestService.createFindProduct(this.form);
      this.post(request);
    }
    if (this.f.sds_url.invalid || this.f.product_label_url.invalid) {
      this.toastr.error('Please insert a valid url');
    }
  }

  get f() {
    return this.form.controls;
  }
}
