import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators, Form } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { RequestService } from 'src/app/shared/services/request/request.service';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';

@Component({
  selector: 'app-ca',
  templateUrl: './ca.component.html',
  styleUrls: ['./ca.component.less']
})

export class CaComponent extends BaseComponent implements OnInit {
  @Input() themeVar: string;
  form: FormGroup;
  codes = [];
  treeData = [];
  hazardous_options = [];

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public activeRoute: ActivatedRoute,
    private expertService: ExpertService
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.codes = this.requestService.ca_state_codes;
    this.hazardous_options = [{ code: "Yes", desc: "Yes" }, { code: "No", desc: "No" }];
    if (this.expertClassificationMode) {
      let unk = [{ code: 'unknown', desc: 'Unknown' }];
      let none = [{ code: 'none', desc: 'None' }];
      this.codes = [...unk, ...this.requestService.ca_state_codes];
      this.hazardous_options = [...none, ...unk, ...this.hazardous_options];
    }
    this.form = this.formBuilder.group({
      cacode: ['', [Validators.required]],
      hazardous: ['', [Validators.required]]
    });
    this.f.cacode.setValue('');
    this.f.hazardous.setValue('');
    if (this.expertClassificationMode) {
      this.expertService.getExpertData$.subscribe((data: any) => {
        if (data) {
          this.treeData = [];
          this.treeData.push(data);
          let code = ''
          if (data.waste.ca == null) {
            data.waste.ca = {
              cacode: '',
              hazardous: ''
            };
          }
          if (data.waste.ca.cacode != 'none') {
            code = this.codes.find(code => code.code === data.waste.ca.cacode);
            if (code == undefined)
              code = ''
          } else
            code = data.waste.ca.cacode
          this.f.cacode.setValue(code);
          this.f.hazardous.setValue(data.waste.ca.hazardous);
        }
      });
    }
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      const request = { cacode: this.f['cacode'].value, hazardous: this.f['hazardous'].value, ca_codes: this.codes};
      this.post(request);
    }
  }

  onChange() {
    const data = {
      type: "waste",
      payload: {
        ...this.treeData[0].waste,
        ca: {
          cacode: this.f['cacode'].value === undefined ? '' : this.f['cacode'].value != 'none' ? this.f['cacode'].value.code : 'none',
          hazardous: this.f['hazardous'].value,
          ca_codes: this.codes
        }
      },
      targetId: this.treeData[0].id
    };
    this.expertService.accept(data);
  }

  get f() { return this.form.controls; }
}
