import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-un-drop-down',
  templateUrl: './un-drop-down.component.html',
  styleUrls: ['./un-drop-down.component.less']
})
export class UnDropDownComponent implements Validators {
  unValidationError = true;
  isUnNumber = false;
  isNaNumber = false;
  isIdNumber = false;
  // TODO: make it generic if needed in future by using object array
  public unOptions = ['Number', 'Not Regulated', 'Could Not Find'];
  unNumber: number = null;
  shippingName = '';
  shippingNameIsDisabled: boolean;

  @Input() label: string;
  @Input() formGrp: FormGroup;
  @Output() disableName = new EventEmitter();

  constructor() { }

  onUnSelectChange(e: Event) {
    this.shippingNameIsDisabled = false;
    if (e) {
      const element = e.target as HTMLInputElement;
      if (element.value === this.unOptions[0]) {
        this.unValidationError = true;
        this.isUnNumber = true;
        this.formGrp.controls[this.getFormControlsArray()[0]].setValue('');
        this.formGrp.controls[this.getFormControlsArray()[1]].setValue('');
        this.resetShippingName(false, element.value);
      } else if (element.value === this.unOptions[1] || element.value === this.unOptions[2]) {
          this.unNumber = null;
          this.isUnNumber = false;
          this.unValidationError = false;
          this.formGrp.controls[this.getFormControlsArray()[0]].setValue(element.value);
          this.formGrp.controls[this.getFormControlsArray()[1]].setValue(element.value);
          this.resetShippingName(true, element.value);
      }
    }
  }

  getFormControlsArray(): string[] {
    return Object.keys(this.formGrp.controls);
  }

  unNumberChanged(e: Event) {
    if (e) {
      const value = (e.target as HTMLInputElement).value;
      this.unNumberValidation(value);
    }
  }

  unNumberValidation(value) {
    const positiveIntCheckRegex = /^\d+$/;
    if (String(value).length === 4 && positiveIntCheckRegex.test(value)) {
      this.unValidationError = false;
    } else {
      this.unValidationError = true;
    }
  }

  resetShippingName(checked: boolean, value) {
    this.shippingNameIsDisabled = checked;
    this.formGrp.controls[this.getFormControlsArray()[1]].setValue('');
    if (value === this.unOptions[1]) {
        this.shippingNameIsDisabled = false;
        this.formGrp.controls[this.getFormControlsArray()[1]].setValue(this.unOptions[1]);
    } else if (value === this.unOptions[2]) {
      if (checked) {
         this.formGrp.controls[this.getFormControlsArray()[1]].setValue(this.unOptions[2]);
      }
    } else if (value === this.unOptions[0]) {
      this.formGrp.controls[this.getFormControlsArray()[1]].setValue('');
    }
    this.disableName.emit(this.getFormControlsArray()[1]);
  }

  alterCheckboxValue() {
    this.shippingNameIsDisabled = !this.shippingNameIsDisabled;
    this.resetShippingName(this.shippingNameIsDisabled, this.unOptions[2]);
  }
}
