import { Component, OnInit, Input } from "@angular/core";
import { TaskService } from "src/app/shared/services/task/task.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-list-task",
  templateUrl: "./list-task.component.html",
  styleUrls: ["./list-task.component.less"]
})
export class ListTaskComponent implements OnInit {
  tasks: any = [];
  expertTasks: any = [];
  total = 0;
  currentPage = 1;
  @Input() themeVar: string;
  console = console;
  user = ''

  constructor(
    private taskService: TaskService,
    public router: Router,
    public toastr: ToastrService,
    public route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.currentPage = !this.route.snapshot.queryParams["page"]
      ? 1
      : parseInt(this.route.snapshot.queryParams["page"]);
    // check if user is expert then get expert pending tasks
    
    if (this.user["expert"]) {
      this.expertList();
    }
    this.list();
  }

  expertList() {
    this.taskService.getUserTask().subscribe((res:any) => {
      this.expertTasks = res.items;
    })
  }

  list() {
    this.taskService.list(this.currentPage).subscribe((res: any) => {
      this.total = res.total;
      this.tasks = res.items;
      this.total = res.total;

    });
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.list();
  }

  goToTask(facet, id) {
    this.taskService.userTask(id).subscribe(
      res => {
        if (facet === 'expert_classification') {
          this.router.navigate([`/private/expert/${id}/transportation`]);
        } else {
          this.router.navigate([`/private/task/${facet}/${id}`]);
        }
        
      },
      err => {
        this.toastr.error("User doesnt have a task");
        this.router.navigate(["/private/task/list"]);
      }
    );
  }
}
