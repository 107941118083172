import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { RequestService } from 'src/app/shared/services/request/request.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-transportation',
  templateUrl: './transportation.component.html',
  styleUrls: ['./transportation.component.less']
})

export class TransportationComponent implements OnInit, OnDestroy {
  @ViewChildren('transportation') div:ElementRef;
  isDisabledByUnNumberCore = false;
  isDisabledByUnNumberGround = false;
  isDisabledByUnNumberAir = false;
  isDisabledByUnNumberSea = false;
  form: FormGroup;
  basicInfoData: any;
  isDisabledUnNumber = [];
  isDisabledProperShippingName = [];
  isDisabledTechnicalName = [];
  isDisabledReportableQuantity = [];
  subscription: Subscription;
  submitted = false;
  treeData = [];
  transportationData = [];
  un_names = ['core_un_number', 'ground_un_number', 'air_un_number', 'sea_un_number']
  defaultUnknownWhenEmpty = ['hazard_class_division', 'compatibility_group', 'subrisk', 'packing_group', 'marine_pollutant', 'limited_quantity', 'excepted_quantity', 'reportable_quantity', 'reportable_quantity_mass']
  transportation_dot = [];
  transportation_imdg = []; 
  transportation_iata = [];
  hazardClassDivisionCodes = [1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 2.1, 2.2, 2.3, 3, 4.1, 4.2, 4.3, 5.1, 5.2, 6.1, 6.2, 7, 8, 9, "Not Regulated", "Unknown"];
  compatibilityGroupCodes = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'N', 'S', "Not Applicable", "Unknown"]
  subriskCodes_core = ['2.1', '3', '4.1', '4.2', '4.3', '5.1', '6.1', '7', '8', 'None', 'Unknown']
  subriskCodes_ground = ['2.1', '3', '4.1', '4.2', '4.3', '5.1', '6.1', '7', '8', 'None', 'Unknown']
  subriskCodes_air = ['2.1', '3', '4.1', '4.2', '4.3', '5.1', '6.1', '7', '8', 'None', 'Unknown']
  subriskCodes_sea = ['2.1', '3', '4.1', '4.2', '4.3', '5.1', '6.1', '7', '8', 'None', 'Unknown']
  packingGroupCodes = ['I', 'II', 'III', 'Not Applicable', 'Unknown']
  marinePollutant = ['True', 'False', 'Not Applicable', 'Unknown']
  limitedQuantity = ['Yes', 'No', 'Not Applicable', 'Unknown']
  exceptedQuantity = ['Yes', 'No', 'Not Applicable', 'Unknown']
  reportableQuantityMass = ['lb.', 'kg.']
  un_dropdown_options = [{ label: 'Please enter', value: '' }, { label: 'Enter value', value: 'enter_value' }, { label: 'Not Regulated', value: 'not_regulated' }, { label: 'Unknown', value: 'unknown' }];
  technical_name_options = [{ label: 'Please enter', value: '' }, { label: 'Enter value', value: 'enter_value' }, { label: 'Not Regulated', value: 'not_regulated' }, {label: 'Not Applicable', value: 'not_applicable'}, { label: 'Unknown', value: 'unknown' }];
  expected_quantity_dropdown_options = [{ label: 'Please enter', value: '' }, { label: 'Enter value', value: 'enter_value' }, { label: 'Not Applicable', value: 'not_applicable' }, { label: 'Unknown', value: 'unknown' }];
  controls_for_drop = [{ select: 'select_un_number', input: 'un_number' }, { select: 'select_proper_shipping_name', input: 'proper_shipping_name' }, { select: 'select_technical_name', input: 'technical_name' }, { select: 'select_reportable_quantity', input: 'reportable_quantity' }]
  onInit: any;
  dataUpdate = []

  get f() {
    return this.form.controls;
  }

  constructor(
    public router: Router,
    private expertService: ExpertService,
    public requestService: RequestService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute
  ) { }

  disableScroll(){
    let transportation_div = this.div['first'].nativeElement
    var x=transportation_div.scrollLeft;
    var y=transportation_div.scrollTop;
    transportation_div.onscroll=function(){transportation_div.scrollTo(x, y);};
  }

  enableScroll(){
    let transportation_div = this.div['first'].nativeElement
    transportation_div.onscroll=function(){};
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      core: this.buildForm('core'),
      ground: this.buildForm('ground'),
      air: this.buildForm('air'),
      sea: this.buildForm('sea')
    });

    this.subscription = this.expertService.getExpertData$.subscribe((data: any) => {
      if (data && (this.treeData.length == 0 || this.treeData[0].id != data.id)) {
        this.treeData.length = 0;
        this.treeData.push(data);
        this.initialize(data.transportation);
      }
    });
  }

  initialize(transportation) {
    this.isDisabledUnNumber.length = 0
    this.isDisabledProperShippingName.length = 0
    this.isDisabledTechnicalName.length = 0
    this.isDisabledReportableQuantity.length = 0
    let formType = ['core', 'ground', 'air', 'sea']
    Object.entries(transportation).forEach((valueControl, i) => {
      valueControl[1] = this.setSelects(formType.indexOf(formType[i]), valueControl[1]);
      delete valueControl[1]["type"];
      valueControl[1] = this.checkIfValueIsEmpty(valueControl[1]);
      valueControl[1] = this.split_value_technical_name(valueControl[1])
      this.f[formType[i]].setValue(valueControl[1]);
      if (formType[i] !== 'core') {
        this.onInit = true;
        this.changeValueMulti(formType[i], 'transportation_exception', this.f[formType[i]]['controls']['transportation_exception']);
        this.onInit = false;
      }
      // this.setTechnicalNames(this.f[formType[i]]['controls'],valueControl[1]);

    });
    Object.entries(transportation).forEach((valueControl, i) => {
      if (valueControl[1]['un_number'] === 'not_regulated')
        this.populateNotRegulatedUnValues(formType[i])
      else
        this.switchUnNumberEditable(formType[i], false)
    });
  }

  switchUnNumberEditable(formGroup, editable) {
    switch (formGroup) {
      case 'core':
        this.isDisabledByUnNumberCore = editable;
        break

      case 'ground':
        this.isDisabledByUnNumberGround = editable;
        break

      case 'air':
        this.isDisabledByUnNumberAir = editable;
        break

      case 'sea':
        this.isDisabledByUnNumberSea = editable;
        break
    }
  }

  populateNotRegulatedUnValues(formGroup) {
    this.switchUnNumberEditable(formGroup, true)
    let to_not_applicable = [
      "compatibility_group",
      "packing_group",
      "marine_pollutant",
      "limited_quantity",
      "excepted_quantity",
      "select_reportable_quantity"
    ]

    let to_none = [
      "transportation_exception",
      "subrisk"
    ]

    let to_not_regulated = [
      "hazard_class_division",
      "select_technical_name",
      "select_proper_shipping_name",
    ]

    this.isDisabledTechnicalName.push(Object.keys(this.f).indexOf(formGroup))
    this.isDisabledUnNumber.push(Object.keys(this.f).indexOf(formGroup))
    this.isDisabledProperShippingName.push(Object.keys(this.f).indexOf(formGroup))
    this.isDisabledReportableQuantity.push(Object.keys(this.f).indexOf(formGroup))

    for (let [sub_key] of Object.entries(this.f[formGroup]['controls'])) {
      switch (true) {
        case to_not_applicable.includes(sub_key):
          let not_applicable = sub_key.match(/select/g) ? 'not_applicable' : 'Not Applicable'
          this.f[formGroup]['controls'][sub_key].setValue(not_applicable);
          this.f[formGroup]['controls'][sub_key].setValidators(null);
          this.f[formGroup]['controls'][sub_key].updateValueAndValidity();
          break;

        case to_none.includes(sub_key):
          let none = sub_key == 'subrisk' ? ['None'] : ['none'];
          this.f[formGroup]['controls'][sub_key].setValue(none);
          this.f[formGroup]['controls'][sub_key].setValidators(null);
          this.f[formGroup]['controls'][sub_key].updateValueAndValidity();
          break;

        case to_not_regulated.includes(sub_key):
          let not_regulated = sub_key.match(/select/g) ? 'not_regulated' : 'Not Regulated';
          this.f[formGroup]['controls'][sub_key].setValue(not_regulated);
          this.f[formGroup]['controls'][sub_key].setValidators(null);
          this.f[formGroup]['controls'][sub_key].updateValueAndValidity();
          break;
      }
    }

    const data = {
      type: 'transportation',
      payload: {
        ...this.treeData[0].transportation,
        [formGroup]: {...this.treeData[0].transportation[formGroup],
          un_number: this.f[formGroup]['controls']['select_un_number'].value,
          compatibility_group: this.f[formGroup]['controls']['compatibility_group'].value,
          packing_group: this.f[formGroup]['controls']['packing_group'].value,
          marine_pollutant: this.f[formGroup]['controls']['marine_pollutant'].value,
          limited_quantity: this.f[formGroup]['controls']['limited_quantity'].value,
          excepted_quantity: this.f[formGroup]['controls']['excepted_quantity'].value,
          reportable_quantity: this.f[formGroup]['controls']['select_reportable_quantity'].value,
          reportable_quantity_mass: this.f[formGroup]['controls']['reportable_quantity_mass'].value,
          subrisk: this.f[formGroup]['controls']['subrisk'].value,
          hazard_class_division: this.f[formGroup]['controls']['hazard_class_division'].value,
          technical_name: this.f[formGroup]['controls']['select_technical_name'].value,
          select_technical_name: this.f[formGroup]['controls']['select_technical_name'].value,
          select_un_number: this.f[formGroup]['controls']['select_un_number'].value,
          proper_shipping_name: this.f[formGroup]['controls']['select_proper_shipping_name'].value,
          ...(formGroup != 'core'
            ? {
              transportation_exception: this.f[formGroup]['controls']['transportation_exception'].value
            }
            : {}
          )
        }
      },
      targetId: this.treeData[0].id
    }
    this.accept(formGroup, data);
    
  }

  selectItemUn(formGroup, controlName, position, value) {
    if (value === 'unknown' || value === 'not_regulated' || value === '') {
      this.isDisabledUnNumber.push(position)
      this.f[formGroup]['controls'][controlName].setValue(value);
      this.f[formGroup]['controls'][controlName].setValidators(null)

      if (value == 'not_regulated')
        this.populateNotRegulatedUnValues(formGroup)
      else {
        this.changeValue(formGroup, controlName, this.f[formGroup]['controls'][controlName])
        this.switchUnNumberEditable(formGroup, false)
      }
    } else {
      this.switchUnNumberEditable(formGroup, false)
      this.f[formGroup]['controls'][controlName].setValue('')
      this.f[formGroup]['controls'][controlName].setValidators(this.unNumberValidation)
      this.f[formGroup]['controls'][controlName].updateValueAndValidity()
      this.isDisabledUnNumber = this.isDisabledUnNumber.filter(c => c !== position)
    }
    //this.f[formGroup]['controls'][controlName].updateValueAndValidity()
  }

  selectItemPSN(formGroup, controlName, position, value) {
    if (value === 'unknown' || value === 'not_regulated' || value === '') {
      this.isDisabledProperShippingName.push(position)
      this.f[formGroup]['controls'][controlName].setValue(value);
      this.changeValue(formGroup, controlName, this.f[formGroup]['controls'][controlName])

    } else {
      this.isDisabledProperShippingName = this.isDisabledProperShippingName.filter(c => c !== position)
    }
  }

  selectItemTN(formGroup, controlName, position, value) {
    if (value === 'unknown' || value === 'not_regulated' || value === '' || value === 'not_applicable') {
      this.isDisabledTechnicalName.push(position)
      this.f[formGroup]['controls']['technical_name_0'].setValue(value);
      this.f[formGroup]['controls']['technical_name_1'].setValue(value);
      this.f[formGroup]['controls']['technical_name_2'].setValue(value);
      this.f[formGroup]['controls']['technical_name_3'].setValue(value);
      this.f[formGroup]['controls']['technical_name_4'].setValue(value);
      this.changeValue(formGroup, controlName, this.f[formGroup]['controls']['technical_name_0'])
      this.changeValue(formGroup, controlName, this.f[formGroup]['controls']['technical_name_1'])
      this.changeValue(formGroup, controlName, this.f[formGroup]['controls']['technical_name_2'])
      this.changeValue(formGroup, controlName, this.f[formGroup]['controls']['technical_name_3'])
      this.changeValue(formGroup, controlName, this.f[formGroup]['controls']['technical_name_4'])

    } else {
      this.isDisabledTechnicalName = this.isDisabledTechnicalName.filter(c => c !== position)
    }
  }

  selectItemRC(formGroup, controlName, position, value) {
    if (value === 'unknown' || value === 'not_applicable' || value === '') {
      this.isDisabledReportableQuantity.push(position)
      this.f[formGroup]['controls'][controlName].setValue(value);
      // this.f[formGroup]['controls']['reportable_quantity_mass'].setValue('');
      this.changeValue(formGroup, controlName, this.f[formGroup]['controls'][controlName]);

    } else {
      this.isDisabledReportableQuantity = this.isDisabledReportableQuantity.filter(c => c !== position)
    }
  }

  checkIfValueIsEmpty(val) {
    Object.entries(val).forEach(property => {
      if (this.defaultUnknownWhenEmpty.includes(property[0])) {
        if (property[1] === '' || property[1] === null || property[1] === "null") {
          val[property[0]] = ''
        }
      }
    })
    return val
  }

  disable_input_by_name(name, index) {
   
    if (name === 'un_number') {
      this.isDisabledUnNumber.push(index);
    } else if (name === 'proper_shipping_name') {
      this.isDisabledProperShippingName.push(index);
    } else if (name === 'technical_name') {
      this.isDisabledTechnicalName.push(index);
    } else if (name === 'reportable_quantity') {
      this.isDisabledReportableQuantity.push(index);
    }
  }

  setSelects(index, val) {
    for (let cont in this.controls_for_drop) {
      var input_name = this.controls_for_drop[cont].input;
      var select_name = this.controls_for_drop[cont].select;
      if (val[input_name] === '' || val[input_name] === null || val[input_name] === "null") {
        val[select_name] = '';
        this.disable_input_by_name(input_name, index);
      } else if (val[input_name] === 'not_regulated' || this.arrayEquals(val[input_name], ["not_regulated"])) {
        val[select_name] = 'not_regulated';
        this.disable_input_by_name(input_name, index);
      } else if (val[input_name] === 'not_applicable' || this.arrayEquals(val[input_name], ["not_applicable"])) {
        val[select_name] = 'not_applicable';
        this.disable_input_by_name(input_name, index);
      } else if (val[input_name] === 'unknown' || this.arrayEquals(val[input_name], ["unknown"])) {
        val[select_name] = 'unknown';
        this.disable_input_by_name(input_name, index);
      }  else {
        val[select_name] = 'enter_value';
      }
    }
    return val
  }

  arrayEquals(a, b) {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }

  unNumberCheck(e, control, index) {
    if (control.value) {
      this.isDisabledUnNumber.push(index)
      control.setValue("not_regulated");
      control.setValidators(null)
      control.updateValueAndValidity();
    } else {
      this.isDisabledUnNumber = this.isDisabledUnNumber.filter(item => item !== index)
      control.setValidators(this.unNumberValidation)
      control.setValue('');
      control.updateValueAndValidity();
    }
    control.updateValueAndValidity();
  }

  //TODO: this will be refactored
  buildForm(type) {
    if (type === 'core') {
      return this.formBuilder.group({
        description: '',
        select_un_number: '',
        un_number: ['', this.unNumberValidation],
        select_proper_shipping_name: '',
        proper_shipping_name: '',
        select_technical_name: '',
        technical_name: '',
        technical_name_0: '',
        technical_name_1: '',
        technical_name_2: '',
        technical_name_3: '',
        technical_name_4: '',
        hazard_class_division: '',
        compatibility_group: '',
        subrisk: '',
        packing_group: null,
        marine_pollutant: '',
        limited_quantity: '',
        excepted_quantity: '',
        select_reportable_quantity: '',
        reportable_quantity: '',
        reportable_quantity_mass: ''
      })
    } else {
      return this.formBuilder.group({
        description: null,
        select_un_number: null,
        un_number: [null, this.unNumberValidation],
        select_proper_shipping_name: null,
        proper_shipping_name: null,
        select_technical_name: null,
        technical_name: '',
        technical_name_0: null,
        technical_name_1: null,
        technical_name_2: null,
        technical_name_3: null,
        technical_name_4: null,
        hazard_class_division: null,
        compatibility_group: null,
        subrisk: null,
        packing_group: null,
        marine_pollutant: null,
        limited_quantity: null,
        excepted_quantity: null,
        select_reportable_quantity: null,
        reportable_quantity: null,
        reportable_quantity_mass: null,
        transportation_exception: null
      })
    }
  }

  setTechnicalNames(formControl, values) {
    console.log(values);
  }

  split_value_technical_name(values) {
    var technical_name_array = values['technical_name'];
    if (technical_name_array != undefined && technical_name_array.length > 0 && technical_name_array.length <= 5) {
      for (var i = 0; i < technical_name_array.length; i++) {
        switch (i) {
          case 0:
            values['technical_name_0'] = technical_name_array[i];
            values['technical_name_1'] = '';
            values['technical_name_2'] = '';
            values['technical_name_3'] = '';
            values['technical_name_4'] = '';
            break;
          case 1:
            values['technical_name_1'] = technical_name_array[i];
            values['technical_name_2'] = '';
            values['technical_name_3'] = '';
            values['technical_name_4'] = '';
            break;
          case 2:
            values['technical_name_2'] = technical_name_array[i];
            values['technical_name_3'] = '';
            values['technical_name_4'] = '';
            break;
          case 3:
            values['technical_name_3'] = technical_name_array[i];
            values['technical_name_4'] = '';
            break;
          case 4:
            values['technical_name_4'] = technical_name_array[i];
            break;
        }
      }
    } else if (!technical_name_array || technical_name_array.length == 0) {
      values['technical_name_0'] = '';
      values['technical_name_1'] = '';
      values['technical_name_2'] = '';
      values['technical_name_3'] = '';
      values['technical_name_4'] = '';
    } else {
      values['technical_name_0'] = technical_name_array;
      values['technical_name_1'] = technical_name_array;
      values['technical_name_2'] = technical_name_array;
      values['technical_name_3'] = technical_name_array;
      values['technical_name_4'] = technical_name_array;
    }
    //delete values["technical_name"];
    return values;
    // values['technical_name_0']
  }
  
  

  changeValue(formGroup, controlName, formControl) {
    const data = {
      type: 'transportation',
      payload: {
        ...this.treeData[0].transportation,
        [formGroup]: { ...this.treeData[0].transportation[formGroup], [controlName]: formControl.value }
      },
      targetId: this.treeData[0].id
    }
    this.accept(formGroup, data);
  }

  changeValueMulti(formGroup, controlName, formControl) {
    if (formControl.value.includes('none')) {
      formControl.setValue(['none']);
    } else if (formControl.value.includes('unknown')) {
      formControl.setValue(['unknown']);
    } else if (formControl.value.includes('None')) {
      formControl.setValue(['None']);
    } else if (formControl.value.includes('Unknown')) {
      formControl.setValue(['Unknown']);
    }
    switch (controlName) {
      case 'transportation_exception':
        switch (formGroup) {
          case 'ground':
            if (formControl.value.includes('none')) {
              this.transportation_dot = [{ label: 'None', value: 'none' }];
            } else if (formControl.value.includes('unknown')) {
              this.transportation_dot = [{ label: 'Unknown', value: 'unknown' }];
            } else {
              this.transportation_dot = this.assign_value_name([
                { label: "173.120(a)(2) <i>Combustion Liquid Mixture</i>", value: "173.120(a)(2)" },
                { label: "173.120(a)(3) <i>Combustion Not Sustainable</i>", value: "173.120(a)(3)" },
                { label: "173.120(a)(4) <i>Combustible Fire Point > 100C</i>", value: "173.120(a)(4)" },
                { label: "173.120(a)(5) <i>90% Water Soluble Solution</i>", value: "173.120(a)(5)" },
                { label: "173.120(b)(3) <i>Combustible Liquid, Combustion Not Sustainable</i>", value: "173.120(b)(3)" },
                { label: "173.150(e)(2) <i>24% Aqueous Alcohol Solutions</i>", value: "173.150(e)(2)" },
                { label: "173.150(f)(2) <i>Flammable Liquid Reclassed to Combustible Liquid</i>", value: "173.150(f)(2)" },
                { label: "173.150(g)(1)(i)(A) <i>70% Alcohol Solutions, Liquid, Small, Non-Glass</i>", value: "173.150(g)(1)(i)(A)" },
                { label: "173.150(g)(1)(i)(B) <i>70% Alcohol Solutions, Liquid, Large, Non-Glass</i>", value: "173.150(g)(1)(i)(B)" },
                { label: "173.150(g)(1)(i)(C) <i>70% Alcohol Solutions, Solid, Small, Non-Glass</i>", value: "173.150(g)(1)(i)(C)" },
                { label: "173.150(g)(1)(i)(D) <i>70% Alcohol Solutions, Solid, Large, Non-Glass</i>", value: "173.150(g)(1)(i)(D)" },
                { label: "173.150(g)(1)(ii)(A) <i>70% Alcohol Solutions, Liquid, Small, Glass</i>", value: "173.150(g)(1)(ii)(A)" },
                { label: "173.150(g)(1)(ii)(B) <i>70% Alcohol Solutions, Liquid, Large, Glass</i>", value: "173.150(g)(1)(ii)(B)" },
                { label: "173.150(g)(1)(ii)(C) <i>70% Alcohol Solutions, Solid, Small, Glass</i>", value: "173.150(g)(1)(ii)(C)" },
                { label: "173.150(g)(1)(ii)(D) <i>70% Alcohol Solutions, Solid, Large, Glass</i>", value: "173.150(g)(1)(ii)(D)" },
                { label: "173.159(a) <i>Non-Spillable Lead Acid Batteries</i>", value: "173.159(a)" },
                { label: "173.134(b)(12)(ii) <i>Used Health Care Products</i>", value: "173.134(b)(12)(ii)" },
                { label: "171.4 <i>Marine Pollutants ≤ 5 L (5 kg)</i>", value: "171.4" },
                { label: "173.13 <i>173.13 Labeling Exception</i>", value: "173.13" },
                { label: "173.134(c) <i>Regulated Medical Waste</i>", value: "173.134(c)" },
                { label: "172.102(136) <i>Dangerous Goods in Machinery/Appratus</i>", value: "172.102(136)" },
                { label: "173.165(c) <i>Polyester Resin Kits</i>", value: "173.165(c)" },
                { label: "173.166(c) <i>Safety Device - EX Number</i>", value: "173.166(c)" },
                { label: "173.174 <i>Refrigerating Machines</i>", value: "173.174" },
                { label: "173.185(c) <i>Small Lithium Cells and Batteries</i>", value: "173.185(c)" },
                { label: "173.185(d) <i>Lithium Cells or Batteries for Disposal or Recycling</i>", value: "173.185(d)" },
                { label: "173.185(e) <i>Low Production Runs & Prototype Lithium Batteries</i>", value: "173.185(e)" },
                { label: "173.185(g) <i>Medical Device Batteries</i>", value: "173.185(g)" },
                { label: "173.217 <i>Carbon Dioxide (Dry Ice)</i>", value: "173.217" },
                { label: "173.222 <i>Dangerous Goods in Machinery/Apparatus</i>", value: "173.222" },
                { label: "173.230(g) <i>Fuel Cell Cartridges</i>", value: "173.230(g)" },
                { label: "173.151(b)(2) <i>Charcoal Briquettes</i>", value: "173.151(b)(2)" },
                { label: "173.151(e) <i>Rare Earth Metals</i>", value: "173.151(e)" },
                { label: "173.154(d)(1) <i>Corrosive to  Aluminum Only</i>", value: "173.154(d)(1)" },
                { label: "173.154(d)(2) <i>Corrosive to  Steel Only</i>", value: "173.154(d)(2)" },
                { label: "173.167(a) <i>Consumer Commodity</i>", value: "173.167(a)" },
                { label: "173.226(f) <i>Class 6.1, PG I ,Hazard Zone A </i>", value: "173.226(f)" },
                { label: "173.302(f)(6) <i>Compressed Oxygen</i>", value: "173.302(f)(6)" },
                { label: "173.306(f)(1) <i>Accumulators in Machinery or Vehicles</i>", value: "173.306(f)(1)" },
                { label: "173.306(f)(2) <i>Accumulators ≤ 200 PSIG</i>", value: "173.306(f)(2)" },
                { label: "173.306(f)(3) <i>Accumulators > 200 PSIG</i>", value: "173.306(f)(3)" },
                { label: "173.306(f)(4) <i>Shocks, Struts & Gas Springs</i>", value: "173.306(f)(4)" },
                { label: "173.306(g) <i>Water Pump System Tank</i>", value: "173.306(g)" },
                { label: "173.306(j) <i>Small Aerosols</i>", value: "173.306(j)" },
                { label: "173.306(k) <i>Aerosols Disposal & Recycling</i>", value: "173.306(k)" },
                { label: "172.102(47) <i>Solid Containing Flammable Liquids</i>", value: "172.102(47)" },
                { label: "173.306(a) <i>Compressed Gases</i>", value: "173.306(a)" },
                { label: "173.306(b) <i>Foodstuffs, Soap, Biological, Electron Tubes, Audible Fire Alarms</i>", value: "173.306(b)" },
                { label: "173.308(e) <i>Lighters</i>", value: "173.308(e)" },
                { label: "173.309(d) <i>Fire Extinguishers</i>", value: "173.309(d)" },
                { label: "173.31 <i>Radiation Detectors</i>", value: "173.31" },
                { label: "173.310(f) <i>Small Radiation Detectors</i>", value: "173.310(f)" },
                { label: "176.9 <i>Private Automobiles</i>", value: "176.9" },
                { label: "173.220(a) <i>Engines, Internal Combustion</i>", value: "173.220(a)" },
                { label: "173.220(c) <i>Battery-Powered Vehicles</i>", value: "173.220(c)" },
                { label: "176.905 <i>Vehicles</i>", value: "176.905" },
                { label: 'None', value: 'none' }, 
                { label: 'Unknown', value: 'unknown' }
              ]);
            }
            break;
          case 'air':
            if (formControl.value.includes('none')) {
              this.transportation_iata = [{ label: 'None', value: 'none' }];
            } else if (formControl.value.includes('unknown')) {
              this.transportation_iata = [{ label: 'Unknown', value: 'unknown' }];
            } else {
              this.transportation_iata = this.assign_value_name([
                { label: "A197 (375) HC9 exemption <i>Marine Pollutants ≤ 5 L (5 kg)</i>", value: "A197 (375) HC9 exemption" },
                { label: "966-II <i>Lithium ion and lithium polymer cells or batteries (966-II)</i>", value: "966-II" },
                { label: "967-II <i>Lithium ion and lithium polymer cells or batteries (967-II)</i>", value: "967-II" },
                { label: "970-II <i>Lithium ion and lithium polymer cells or batteries (970-II)</i>", value: "970-II" },
                { label: "ID8000 <i>Consumer Commodity</i>", value: "ID8000" },
                { label: "SP A44 <i>Chemical kits or first aid kit</i>", value: "SP A44" },
                { label: "SP A46 <i>Solids containing flammable liquids</i>", value: "SP A46" },
                { label: "SP A67 <i>Non-spillable batteries</i>", value: "SP A67" },

                { label: 'None', value: 'none' }, 
                { label: 'Unknown', value: 'unknown' }
              ]);
            }
            break;
          case 'sea':
            if (formControl.value.includes('none')) {
              this.transportation_imdg = [{ label: 'None', value: 'none' }];
            } else if (formControl.value.includes('unknown')) {
              this.transportation_imdg = [{ label: 'Unknown', value: 'unknown' }];
            } else {
              this.transportation_imdg = this.assign_value_name([
                { label: "2.10.2.7 HC9 exemption <i>Marine Pollutants ≤ 5 L (5 kg)</i>", value: "2.10.2.7 HC9 exemption" },
                { label: "SP 188 <i>Lithium batteries</i>", value: "SP 188" },
                { label: "SP 216 <i>Solids containing flammable liquids</i>", value: "SP 216" },
                { label: "SP 238 <i>Non-spillable batteries</i>", value: "SP 238" },
                { label: "SP 251 <i>First Aid Kits</i>", value: "SP 251" },
                { label: 'None', value: 'none' }, 
                { label: 'Unknown', value: 'unknown' }]);
            }
            break;
        }
        break;

      case 'subrisk':
        switch (formGroup) {
          case 'core':
            if (formControl.value.includes('None')) {
              this.subriskCodes_core = ['None'];
            } else if (formControl.value.includes('Unknown')) {
              this.subriskCodes_core = ['Unknown'];
            } else {
              this.subriskCodes_core = ['2.1', '3', '4.1', '4.2', '4.3', '5.1', '6.1', '7', '8', 'None', 'Unknown'];
            }
            break;
          case 'ground':
            if (formControl.value.includes('None')) {
              this.subriskCodes_ground = ['None'];
            } else if (formControl.value.includes('Unknown')) {
              this.subriskCodes_ground = ['Unknown'];
            } else {
              this.subriskCodes_ground = ['2.1', '3', '4.1', '4.2', '4.3', '5.1', '6.1', '7', '8', 'None', 'Unknown'];
            }
            break;
          case 'air':
            if (formControl.value.includes('None')) {
              this.subriskCodes_air = ['None'];
            } else if (formControl.value.includes('Unknown')) {
              this.subriskCodes_air = ['Unknown'];
            } else {
              this.subriskCodes_air = ['2.1', '3', '4.1', '4.2', '4.3', '5.1', '6.1', '7', '8', 'None', 'Unknown'];
            }
            break;
          case 'sea':
            if (formControl.value.includes('None')) {
              this.subriskCodes_sea = ['None'];
            } else if (formControl.value.includes('Unknown')) {
              this.subriskCodes_sea = ['Unknown'];
            } else {
              this.subriskCodes_sea = ['2.1', '3', '4.1', '4.2', '4.3', '5.1', '6.1', '7', '8', 'None', 'Unknown'];
            }
            break;
        }

        break;
    }
    if (!this.onInit) {
      const data = {
        type: 'transportation',
        payload: {
          ...this.treeData[0].transportation,
          [formGroup]: { ...this.treeData[0].transportation[formGroup], [controlName]: formControl.value }
        },
        targetId: this.treeData[0].id
      }
      this.accept(formGroup, data);
    }

  }

  changeValueMultiWithMax(formGroup, controlName, formControl, max) {
    if (!formControl.value.includes('None') && !formControl.value.includes('Unknown') && formControl.value.length > max) {
      formControl.value.pop();
      formControl.setValue(formControl.value);
      formControl.updateValueAndValidity();
    }
    this.changeValueMulti(formGroup, controlName, formControl);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private unNumberValidation(control: FormControl) {
    let value = control.value;
    if (value) {
      const alphaPrefixPlusFourDigitCheckRegex = /^(un|id|na)(\d{4})$/;
      const isValidUN = alphaPrefixPlusFourDigitCheckRegex.test(value.toLowerCase());
      return isValidUN ? null : {notInteger: true}
    }
    return null;
  }

  private accept(formGroup, data) {
    data.payload = this.build_technical_name_array(data.payload);
    this.treeData[0].transportation = {
      ...this.treeData[0].transportation,
      [formGroup]: { ...data.payload[formGroup] }
    }
    // expert format data
    let expert_data = {
      type: 'transportation',
      payload: {
        ...this.treeData[0].transportation,
      },
      targetId: this.treeData[0].id
    }
    this.expertService.accept(expert_data);
  }

  private build_technical_name_array(corrected_data) {
    let formType = ['core', 'ground', 'air', 'sea']
    // tslint:disable-next-line: forin
    for (let form_type in formType) {
      var technical_names = [];
      if (this.f[formType[form_type]]['controls']['technical_name_0'].value.trim() != '') {
        technical_names.push(this.f[formType[form_type]]['controls']['technical_name_0'].value.trim());
      }
      if (this.f[formType[form_type]]['controls']['technical_name_1'].value.trim() != '') {
        technical_names.push(this.f[formType[form_type]]['controls']['technical_name_1'].value.trim());
      }
      if (this.f[formType[form_type]]['controls']['technical_name_2'].value.trim() != '') {
        technical_names.push(this.f[formType[form_type]]['controls']['technical_name_2'].value.trim());
      }
      if (this.f[formType[form_type]]['controls']['technical_name_3'].value.trim() != '') {
        technical_names.push(this.f[formType[form_type]]['controls']['technical_name_3'].value.trim());
      }
      if (this.f[formType[form_type]]['controls']['technical_name_4'].value.trim() != '') {
        technical_names.push(this.f[formType[form_type]]['controls']['technical_name_4'].value.trim());
      }
      
      if (technical_names.filter(x => x === 'unknown').length === 5) {
        corrected_data[formType[form_type]]['technical_name'] = ['unknown'];
      } else if (technical_names.filter(x => x === 'not_regulated').length === 5 || 
                this.f[formType[form_type]]['controls']['select_technical_name'].value === 'not_regulated') {
        corrected_data[formType[form_type]]['technical_name'] = ['not_regulated'];
      } else if (technical_names.length === 0) {
        corrected_data[formType[form_type]]['technical_name'] = '';
      } else if (technical_names.filter(x => x === 'not_applicable').length === 5) {
        corrected_data[formType[form_type]]['technical_name'] = ['not_applicable'];
      } else {
        corrected_data[formType[form_type]]['technical_name'] = technical_names;
      }
      delete corrected_data[formType[form_type]]['technical_name_0'];
      delete corrected_data[formType[form_type]]['technical_name_1'];
      delete corrected_data[formType[form_type]]['technical_name_2'];
      delete corrected_data[formType[form_type]]['technical_name_3'];
      delete corrected_data[formType[form_type]]['technical_name_4'];
      
      // delete corrected_data[formType[form_type]]['select_un_number'];
      // delete corrected_data[formType[form_type]]['select_proper_shipping_name'];
      // delete corrected_data[formType[form_type]]['select_technical_name'];
      // delete corrected_data[formType[form_type]]['select_reportable_quantity'];
    }
    return corrected_data;
  }

  assign_value_name(list){
    return list.map(element => element.label.length > 31 ? {...element, name: element.label.substr(0, 30) + "</i>..."} : {...element, name: element.label})
  }
}
