import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  AbstractControl
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ExpertService } from '../../services/expert/expert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.less']
})
export class CancelComponent implements OnInit {
  modalRef: BsModalRef;
  @Output() handleConfirm: EventEmitter<any> = new EventEmitter();
  @Input() themeVar: string;
  @Input() expertCancel: any;
  request = {};
  form: FormGroup;
  submitted = false;
  isDisabled = false;
  subscription: Subscription;
  
  static validateComment(AC: AbstractControl) {
    const array = ['Issue with PACE UI', 'Other', 'General technical issue with SPACE tool'];
    const reason = AC.get('reason').value; // to get value in input tag
    const comment = AC.get('comment').value; // to get value in input tag
    if (array.includes(reason) && !comment) {
      AC.get('comment').setErrors({ validateComment: true });
      return;
    }
    AC.get('comment').setErrors(null);
    return;
  }

  constructor(
    public modalService: BsModalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private expertService: ExpertService
  ) {
    this.subscription = this.expertService.disableInteraction$.subscribe(state => this.isDisabled = state); 
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      reason: ['', [Validators.required]],
      comment: ['', ]
    },
    { validator: CancelComponent.validateComment});
  }

  confirm(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.modalRef.hide();
      this.handleConfirm.emit({
        reason: this.f.reason.value,
        comment: this.f.comment.value
      });
      this.f.reason.setValue('');
      this.f.comment.setValue('');
    }
  }

  decline(): void {
    this.f.reason.setValue('');
    this.f.comment.setValue('');
    this.submitted = false;
    this.modalRef.hide();
  }

  open(template) {
    this.modalRef = this.modalService.show(template);
  }


  get f() {
    return this.form.controls;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }
}
