import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators, Form } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { RequestService } from 'src/app/shared/services/request/request.service';

@Component({
  selector: 'app-wa',
  templateUrl: './wa.component.html',
  styleUrls: ['./wa.component.less']
})

export class WaComponent extends BaseComponent implements OnInit {
  @Output() handleOpen: EventEmitter<any> = new EventEmitter();
  @Output() handleClose: EventEmitter<any> = new EventEmitter();
  treeData = [];
  public waForm: FormGroup;
  public waCodes: {};
  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public activeRoute: ActivatedRoute,
    private expertService: ExpertService,
    public requestService: RequestService
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.waForm = this.formBuilder.group({
      wt: ['', Validators.required],
      wp: ['', Validators.required],
      ws: ['', Validators.required]
    });
    this.expertService.getExpertData$.subscribe((data: any) => {
      if (data) {
        
        this.treeData = [];
        this.treeData.push(data);
        if(data.waste.wa==null){
          data.waste.wa = {};
        }
        let wtFiltered = this.filterInvalidCodes(data.waste.wa.wt, this.requestService.wa_codes, 'wt');
        let wsFiltered = this.filterInvalidCodes(data.waste.wa.ws, this.requestService.wa_codes, 'ws');
        let wpFiltered = this.filterInvalidCodes(data.waste.wa.wp, this.requestService.wa_codes, 'wp');
        this.f.wt.setValue(wtFiltered ? wtFiltered : '');
        this.f.ws.setValue(wsFiltered ? wsFiltered : '');
        this.f.wp.setValue(wpFiltered ? wpFiltered : '');
      }
    });
    this.waCodes = this.requestService.wa_codes;
  }

  filterInvalidCodes(code, allCodes, type) {
    switch (type) {
      case 'wt':
        if(allCodes["wt"].includes(code)) {
          return code;
        }
        break;
      case 'wp':
        if (allCodes["wp"].includes(code)) {
          return code
        };
        break;
      case 'ws':
        if (allCodes["ws"].includes(code)) {
          return code
        };
        break;
      default:
        break;
    }
  }

  change() {
    const data = { 
      type: "waste", 
      payload: {...this.treeData[0].waste, 
        wa: { 
          wt: this.f.wt.value,
          wp: this.f.wp.value,
          ws: this.f.ws.value
        },
        wa_codes: this.getWACodes(this.waCodes)
      }, 
      targetId: this.treeData[0].id 
    };
    this.expertService.accept(data);
  }

  submit() {
    this.submitted = true;
    if (this.waForm.valid) {
      const request = {  WT: this.f.wt.value, WP: this.f.wp.value, WS: this.f.ws.value, wa_codes: this.getWACodes(this.waCodes) };
      this.post(request);
    }
  }

  getWACodes(WACodes){
    let wa_codes = {
      WT:[],
      WP:[],
      WS:[]
    }
    wa_codes.WT = WACodes.wt.filter(code => code.match(/^W/))
    wa_codes.WP = WACodes.wp.filter(code => code.match(/^W/))
    wa_codes.WS = WACodes.ws.filter(code => code.match(/^W/))
    return wa_codes
  }

  onClose(){
    this.handleClose.emit(null);
  }

  onOpen(){
    this.handleOpen.emit(null);
  }

  get f() {
    return this.waForm.controls;
  }
}