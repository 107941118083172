import { Component, OnInit, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { RequestService } from 'src/app/shared/services/request/request.service';
import { DropdownConfig } from 'src/app/shared/interface';
import { HazardService } from 'src/app/shared/services/hazard/hazard.service';
import { DropdownLabels } from '../../../shared/enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-iship',
  templateUrl: './iship.component.html',
  styleUrls: ['./iship.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class IshipComponent extends BaseComponent implements OnInit {
  public form: FormGroup;
  public disabledDotName = false;
  public disabledIataName = false;
  public disabledImdgName = false;

  public naOptions = ['Number', this.hazard.couldNotFindText];
  public isNaNumber = false;
  public naValidationError = true;
  public isIdNumber = false;
  public idValidationError = true;

  public showNa = false;
  public showId = false;
  public dotSubscription: Subscription;
  public iataSubscription: Subscription;

  public dotDropDownConfigs = {
    class: {},
    division: {},
    compatibility: {},
    packingGroup: {},
    subClass: {},
    subDivision: {}
  };
  public imdgDropDownConfigs = {
    class: {},
    division: {},
    compatibility: {},
    packingGroup: {},
    subClass: {},
    subDivision: {}
  };
  public iataDropDownConfigs = {
    class: {},
    division: {},
    compatibility: {},
    packingGroup: {},
    subClass: {},
    subDivision: {}
  };

  public dotFormControlNames = {
    hazardClass: 'dot_hazard_class',
    hazardDivision: 'dot_hazard_division',
    hazardCompatibility: 'dot_hazard_compatibility',
    packingGroup: 'dot_hazard_packing_group',
    subHazardClass: 'dot_subhazard_class',
    subHazardDivision: 'dot_subhazard_division',
  };
  public iataFormControlNames = {
    hazardClass: 'iata_hazard_class',
    hazardDivision: 'iata_hazard_division',
    hazardCompatibility: 'iata_hazard_compatibility',
    packingGroup: 'iata_hazard_packing_group',
    subHazardClass: 'iata_subhazard_class',
    subHazardDivision: 'iata_subhazard_division',
  };
  public imdgFormControlNames = {
    hazardClass: 'imdg_hazard_class',
    hazardDivision: 'imdg_hazard_division',
    hazardCompatibility: 'imdg_hazard_compatibility',
    packingGroup: 'imdg_hazard_packing_group',
    subHazardClass: 'imdg_subhazard_class',
    subHazardDivision: 'imdg_subhazard_division',
  };

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public activeRoute: ActivatedRoute,
    public hazard: HazardService
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.initForm();
    this.setDropDownConfigs();
    this.checkIfUnDoesnotExist();
  }

  initForm() {
    this.form = this.formBuilder.group({
      un_dot_grp: this.formBuilder.group({
        un_dot: ['', [Validators.required, this.unNumberValidation]],
        dot_proper_shipping_name: [{
          value: '',
          disabled: this.disabledDotName
        }, [Validators.required, this.noWhitespaceValidator]]
      }),
      un_iata_grp: this.formBuilder.group({
        un_iata: ['', [Validators.required, this.unNumberValidation]],
        iata_proper_shipping_name: [{
          value: '',
          disabled: this.disabledIataName
        }, [Validators.required, this.noWhitespaceValidator]]
      }),
      un_imdg_grp: this.formBuilder.group({
        un_imdg: ['', [Validators.required, this.unNumberValidation]],
        imdg_proper_shipping_name: [{
          value: '',
          disabled: this.disabledImdgName
        }, [Validators.required, this.noWhitespaceValidator]]
      }),
      dot_grp: this.formBuilder.group({
        dot_hazard_class: ['', Validators.required],
        dot_subhazard_class: ['', Validators.required],
      }),
      iata_grp: this.formBuilder.group({
        iata_hazard_class: ['', Validators.required],
        iata_subhazard_class: ['', Validators.required],
      }),
      imdg_grp: this.formBuilder.group({
        imdg_hazard_class: ['', Validators.required],
        imdg_subhazard_class: ['', Validators.required],
      }),
      na_num: ['', [Validators.required, this.unNumberValidation]],
      id_num: ['', [Validators.required, this.unNumberValidation]],
    });
  }

  setDropDownConfigs() {
    this.setConfig(this.dotDropDownConfigs, 'DOT');
    this.setConfig(this.imdgDropDownConfigs, 'IMDG');
    this.setConfig(this.iataDropDownConfigs, 'IATA');
  }

  setConfig(configObj, dropDownFor: string) {
    configObj.class = this.getClassConfig(dropDownFor);
    configObj.subClass = this.getSubClassConfig(dropDownFor);
    configObj.packingGroup = this.getPackingGroupConfig(dropDownFor);
  }

  public getClassConfig(dropDownFor: string): any {
    return {
      label: dropDownFor + ' ' + DropdownLabels.labelClass,
      dropdownOption: this.hazard.classes,
      placeHolder: this.hazard.placeHolder
    };
  }

  public getSubClassConfig(dropDownFor: string): any {
    return {
      label: dropDownFor + ' ' + DropdownLabels.labelSubClass,
      dropdownOption: this.hazard.subIshipClasses,
      placeHolder: this.hazard.placeHolder
    };
  }

  public getPackingGroupConfig(dropDownFor: string): any {
    return {
      label: dropDownFor + ' ' + DropdownLabels.labelPackingGroup,
      dropdownOption: this.hazard.packingGroup,
      placeHolder: this.hazard.placeHolder
    };
  }

  checkIfUnDoesnotExist() {
    this.dotSubscription = (this.form.controls.un_dot_grp as FormGroup).controls.un_dot.valueChanges.subscribe((res) => {
      this.setNa(res);
    });
    this.iataSubscription = (this.form.controls.un_iata_grp as FormGroup).controls.un_iata.valueChanges.subscribe((res) => {
      this.setId(res);
    });
  }

  setNa(val: string) {
    if (val === this.hazard.couldNotFindText) {
      this.showNa = true;
      this.form.controls.na_num.setValue('');
    } else {
      this.showNa = false;
      this.isNaNumber = false;
      this.form.controls.na_num.setValue('Could Not Find');
    }
  }

  setId(val: string) {
    if (val === this.hazard.couldNotFindText) {
      this.showId = true;
      this.form.controls.id_num.setValue('');
    } else {
      this.showId = false;
      this.isIdNumber = false;
      this.form.controls.id_num.setValue('Could Not Find');
    }
  }

  onNaSelectChange(e: Event) {
    if (e) {
      const element = e.target as HTMLInputElement;
      if (element.value === this.naOptions[0]) {
        this.isNaNumber = true;
        this.naValidationError = true;
        this.form.controls.na_num.setValue('');
      } else {
        this.isNaNumber = false;
        this.form.controls.na_num.setValue(element.value);
      }
    }
  }

  naNumberChanged(e: Event) {
    if (e) {
      const value = (e.target as HTMLInputElement).value;
      this.naNumberValidation(value);
    }
  }

  naNumberValidation(value) {
    const positiveIntCheckRegex = /^\d+$/;
    if (String(value).length === 4 && positiveIntCheckRegex.test(value)) {
      this.naValidationError = false;
    } else {
      this.naValidationError = true;
    }
  }

  onIdSelectChange(e: Event) {
    if (e) {
      const element = e.target as HTMLInputElement;
      if (element.value === this.naOptions[0]) {
        this.isIdNumber = true;
        this.idValidationError = true;
        this.form.controls.id_num.setValue('');
      } else {
        this.isIdNumber = false;
        this.form.controls.id_num.setValue(element.value);
      }
    }
  }

  idNumberChanged(e: Event) {
    if (e) {
      const value = (e.target as HTMLInputElement).value;
      this.idNumberValidation(value);
    }
  }

  idNumberValidation(value) {
    const positiveIntCheckRegex = /^\d+$/;
    if (String(value).length === 4 && positiveIntCheckRegex.test(value)) {
      this.idValidationError = false;
    } else {
      this.idValidationError = true;
    }
  }

  disableShippingName(controlName) {
    Object.keys(this.form.controls).forEach((key) => {
      if ((this.form.controls[key] as any) instanceof FormGroup && (this.form.controls[key] as any).controls[controlName]) {
        const value = (this.form.controls[key] as any).controls[controlName].value;
        if (value === this.hazard.notRegulatedText) {
          (this.form.controls[key] as any).controls[controlName].enable();
          this.setShippingName(controlName, value);
        } else if (value === this.hazard.couldNotFindText) {
           if ((this.form.controls[key] as any).controls[controlName].disabled) {
            (this.form.controls[key] as any).controls[controlName].enable();
          } else {
            (this.form.controls[key] as any).controls[controlName].disable();
          }
        } else {
          (this.form.controls[key] as any).controls[controlName].enable();
        }
      }
    });
  }

  private noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  private unNumberValidation(control: FormControl) {
    const value = control.value;
    if (value === 'Not Regulated') {
      return true;
    } else if (value && value !== 'Could Not Find') {
      const positiveIntCheckRegex = /^\d+$/;
      const isValid = positiveIntCheckRegex.test(value) && value.length === 4;
      return isValid ? null : { notInteger: true };
    }
    return null;
  }

  trimTrailingSpaces(name: string) {
    if (name) {
      return name.trim();
    }
    return null;
  }

  setShippingName(controlName, value) {
    if (controlName === 'dot_proper_shipping_name') {
      this.form.value.un_dot_grp.dot_proper_shipping_name = value;
    } else if (controlName === 'iata_proper_shipping_name') {
      this.form.value.un_iata_grp.iata_proper_shipping_name = value;
    } else if (controlName === 'imdg_proper_shipping_name') {
      this.form.value.un_imdg_grp.imdg_proper_shipping_name = value;
    }
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
    this.form.value.un_dot_grp.dot_proper_shipping_name = this.trimTrailingSpaces(this.form.value.un_dot_grp.dot_proper_shipping_name);
    this.form.value.un_iata_grp.iata_proper_shipping_name = this.trimTrailingSpaces(this.form.value.un_iata_grp.iata_proper_shipping_name);
    this.form.value.un_imdg_grp.imdg_proper_shipping_name = this.trimTrailingSpaces(this.form.value.un_imdg_grp.imdg_proper_shipping_name);
    const request = this.requestService.createIship(this.form);
    this.post(request);
    }
  }

  ngOnDestroy() {
    if (this.dotSubscription) {
      this.dotSubscription.unsubscribe();
    }
    if (this.iataSubscription) {
      this.iataSubscription.unsubscribe();
    }
  }
}
